import React, { useEffect, useState } from "react";

import "./Header.scss";
import helpIcon from "../../../../assets/images/Iconhelp.svg";
import { Button, Modal, Stack, Box, Icon } from "@mui/material";
import Advisor from "../Advisor/Advisor";
import ContactPhone from "./ContactPhone.svg";
import ContactEmail from "./ContactEmail.svg";
import { getUserInfo } from "../../../../core/services/TokenService";

const Header = ({ product, brand }) => {
  let [contactModalOpened, setContactModalOpened] = useState(false);
  let [userName, setUserName] = useState("");

  useEffect(() => {
    const userInfo = getUserInfo();
    setUserName(userInfo?.username || "");
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 460,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <section className="Header">
        <div className="Header__logo">
          <img height={42} src={brand.logo.url} alt="logo" />
        </div>
        <div className="Header__fill" />
        <div className="Header__login">{userName}</div>
        <Button
          variant={"outlined"}
          sx={{ width: "160px" }}
          startIcon={
            <Icon sx={{ marginTop: "-5px" }}>
              <img
                className="Header__icon"
                src={helpIcon}
                alt="icône utilisateur"
              />
            </Icon>
          }
          onClick={() => setContactModalOpened(true)}
        >
          BESOIN D'AIDE ?
        </Button>
      </section>
      <Modal
        open={contactModalOpened}
        onClose={() => setContactModalOpened(false)}
      >
        <Box sx={style}>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Advisor
                adviserImage={product.adviserImage}
                adviser={product.adviser}
                adviserDescription={product.adviserDescription}
              />
              <div className={"Header__contact"}>{product.adviserContact}</div>
            </Stack>
            <Stack direction="row" justifyContent={"center"} spacing={2}>
              <Button
                variant="contained"
                href={"tel:" + product.adviserPhone.replaceAll(/[^+0-9]/gi, "")}
                startIcon={
                  <Icon sx={{ marginTop: "-5px" }}>
                    <img src={ContactPhone} alt="Telephone" />
                  </Icon>
                }
              >
                {product.adviserPhone}
              </Button>
              <Button
                variant="contained"
                href={"mailto:" + product.adviserEmail}
                startIcon={
                  <Icon sx={{ marginTop: "-5px" }}>
                    <img src={ContactEmail} alt="Email" />
                  </Icon>
                }
              >
                {product.adviserEmail}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
