import { apiGetMany, apiGetOne, apiPatch, apiPost, apiUrl } from "./ApiService";
import PQueue from "p-queue";
import { snapshotFactory } from "../utils/snapshot";

let batches = new Map();
let queue = new PQueue({ concurent: 1 });

const subscriptionSnapshot = snapshotFactory([
  "policyHolder",
  "status",
  "jobStatus",
  "armyCorps",
  "signingDate",
  "effectiveFrom",
  "prevInsurer",
  "prevContractRef",
  "isRia",
  "bundleRef",
  "oavRef",
  "personDadsRef",
  "policyDadsRef",
  "offerDadsRef",
  "isDuplicatesSearched",
  "dematConsent",
  "ribBanque",
  "ribGuichet",
  "ribCompte",
  "ribCle",
  "ribIban",
  "ribBic",
  "ribDomiciliation",
  "ribOwner",
  "prevRiskLocation",
  "periodicity",
]);
const batchSnapshot = snapshotFactory(["status"]);

export const getBatch = async (id, force = false) => {
  if (!force && batches.has(id)) return batches.get(id).batch;

  const batch = await apiGetOne(`${apiUrl}/batch-subscriptions/${id}`);
  batches.set(batch._id, {
    batch: batch,
    subscriptions: new Map(),
  });

  subscriptionSnapshot.clear();
  batchSnapshot.snapshot(batch);
  return batch;
};

export const getBatches = async () => {
  return apiGetMany(`${apiUrl}/batch-subscriptions`);
};

export const clearBatchCache = (batchId) => {
  if (batches.has(batchId)) {
    batches.get(batchId).subscriptions.clear();
  }
};

export const getSubscription = async (batchId, subscriptionId) => {
  const batch = await getBatch(batchId);
  if (!batch) throw new Error("Batch " + batchId + " is not found.");
  const subMap = batches.get(batch._id).subscriptions;
  if (subMap.has(subscriptionId)) return subMap.get(subscriptionId);

  if (!batch.subscriptions) batch.subscriptions = [];
  const idx = batch.subscriptions.findIndex((s) => s._id === subscriptionId);
  if (idx === -1)
    throw new Error("Subscription " + subscriptionId + " is not found.");

  const subscription = await apiGetOne(
    `${apiUrl}/subscriptions/${subscriptionId}`
  );
  if (!subscription)
    throw new Error(
      "Subscription " + subscriptionId + " doesn't exist on the server."
    );
  batch.subscriptions[idx] = subscription;
  subMap.set(subscriptionId, subscription);
  // TODO : Assert object format (legacy data)

  // Fix document status.
  if (!subscription.supportingDocuments) subscription.supportingDocuments = [];
  subscription.supportingDocuments.forEach((doc) => {});

  subscriptionSnapshot.snapshot(subscription);
  return subscription;
};

export const saveBatch = async (batch) => {
  const _batch = await getBatch(batch._id);
  if (!batch) throw new Error("Batch " + batch._id + " is not found.");
  let [diff, snapshot] = batchSnapshot.compare(_batch);
  await queue.add(async () => {
    await apiPatch(`${apiUrl}/batch-subscriptions/${batch._id}`, diff);
    batchSnapshot.snapshot(snapshot);
  });
  return _batch;
};

export const saveSubscription = async (subscription) => {
  const _subscription = await getSubscription(
    subscription.batchSubscriptionId,
    subscription._id
  );
  if (!_subscription)
    throw new Error("Subscription " + subscription._id + " is not found.");
  const [diff, snapshot] = subscriptionSnapshot.compare(subscription);
  if (diff.dematConsent === null) {
    diff.dematConsent = false;
  }
  if (diff.isRia === null) {
    diff.isRia = false;
  }
  await queue.add(async () => {
    await apiPatch(`${apiUrl}/subscriptions/${subscription._id}`, diff);
    subscriptionSnapshot.snapshot(snapshot);
  });
  return subscription;
};

export const createSubscription = async (batchId, data = {}) => {
  let batch = await getBatch(batchId);
  let subscription = await apiPost(`${apiUrl}/subscriptions`, {
    batchSubscriptionId: batchId,
    ...data,
  });
  batch.subscriptions.push(subscription);
  batches.get(batchId).subscriptions.set(subscription._id, subscription);
  return subscription;
};

export const updateDocument = async (
  subscriptionId,
  documentId,
  file,
  filename
) => {
  const formData = new FormData();
  formData.append("file", file, filename || documentId + ".pdf");
  return apiPatch(
    `${apiUrl}/subscriptions/${subscriptionId}/documents/${documentId}`,
    formData
  );
};

export const getDocumentSpecification = () => {
  return [
    {
      type: "id",
      label: "PI",
      variants: [
        { label: "CNI", pageSize: [500, 500], pages: ["RECTO", "VERSO"] },
        { label: "PASSEPORT", pageSize: [500, 500], pages: ["PASSEPORT"] },
        {
          label: "Militaire",
          pageSize: [500, 500],
          pages: ["Carte Identité Militaire"],
          isLegionnaire: true,
        },
      ],
    },
    {
      type: "rib",
      label: "RIB",
      variants: [{ label: "RIB", pageSize: [500, 500], pages: ["RIB"] }],
    },
    {
      type: "da",
      label: "DA",
      variants: [
        {
          label: "DA",
          pageSize: [500, 500],
          pages: ["Page 1", "Page 2", "Page 3"],
        },
      ],
    },
  ].sort((a, b) => (a.type > b.type ? 1 : -1));
};

export const getSubscriptionDocument = (subscription, type) => {
  return subscription.supportingDocuments?.find(
    (ii) => ii.documentType === type
  );
};
