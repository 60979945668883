import React from "react";
import CardBlock from "../CardBlock/CardBlock";
import "./CardBlockTextIcon.scss";
import T from "prop-types";

const CardBlockTextIcon = React.forwardRef(
  ({ item, onActiveCard = () => null, disable, i }, CardRef) => {
    return (
      <CardBlock
        i={i}
        ref={CardRef}
        value={item.value}
        disable={disable}
        onActiveCard={onActiveCard}
        className={"CardBlockTextIcon"}
      >
        <div className="CardBlockTextIcon__icon">
          <img src={item.icon} alt={`${item.text} icon`} />
        </div>
        <span className="CardBlockTextIcon__text">{item.text}</span>
      </CardBlock>
    );
  }
);
CardBlockTextIcon.propTypes = {
  item: T.shape({
    name: T.string,
    description: T.string,
    content: T.string,
  }),
  onActiveCard: T.func,
  disable: T.bool,
  i: T.oneOfType([T.string, T.number]),
  CardRef: T.array,
};

export default CardBlockTextIcon;
