import React from "react"
import "./HumanAdvice.scss"
import {Stack} from "@mui/material";
import ReactMarkdown from "react-markdown";
import Advisor from "../Advisor/Advisor";

const HumanAdvice = ({adviserImage, adviser, adviserDescription, advice}) => {
    return (
        <div className="HumanAdvice">
            <Stack direction={"row"} alignItems={'start'} justifyContent={"center"}>
                <Advisor adviser={adviser} adviserImage={adviserImage} adviserDescription={adviserDescription}/>
                <div className="HumanAdvice__Bubble">
                    <div className="HumanAdvice__Bubble-triangle"></div>
                    <ReactMarkdown>
                        {advice}
                    </ReactMarkdown>
                </div>
            </Stack>
        </div>
    )
}

export default HumanAdvice
