import {
  apiDelete,
  apiGetMany,
  apiGetOne,
  apiPatch,
  apiPost,
  apiUrl,
} from "./ApiService";
import { ROWS_PER_PAGE } from "./ApiBatchService";

export const getSubscriptions = async (batchId, nbPage) =>
  apiGetMany(
    `${apiUrl}/subscriptions?limit=${ROWS_PER_PAGE}&page=${nbPage}&batchSubscriptionId=${batchId}&status=ne:DELETED`
  );

export const getNotCompletedSubscriptions = async (row_id) =>
  apiGetMany(
    `${apiUrl}/subscriptions?batchSubscriptionId=${row_id}&status=ne:SUCCESS&status=ne:DELETED&status=ne:SEF&select=_id`
  );

export const getSavedSubscriptions = async (row_id) =>
  apiGetMany(
    `${apiUrl}/subscriptions?batchSubscriptionId=${row_id}&status=ne:DELETED&select=_id`
  );

export const bundleRefTaken = async (subscriptionId, bundleRef) => {
  const { total } = await apiGetMany(
    `${apiUrl}/subscriptions?bundleRef=${bundleRef}&status=ne:ERROR&status=ne:DELETED&_id=ne:${subscriptionId}`
  );
  return total > 0;
};

export const getSubscription = async (subscriptionId) =>
  apiGetOne(`${apiUrl}/subscriptions/${subscriptionId}`);

export const postDocument = async (subscriptionId, data) =>
  apiPatch(`${apiUrl}/subscriptions/${subscriptionId}/documents`, data);

export const uploadMandateRiaDoc = async (subscriptionId, formData) =>
  apiPost(`${apiUrl}/subscriptions/${subscriptionId}/mandate-ria`, formData);

export const patchDocument = async (subscriptionId, documentId, data) =>
  apiPatch(
    `${apiUrl}/subscriptions/${subscriptionId}/documents/${documentId}`,
    data
  );

export const deleteDocument = async (subscriptionId, documentId, data) =>
  apiDelete(
    `${apiUrl}/subscriptions/${subscriptionId}/documents/${documentId}`,
    data
  );

export const postSubscription = async (data) =>
  apiPost(`${apiUrl}/subscriptions`, data);

export const patchSubscription = async (subscriptionId, data) =>
  apiPatch(`${apiUrl}/subscriptions/${subscriptionId}`, data);

export const deleteSubscription = async (subscriptionId) =>
  apiDelete(`${apiUrl}/subscriptions/${subscriptionId}`);

export const getDraftSubscription = async (data) =>
  new Promise((resolve) =>
    setTimeout(
      () =>
        resolve({
          batchSubscriptionId: data.batchSubscriptionId,
          ...JSON.parse(JSON.stringify(SUBSCRIPTION_DRAFT)),
          ...(data || {}),
        }),
      450
    )
  );

export const SUBSCRIPTION_DRAFT = {
  policyHolder: {
    status: "",
    title: "",
    lastName: "",
    firstName: "",
    birthLastName: "",
    birthFirstName: "",
    birthDate: "",
    sex: "",
    nationality: "",
    birthDepartment: "",
    birthPlace: "",
    birthCountry: "",
    jobStatus: "",
    contacts: [
      {
        name: "",
        phone: "",
        email: "",
      },
    ],
    addresses: [
      {
        address: "",
        city: "",
        zipCode: "",
        country: "",
      },
    ],
  },
  supportingDocuments: [
    { documentType: "id" },
    { documentType: "rib" },
    { documentType: "da" },
  ],
  armyCorps: "",
  signingDate: "",
  effectiveFrom: "",
  riaRiskLocation: "",
  riaInsurer: "",
  riaYesNo: "",
  bundleRef: "",
  oavRef: "",
  personDadsRef: "",
  policyDadsRef: "",
  offerDadsRef: "",
  createdBy: "",
  createdOn: "",
  updatedOn: "",
  updatedBy: "",
};

//   {
//     _id: null,
//     batchSubscriptionId: null,
//     policyHolder: {
//       status: "active",
//       title: "",
//       lastName: "MARTIN",
//       firstName: "Maelis-Gaelle",
//       birthLastName: "MARTIN",
//       birthFirstName: "Maelis-Gaelle",
//       birthDate: "13/07/1990",
//       sex: "F",
//       nationality: "",
//       birthDepartment: "",
//       birthPlace: "Paris",
//       birthCountry: "",
//       jobStatus: "",
//       contacts: [
//         {
//           name: "",
//           phone: "06 26 26 26 04",
//           email: "maelisg.martin@yopmail.com",
//         },
//       ],
//       addresses: [
//         {
//           address: "",
//           city: "",
//           zipCode: "",
//           country: "",
//         },
//       ],
//     },
//     supportingDocuments: [],
//     armyCorps: "",
//     signingDate: "01/01/2022",
//     effectiveFrom: "",
//     riaRiskLocation: "",
//     riaInsurer: "",
//     riaYesNo: "",
//     bundleRef: "",
//     oavRef: "",
//     personDadsRef: "",
//     policyDadsRef: "",
//     offerDadsRef: "",
//     createdBy: "",
//     createdOn: "",
//     updatedOn: "",
//     updatedBy: "",
//   },
// ];
