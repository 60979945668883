import React from "react"
import CardBlock from "../CardBlock/CardBlock"
import TagBlock from "../TagBlock/TagBlock"
import T from "prop-types"

import "./CardBlockTextTag.scss"

const CardBlockTextTag = React.forwardRef(({ item, className, onActiveCard = () => null, disable, i }, CardRef) => {
    return (
      <CardBlock
        ref={CardRef}
        i={i}
        value={item.value}
        onActiveCard={onActiveCard}
        disable={disable}
        className={`CardBlockTextTag ${className}`}
      >
        <div className="CardBlockTextTag__text">
          <span>{item.label}</span>
        </div>
        <div className="CardBlockTextTag__tag">
          <TagBlock name={item.tagLabel} variant={item.tagVariant} />
        </div>
      </CardBlock>
    );
  }
);

CardBlockTextTag.propTypes = {
  item: T.shape({
    value: T.string,
    label: T.string,
    tagLabel: T.string,
    tagVariant: T.string,
  }),
  className: T.string,
  onActiveCard: T.func,
  disable: T.bool,
  i: T.oneOfType([T.string, T.number]),
};
export default CardBlockTextTag;
