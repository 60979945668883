import React, {useState, useRef} from 'react'

const Component = ({registry, events, ...args}) => {
    const [style, setStyle] = useState(args.style || 'info');
    const [text, setText] = useState(args.text || '');
    const [visible, setVisible] = useState(true);

    if(args.code) {
        const api = {};
        Object.defineProperties(api, {
            'style': {
                get: () => style,
                set: (v) => setStyle(v)
            },
            'text': {
                get: () => text,
                set: (v) => setText(v)
            },
            'visible': {
                get: () => visible,
                set: (v) => setVisible(v)
            },
        });

        registry.set(args.code, api);
    }

    const styleCfg = {
        alert: {
            color: 'var(--error)',
            fontSize: '18px',
            fontWeight: '500',
            whiteSpace: 'pre'
        },
        info: {
            color: 'var(--secondary)',
            fontSize: '18px',
            fontWeight: '500',
            whiteSpace: 'pre'
        },
        normal: {
            color: 'var(--primary)',
            fontSize: '18px',
            fontWeight: '500',
            whiteSpace: 'pre'
        }
    }

    if(!visible) return <></>

    return <div style={styleCfg[style]||styleCfg.normal}>{text}</div>
}

export default Component;
