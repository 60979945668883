import React, {useEffect, useState} from "react";
import "./CardField.scss";

const CardField = ({disabled, selected, children, onChange, onClick, className, ...args}) => {

    const switchState = () => {
        onClick && onClick();
        if(disabled) return;
        onChange && onChange(!selected);
    }

    return <div className={"CardField "+(selected?"selected":disabled?"disabled":"")+" "+(className||"")} onClick={switchState} {...args}>
        {children}
    </div>
}

export default CardField;