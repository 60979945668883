import React, { useState, useEffect, useContext } from "react";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import plusIcon from "../../../../assets/images/plus-icon.svg";
import "./BatchSubscriptionTabs.scss";
import { getBatches } from "../../../../core/services";
import BatchSubscriptionList from "../BatchSubscriptionList/BatchSubscriptionList";
import { Button, Tabs, Tab, Typography } from "@mui/material";
import BatchSubscription from "../BatchSubsription/BatchSubscription";
import SubscriptionsHistory from "../SubscriptionsHistory/SubscriptionsHistory";
import { BatchSubscriptionContext } from "../..";

const BatchSubscriptionTabs = () => {
  const [loading, setLoading] = useState(true);
  const [batches, setBatches] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = React.useState(location.pathname);
  const { forceBatchTabsReload } = useContext(BatchSubscriptionContext);

  useEffect(() => {
    setCurrentTab(location.pathname);
  }, [location]);

  useEffect(() => {
    getBatches({ filter: `status=ne:DELETED&status=ne:ARCHIVED` }).then(
      (res) => {
        setLoading(false);
        setBatches(res.data.reverse());
      }
    );
  }, [forceBatchTabsReload]);

  useEffect(() => {
    // Look in batches the requested batch
    if (location.pathname === "/batch-subscriptions/batches")
      navigate("/batch-subscriptions/batches/list");
    if (location.pathname === "/batch-subscriptions/batches/list") return;
    if (location.pathname === "/batch-subscriptions/batches/history") return;

    if (!loading && location.pathname === "/batch-subscriptions/batches") {
      if (batches.length > 0) {
        navigate(`/batch-subscriptions/batches/${batches[0]._id}`);
      } else {
        navigate(`/batch-subscriptions/batches/list`);
      }
    }
  }, [batches, loading, location.pathname, navigate]);

  return (
    !loading && (
      <div className="BatchSubscriptionTabs Page">
        <div className="BatchSubscriptionTabs__addBatch">
          <Typography variant="h2">Import liasse papier</Typography>
          <div className="BatchSubscriptionTabs__addBatch-button">
            <Link to="/batch-subscriptions/batches/new">
              <Button
                startIcon={<img src={plusIcon} alt="plus icon" />}
                variant="contained"
                color="info"
              >
                Nouvelle session
              </Button>
            </Link>
          </div>
        </div>
        <Tabs
          value={currentTab}
          variant="scrollable"
          allowScrollButtonsMobile
          style={{ marginTop: 12 }}
        >
          <Tab
            label="Sessions"
            component={Link}
            to={`/batch-subscriptions/batches/list`}
            value={`/batch-subscriptions/batches/list`}
          />

          {batches.map((batch) => (
            <Tab
              key={batch._id}
              label={batch.sessionName}
              to={`/batch-subscriptions/batches/${batch._id}`}
              component={Link}
              value={`/batch-subscriptions/batches/${batch._id}`}
            />
          ))}

          {/* <Tab label="Liasses confiées"
        component={Link}
        to={`/batch-subscriptions/batches/history`}
        value={`/batch-subscriptions/batches/history`}
      /> */}
        </Tabs>
        <Routes>
          <Route path="list" element={<BatchSubscriptionList />} />
          <Route path="history" element={<SubscriptionsHistory />} />
          <Route path=":batchId" element={<BatchSubscription />} />
        </Routes>
      </div>
    )
  );
};

export default BatchSubscriptionTabs;
