import React, { useContext, useEffect, useState } from "react";
import { SubscriptionContext } from "./SubscriptionIndex";
import { Link, useNavigate, useParams } from "react-router-dom";
import DocumentBlock from "../DocumentBlock/DocumentBlock";
import "./Subscription.scss";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  getDocumentSpecification,
  saveSubscription,
} from "../../../../core/services/BatchSubscriptionService";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { apiUrl } from "../../../../core/services";
import { axiosInstance } from "../../../../core/services/Https";
import TagBlock from "../../../../core/blocks/TagBlock/TagBlock";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { LoadingContext } from "../../../../core/utils/loading";
import countries from "../../../../assets/countries";
import departements from "../../../../assets/departements";

let timeOutHandler = null;

const SubscriptionDuplicates = () => {
  const [loading, setLoading] = useState();
  const { subscription, batch } = useContext(SubscriptionContext);

  const { startLoading, endLoading } = useContext(LoadingContext);
  let { batchId, subscriptionId } = useParams();
  let [results, setResults] = useState([]);
  let [selected, setSelected] = useState(null);
  let [currentDocument, setCurrentDocument] = useState("id");
  let [documents, setDocuments] = useState([]);
  let [firstName, setFirstName] = useState(
    subscription?.policyHolder.firstName || ""
  );
  let [lastName, setLastName] = useState(
    subscription?.policyHolder.lastName || ""
  );
  let [birthDate, setBirthDate] = useState(
    subscription?.policyHolder.birthDate
      ? moment(subscription?.policyHolder.birthDate)
      : null
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!subscription) return;
    const specs = getDocumentSpecification();
    setDocuments(
      subscription.supportingDocuments.map((d) => {
        return {
          value: d.documentType,
          label:
            specs.find((s) => s.type === d.documentType)?.label || "Inconnu",
          _id: d._id,
        };
      })
    );

    setFirstName(subscription?.policyHolder?.firstName || "");
    setLastName(subscription?.policyHolder?.lastName || "");
    if (subscription?.policyHolder?.birthDate !== "") {
      setBirthDate(moment(subscription?.policyHolder?.birthDate));
    }
  }, [subscription]);

  const handleNext = async () => {
    // if (subscription.isDuplicatesSearched) {
    //   navigate(
    //     `/batch-subscriptions/${batchId}/subscriptions/${subscriptionId}/fill/person`
    //   );
    //   return
    // }

    if (selected !== "NEW") {
      startLoading();
      try {
        const response = await axiosInstance.get(
          apiUrl + "/dads/consultation-personne-global",
          {
            params: {
              codePartenaire: "Carene",
              idClientSF: selected.id,
            },
          }
        );

        if (!response.data || !response.data.Acc) {
          return;
        }

        const o = response.data.Acc;

        // Do not change the following 4 fields if 'isLegionnaire==true'.
        if (!batch.isLegionnaire) {
          subscription.policyHolder.armyCorps = o.Corps_Affectation__pc || "";
          subscription.policyHolder.jobStatus =
            o.Statut_Professionnel_Actuel__pc || "";

          subscription.policyHolder.email = o.PersonEmail || "";
          subscription.policyHolder.phone = o.PersonMobilePhone
            ? o.PersonMobilePhone.replace(/\s/g, "")
            : "";
        }
        // For student's case
        subscription.isStudent = batch.isStudent;

        // To be compliant with the api
        if (["M", "M.", "NR"].includes(o.Salutation)) {
          subscription.policyHolder.title = "M";
        } else {
          subscription.policyHolder.title = "Mme";
        }
        subscription.policyHolder.firstName = o.FirstName || "";
        subscription.policyHolder.lastName = o.LastName || "";
        // Sexe__pc
        subscription.policyHolder.sex = o.Sexe__pc || "M";
        subscription.policyHolder.birthLastName = o.Nom_Naissance__pc || "";
        subscription.policyHolder.birthDate = o.PersonBirthdate || "";
        subscription.policyHolder.address.streetType = o.Type_Voie__c || "";
        subscription.policyHolder.address.streetNumber = o.No_Voie__c || "";
        subscription.policyHolder.address.street = o.Nom_Voie__c || "";
        subscription.policyHolder.address.city = o.Ville__c || "";
        subscription.policyHolder.address.zipCode = o.Code_Postal__c || "";
        subscription.policyHolder.address.country = o.Code_Pays__c || "";
        subscription.personDadsRef = o.Id || "";

        subscription.dematConsent =
          o.Accept_email__pc && o.Accept_email__pc.includes("O", "N")
            ? o.Accept_email__pc === "O"
            : false;

        subscription.policyHolder.address.additional =
          o.Complement_Adresse__c || "";
        // commune naissance
        subscription.policyHolder.birthPlace = o.Nom_Commune_Naissance__c || "";
        // depart naissance
        if (o.Code__c) {
          const correspondingBirthDepart = departements.find(
            (item) => item.code_dep === o.Code__c
          );
          if (correspondingBirthDepart) {
            subscription.policyHolder.birthDepartment =
              correspondingBirthDepart.code_dep;
          }
        }
        // pays naissance
        // subscription.policyHolder.birthCountry = o.Nu_Pays_Naissance__c || "";
        if (o.Nu_Pays_Naissance__c) {
          const correspondingBirthCountry = countries.find(
            (item) => item.code === o.Nu_Pays_Naissance__c
          );
          if (correspondingBirthCountry) {
            subscription.policyHolder.birthCountry =
              correspondingBirthCountry.id;
          }
        }
        // pays domicile dans l'adresse
        if (o.Code_Pays__c) {
          const correspondingResidenceCountry = countries.find(
            (item) => item.code === o.Code_Pays__c
          );
          if (correspondingResidenceCountry) {
            subscription.policyHolder.address.country =
              correspondingResidenceCountry?.id;
          }
        }
        // pays nationnalite
        if (o.Nu_Pays_Nationalite__c) {
          const correspondingNationalityCountry = countries.find(
            (item) => item.code === o.Nu_Pays_Nationalite__c
          );

          if (correspondingNationalityCountry) {
            subscription.policyHolder.nationality =
              correspondingNationalityCountry?.id;
          }
        }
      } catch (e) {
        console.error(e);
        return;
      } finally {
        endLoading();
      }
    } else {
      if (!subscription?.policyHolder?.firstName)
        subscription.policyHolder.firstName = firstName;
      if (!subscription?.policyHolder?.lastName)
        subscription.policyHolder.lastName = lastName;
    }

    subscription.isDuplicatesSearched = true;
    saveSubscription(subscription)
      .then((res) => console.info(res))
      .catch((err) => console.error(err));

    navigate(
      `/batch-subscriptions/${batchId}/subscriptions/${subscriptionId}/fill/person`
    );
  };

  useEffect(() => {
    if (timeOutHandler) clearTimeout(timeOutHandler);
    if (!firstName && !lastName && !birthDate) return;
    timeOutHandler = setTimeout(() => {
      setLoading(true);
      startLoading();
      axiosInstance
        .get(apiUrl + "/dads/search-duplicates", {
          params: {
            codePartenaire: "Carene",
            firstName: firstName,
            lastName: lastName,
            birthDate: birthDate ? birthDate.format("YYYY-MM-DD") : "",
          },
        })
        .then((response) => {
          endLoading();
          setLoading(false);
          if (response && response.data === "") {
            setResults([]);
          }
          if (response && response.data) {
            setResults(response.data);
          }
        })
        .catch((e) => {
          endLoading();
          setLoading(false);
          console.error(e);
        });
    }, 700);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, birthDate]);

  const sfStatuses = {
    RESILIE: { label: "Résilié", tag: "error" },
    "EN INSTANCE": { label: "En Instance", tag: "success" },
    ACTIF: { label: "Actif", tag: "success" },
  };

  return (
    <div className="Subscription">
      <div style={{ width: "50%" }}>
        <Typography variant="h2">Souscripteur</Typography>
        <Typography mt={1} variant="h6">
          Vérifiez que le souscripteur n'est pas déjà client
        </Typography>

        <Stack>
          <TextField
            size="small"
            margin="normal"
            variant="outlined"
            label="Nom de famille"
            required={true}
            placeholder="Nom de famille"
            title="Nom de famille"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            size="small"
            margin="normal"
            variant="outlined"
            label="Prénom"
            required={true}
            placeholder="Prénom"
            title="Prénom"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <DesktopDatePicker
            label="Date naissance"
            inputFormat="DD/MM/YYYY"
            value={birthDate}
            onChange={(e) => setBirthDate(e)}
            renderInput={(params) => {
              params.inputProps.placeholder = "jj/mm/aaaa";
              return (
                <TextField
                  size="small"
                  required={true}
                  margin="normal"
                  variant="outlined"
                  {...params}
                />
              );
            }}
          />

          <Typography mt={1} variant="h6">
            Sélectionnez le souscripteur, ou créez une nouvelle fiche :
          </Typography>
        </Stack>

        <div className="Subscription-ResultList">
          {results.map((item, i) => {
            return (
              <div
                key={i}
                onClick={() => setSelected(item)}
                className={`Subscription-ResultCard  ${
                  selected &&
                  item.id === selected.id &&
                  "Subscription-ResultCard-selected"
                }`}
              >
                <div className="Subscription-ResultCard__col1">
                  <div className="Subscription-ResultCard__name">
                    {item.civilite} {item.prenom} {item.nom}
                  </div>
                  {item.dateNaissance && (
                    <span>
                      Né(e) le{" "}
                      {new Date(item.dateNaissance).toLocaleDateString()}
                    </span>
                  )}
                </div>
                <div className="Subscription-ResultCard__col2">
                  <div>{item.telephonePortable}</div>
                  <div>{item.mail}</div>
                </div>
                <TagBlock
                  name={sfStatuses[item.statut]?.label || item.statut}
                  variant={sfStatuses[item.statut]?.tag || "error"}
                />
              </div>
            );
          })}

          {loading && (
            <div className={"Subscription-ResultCard"}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={3}
              >
                Recherche en cours
                <CircularProgress size={24} style={{ marginLeft: 12 }} />
              </Stack>
            </div>
          )}

          {!loading && results.length === 0 && (
            <div className={"Subscription-ResultCard"}>Aucun résultat</div>
          )}

          <Grid container spacing={0} direction="row" justifyContent="center">
            <Typography mt={1} mr={3} variant="h6">
              OU
            </Typography>
          </Grid>

          <div
            className={`Subscription-ResultCard ${
              selected === "NEW" && "Subscription-ResultCard-selected"
            }`}
            onClick={() => (selected ? setSelected(null) : setSelected("NEW"))}
          >
            <span className="Subscription-ResultCard__name">
              Créer une nouvelle fiche client
            </span>
          </div>

          {selected === "NEW" && results.length > 0 && (
            <>
              <br />
              <Alert severity="warning">
                Vous allez créer un doublon : Il existe déjà des adhérents
                portant ce prénom, ce nom, cette date de naissance. Pour ne pas
                créer de doublon sélectionner la personne dans la liste et ne
                sélectionnez pas "Créer une nouvelle fiche client"
              </Alert>
            </>
          )}
        </div>
        <br />
        <div className="Subscription__button">
          <Button
            color="info"
            variant="contained"
            onClick={() => handleNext()}
            disabled={!selected}
          >
            SUIVANT
          </Button>
        </div>
      </div>

      <div className="Subscription__documentBlock-container">
        <div className="Subscription__btPrecedent">
          <Link to={`/batch-subscriptions/batches/${batchId}`}>
            <Button
              variant="outlined"
              disabled
              startIcon={<KeyboardArrowLeft />}
            >
              PRÉCÉDENT
            </Button>
          </Link>
        </div>
        <DocumentBlock
          documents={documents}
          currentDocument={currentDocument}
          setCurrentDocument={setCurrentDocument}
          subscriptionId={subscriptionId}
        />
      </div>
    </div>
  ) /*: (
    <div className="Subscription">
      <div style={{ width: "50%" }}>
        <Typography variant="h2">Souscripteur</Typography>
        <Typography mt={1} variant="h6">
          Vérifiez que le souscripteur n'est pas déjà client
        </Typography>
        <br />
        <Alert severity="warning">La recherche a déjà été effectuée.</Alert>

        <div className="Subscription__button">
          <Button color="info" variant="contained" onClick={() => handleNext()}>
            SUIVANT
          </Button>
        </div>
      </div>

      <div className="Subscription__documentBlock-container">
        <div className="Subscription__btPrecedent">
          <Link to={`/batch-subscriptions/batches/${batchId}`}>
            <Button variant="outlined" startIcon={<KeyboardArrowLeft />}>
              PRÉCÉDENT
            </Button>
          </Link>
        </div>
        <DocumentBlock
          documents={documents}
          currentDocument={currentDocument}
          setCurrentDocument={setCurrentDocument}
          subscriptionId={subscriptionId}
        />
      </div>
    </div>
  )*/;
};

export default SubscriptionDuplicates;
