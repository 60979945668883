import {axiosInstance, publicAxiosInstance} from "./Https";
export const apiUrl = process.env.REACT_APP_API_URL;



export const apiGetOne = async (url) =>  {
  const resp = await axiosInstance.get(url);
  return apiGetData(resp);
}

export const apiGetMany = async (url) => {
  const resp = await axiosInstance.get(url);
  return apiGetData(resp);
}
export const apiGetDocument = async (url) => {
  return axiosInstance.get(url, { responseType: "arraybuffer" });
}

export const apiPut = async (url, data) => {
  const resp = await axiosInstance.put(url, data);
  return apiGetData(resp);
}

export const apiPatch = async (url, data) => {
  const resp = await axiosInstance.patch(url, data);
  return apiGetData(resp);
}
export const apiPost = async (url, data) => {
  const resp = await axiosInstance.post(url, data);
  return apiGetData(resp);
}

export const apiDelete = async (url) =>{
  const resp = await axiosInstance.delete(url);
  return resp && resp.success
}

function apiGetData(resp) {
  return resp && resp.data ? resp.data : null;
}

// function apiGetRows(resp) {
//   return resp && resp.rows ? resp.rows : null;
// }





export const apiPublicGetOne = async (url) =>  {
  const resp = await publicAxiosInstance.get(url);
  return apiGetData(resp);
}

export const apiPublicGetMany = async (url) => {
  const resp = await publicAxiosInstance.get(url);
  return apiGetData(resp);
}
export const apiPublicGetDocument = async (url) => {
  return publicAxiosInstance.get(url, { responseType: "arraybuffer" });
}

export const apiPublicPut = async (url, data) => {
  const resp = await publicAxiosInstance.put(url, data);
  return apiGetData(resp);
}

export const apiPublicPatch = async (url, data) => {
  const resp = await publicAxiosInstance.patch(url, data);
  return apiGetData(resp);
}
export const apiPublicPost = async (url, data) => {
  const resp = await publicAxiosInstance.post(url, data);
  return apiGetData(resp);
}

export const apiPublicDelete = async (url) =>{
  const resp = await publicAxiosInstance.delete(url);
  return resp && resp.success
}