import React, { useContext, useEffect, useState } from "react";
import { SubscriptionContext } from "./SubscriptionIndex";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form } from "react-final-form";
import { Button, MenuItem, Stack, Typography } from "@mui/material";
import { Autocomplete, makeValidate, Switches, TextField } from "mui-rff";
import DocumentBlock from "../DocumentBlock/DocumentBlock";
import {
  getDocumentSpecification,
  saveSubscription,
} from "../../../../core/services/BatchSubscriptionService";
import countries from "../../../../assets/countries";
import departements from "../../../../assets/departements";
import dadsStatutProfessionel from "../../../../assets/dadsStatutProfessionel";
import dadsCorps from "../../../../assets/dadsCorps";
import BanAddressField from "../../../../core/blocks/BanAddressField/BanAddressField";
import streetTypes from "../../../../assets/streetTypes";
import stringSimilarity from "string-similarity";
import { DatePicker } from "../../../../core/blocks/FormField/DatePicker";
import * as Yup from "yup";
import { KeyboardArrowLeft } from "@mui/icons-material";
import moment from "moment";
import { Box } from "@mui/system";

Yup.setLocale({
  mixed: {
    default: "Invalide",
    required: "Obligatoire",
    date: "Date invalide",
    string: "Invalide",
  },
});

const fieldsValidate = makeValidate(
  Yup.object().shape({
    //Etat Civile
    title: Yup.string()
      .required("Obligatoire")
      .oneOf(["M", "Mme"], "La valeur doit être M ou Mme"),
    firstName: Yup.string()
      .matches(
        /^(?!.*([ .-])\1)[ A-Za-zÀ-Ÿ.-]+$/,
        "Contient des caractères invalides"
      )
      .required("Obligatoire"),
    lastName: Yup.string()
      .matches(
        /^(?!.*([ .-])\1)[ A-Za-zÀ-Ÿ.-]+$/,
        "Contient des caractères invalides"
      )
      .required("Obligatoire"),
    nationality: Yup.string()
      .required("Obligatoire")
      .nullable()
      .oneOf([countries.map((country) => country.label)], (e) =>
        e.value !== null && e.value !== "" ? "Valeur incorrecte" : true
      ),
    birthLastName: Yup.string().required("Obligatoire"),
    sex: Yup.string()
      .required("Obligatoire")
      .test("title-sex-coherency-check", (v, o) => {
        return (o.parent.title === "Mme" && v === "M") ||
          (o.parent.title === "M" && v === "F")
          ? o.createError({
              message: "Le sexe doit être cohérent avec la civilité choisie.",
              path: "sex",
            })
          : true;
      })
      .oneOf(["M", "F"], "La valeur doit être M ou F"),
    birthDate: Yup.date()
      .max(
        new Date(
          new Date(Date.now()).setDate(new Date(Date.now()).getDate() - 5840)
        ),
        "Date incorrect. Le souscripteur doit avoir 16 ans ou plus"
      )
      .required("Obligatoire")
      .nullable()
      .typeError("Date invalide."),
    birthDepartment: Yup.string()
      .test(
        "french-birth-department-checking",
        "Ne correspond pas au pays de naissance sélectionné",
        (v, o) =>
          (v !== "99 - Etranger" && o.parent.birthCountry === "France") ||
          (v === "99 - Etranger" && o.parent.birthCountry !== "France")
      )
      .required("Obligatoire")
      .nullable()
      .oneOf(
        departements.map((o) => o.code_dep + " - " + o.dep_name),
        (e) => (e.value !== null && e.value !== "" ? "Valeur incorrecte" : true)
      ),
    birthPlace: Yup.string()
      .test(
        "checking-birth-place",
        "Ne doit pas comporter plus de 3 mots",
        (v) => {
          if (v && /\s+(?:du|le|les|lès|la|de|en|sur|et)\s+/i.test(v)) {
            return true;
          }
          return v ? v.trim().split(/\s+/).length < 4 : true;
        }
      )
      .required("Obligatoire"),
    birthCountry: Yup.string()
      .test(
        "french-birth-country-checking",
        "Ne correspond pas au département de naissance sélectionné",
        (v, o) =>
          (v !== "France" && o.parent.birthDepartment === "99 - Etranger") ||
          (v === "France" && o.parent.birthDepartment !== "99 - Etranger")
      )
      .required("Obligatoire")
      .nullable()
      .oneOf(
        countries.map((country) => country.label),
        (e) => (e.value !== null && e.value !== "" ? "Valeur incorrecte" : true)
      ),

    //Contact et domicile
    phone: Yup.string()
      .matches(/^0[1-9]([0-9]{2}){4}$/, "Format incorrect.")
      .required("Obligatoire"),
    email: Yup.string()
      .matches(
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
        "Adresse email non valide"
      )
      .required("Ce champ est requis"),
    building: Yup.string().notRequired(),
    additional: Yup.string().test("test-additional", (v, o) =>
      !!v || (!!o.parent.street && !!o.parent.streetType)
        ? true
        : o.createError({
            message: "Complément d'adresse ou\nType voie + Voie",
          })
    ),
    street: Yup.string().test("test-street", (v, o) =>
      !!v || !!o.parent.additional
        ? true
        : o.createError({ message: "Obligatoire" })
    ),
    streetNumber: Yup.string().notRequired(),
    streetType: Yup.string()
      .test("test-streetType", (v, o) =>
        !!v || !!o.parent.additional
          ? true
          : o.createError({ message: "Obligatoire" })
      )
      .nullable()
      .oneOf(
        streetTypes.map((streetType) => streetType.label),
        (e) => (e.value !== null && e.value !== "" ? "Valeur incorrecte" : true)
      ),
    zipCode: Yup.string()
      .matches(/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/, "CP incorrect.")
      .required("Obligatoire"),
    city: Yup.string().required("Obligatoire"),
    country: Yup.string()
      .required("Obligatoire")
      .nullable()
      .oneOf(
        countries.map((country) => country.label),
        "Valeur incorrecte"
      ),

    //Activité professionnelle
    jobStatus: Yup.string()
      .required("Obligatoire")
      .nullable()
      .oneOf(
        dadsStatutProfessionel.map((status) => status.label),
        (e) => (e.value !== null && e.value !== "" ? "Valeur incorrecte" : true)
      ),
    armyCorps: Yup.string()
      .required("Obligatoire")
      .nullable()
      .oneOf(
        dadsCorps.map((corps) => corps.label),
        (e) => (e.value !== null && e.value !== "" ? "Valeur incorrecte" : true)
      ),
    isStudent: Yup.boolean(),
  })
);

const LOV = {
  nationality: countries,
  birthDepartment: departements.map((o) => ({
    id: o.code_dep + "",
    label: o.code_dep + " - " + o.dep_name,
  })),
  birthCountry: countries,
  country: countries,
  streetType: streetTypes,
  jobStatus: dadsStatutProfessionel,
  armyCorps: dadsCorps,
};

const textFieldOptions = {
  variant: "outlined",
  size: "small",
};

const SubscriptionFormPerson = () => {
  let navigate = useNavigate();
  let [documents, setDocuments] = useState([]);
  let { batchId, subscriptionId } = useParams();
  let [onChangeCount, setOnChangeCount] = useState(0);
  let [initialValues, setInitialValues] = useState({});
  let [currentDocument, setCurrentDocument] = useState("id");
  let [armyCorpsPossibleValues, setArmyCorpsPossibleValues] = useState([]);
  const { subscription, batch } = useContext(SubscriptionContext);

  useEffect(() => {
    if (!subscription) return;
    const specs = getDocumentSpecification();
    setDocuments(
      subscription?.supportingDocuments?.map((d) => {
        return {
          value: d.documentType,
          label:
            specs.find((s) => s.type === d.documentType)?.label || "Inconnu",
          _id: d._id,
        };
      })
    );

    let init = {};
    init.isStudent = subscription.isStudent;
    init.birthDate = subscription?.policyHolder?.birthDate
      ? moment(subscription?.policyHolder?.birthDate)
      : null;
    [
      "phone",
      "email",
      "title",
      "sex",
      "firstName",
      "lastName",
      "birthLastName",
      "birthPlace",
    ].forEach((k) => {
      init[k] =
        (subscription?.policyHolder && subscription?.policyHolder[k]) || "";
    });
    [
      "building",
      "additional",
      "streetNumber",
      "street",
      "city",
      "zipCode",
    ].forEach((k) => {
      init[k] = (subscription?.policyHolder?.address || {})[k] || "";
    });
    [
      "nationality",
      "birthDepartment",
      "birthCountry",
      "armyCorps",
      "jobStatus",
    ].forEach((k) => {
      init[k] =
        LOV[k].find(
          (o) =>
            o.id === ((subscription?.policyHolder || {})[k] || "").toString()
        )?.label || "";
    });
    ["country", "streetType"].forEach((k) => {
      init[k] =
        LOV[k].find(
          (o) => o.id === ((subscription?.policyHolder?.address || {})[k] || "")
        )?.label || "";
    });

    setInitialValues(init);
  }, [subscription]);

  const setAddressFields = (args, state) => {
    // streetType
    const res = stringSimilarity.findBestMatch(
      args[0].street.split(" ").shift() || "Rue",
      streetTypes.map((elt) => elt.label)
    );
    let streetType = "Rue";
    if (res.bestMatch.rating > 0) {
      streetType = streetTypes[res.bestMatchIndex].label;
    }
    // street
    const index = streetTypes.indexOf(
      streetTypes.find((i) => i.label === streetType)
    );
    let street = args[0].street
      ?.replace(
        new RegExp(streetTypes[index]?.label?.replace(/-+/g, " "), "gi"),
        ""
      )
      ?.replace(
        new RegExp(streetTypes[index]?.label?.replace(/ +/g, "-"), "gi"),
        ""
      )
      ?.trim();
    state.fields["streetNumber"].change(args[0].housenumber);
    state.fields["streetType"].change(streetType);
    state.fields["street"].change(street);
    state.fields["zipCode"].change(args[0].postcode);
    state.fields["city"].change(args[0].city);
    state.fields["country"].change("France");
  };

  const validate = async (values) => {
    return await fieldsValidate(values);
  };

  const onSubmit = async (values) => {
    try {
      subscription.isStudent = values.isStudent;
      subscription.policyHolder.birthDate =
        values.birthDate.format("YYYY-MM-DD");

      [
        "phone",
        "email",
        "title",
        "sex",
        "firstName",
        "lastName",
        "birthLastName",
        "nationality",
        "birthPlace",
        "birthCountry",
        "birthDepartment",
        "jobStatus",
        "armyCorps",
      ].forEach((k) => {
        subscription.policyHolder[k] = values[k] || "";
      });
      ["streetNumber", "street", "streetType", "country", "city"].forEach(
        (k) => {
          subscription.prevRiskAddress[k] = values[k] || "";
        }
      );

      [
        "phone",
        "email",
        "title",
        "sex",
        "firstName",
        "lastName",
        "birthLastName",
        "birthPlace",
      ].forEach((k) => {
        subscription.policyHolder[k] = values[k] || "";
      });
      [
        "building",
        "additional",
        "streetNumber",
        "street",
        "city",
        "zipCode",
      ].forEach((k) => {
        subscription.policyHolder.address[k] = values[k] || "";
      });
      [
        "nationality",
        "jobStatus",
        "armyCorps",
        "birthDepartment",
        "birthCountry",
      ].forEach((k) => {
        subscription.policyHolder[k] =
          LOV[k].find((o) => o.label === values[k])?.id || "";
      });

      subscription.policyHolder.address["streetType"] = LOV.streetType.find(
        (o) => o.label === values["streetType"]
      )?.id;

      subscription.policyHolder.address["country"] = LOV.country.find(
        (o) => o.label === values["country"]
      )?.id;

      await saveSubscription(subscription);
    } catch (e) {
      console.error(e);
    } finally {
      navigate(
        `/batch-subscriptions/${batchId}/subscriptions/${subscriptionId}/fill/contract`
      );
    }
  };

  const goBack = () => {
    navigate(
      `/batch-subscriptions/${batchId}/subscriptions/${subscriptionId}/fill/duplicates`
    );
  };

  const resetArmyCorps = (args, state) => {
    state.fields["armyCorps"].change("");
  };

  const updateArmyPossibleValues = (value, resetArmyCorps) => {
    setOnChangeCount((prevState) => prevState + 1);

    const status = LOV.jobStatus.find((a) => a.label === value);
    if (onChangeCount !== 0) {
      if (resetArmyCorps) resetArmyCorps(value);
    }
    if (!status) {
      setArmyCorpsPossibleValues([]);
      return;
    }
    setArmyCorpsPossibleValues(
      LOV.armyCorps
        .filter((v) => v.parent && v.parent === status.id)
        .map((v) => v.label)
    );
  };

  return (
    <Form
      mutators={{ setAddressFields, resetArmyCorps }}
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form /*invalid, values, errors*/ }) => (
        <form onSubmit={handleSubmit} noValidate>
          {/* <pre>{JSON.stringify(values, null, 1)}</pre> */}
          <div className="Subscription">
            <div style={{ width: "50%" }}>
              <Typography variant="h2">Souscripteur</Typography>
              <Typography variant="p">Fiche souscripteur</Typography>
              <Stack sx={{ marginTop: "40px" }} spacing={2}>
                <Typography variant="h4">Etat Civil</Typography>
                <Stack direction="row" spacing={2}>
                  <TextField
                    {...textFieldOptions}
                    select
                    name="title"
                    label="Civilité"
                    required={true}
                    sx={{ width: 300 }}
                    onFocus={() => setCurrentDocument("id")}
                  >
                    <MenuItem value="M">M</MenuItem>
                    <MenuItem value="Mme">Mme</MenuItem>
                  </TextField>
                  <TextField
                    {...textFieldOptions}
                    label="Nom de famille *&#8194; &#8194;"
                    name="lastName"
                    // required={true}
                    placeholder="Nom de famille"
                    title="Nom de famille"
                    sx={{ width: 480 }}
                    onFocus={() => setCurrentDocument("id")}
                  />
                  <TextField
                    {...textFieldOptions}
                    label="Prénom"
                    name="firstName"
                    required={true}
                    placeholder="Prénom"
                    title="Prénom"
                    sx={{ width: 300 }}
                    onFocus={() => setCurrentDocument("id")}
                  />
                  <Autocomplete
                    size="small"
                    disablePortal
                    required={true}
                    label="Nationalité"
                    name="nationality"
                    options={["", ...LOV.nationality.map((a) => a.label)]}
                    sx={{ width: 500 }}
                    onFocus={() => setCurrentDocument("id")}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextField
                    {...textFieldOptions}
                    select
                    name="sex"
                    label="Sexe"
                    required={true}
                    sx={{ width: 145 }}
                    onFocus={() => setCurrentDocument("id")}
                  >
                    <MenuItem value="M">M</MenuItem>
                    <MenuItem value="F">F</MenuItem>
                  </TextField>
                  <TextField
                    {...textFieldOptions}
                    label="Nom de naissance"
                    name={"birthLastName"}
                    required={true}
                    placeholder="Nom de naissance"
                    title="Nom de naissance"
                    sx={{ width: 245 }}
                    onFocus={() => setCurrentDocument("id")}
                  />
                  <Box onFocus={() => setCurrentDocument("id")}>
                    <DatePicker
                      label="Date de naissance"
                      inputFormat="DD/MM/YYYY"
                      name={"birthDate"}
                      required={true}
                      textFieldProps={{ ...textFieldOptions }}
                      placeholder="jj/mm/aaaa"
                    />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Autocomplete
                    size="small"
                    disablePortal
                    required={true}
                    label="Département naissance"
                    name="birthDepartment"
                    options={["", ...LOV.birthDepartment.map((a) => a.label)]}
                    sx={{ width: 830 }}
                    onFocus={() => setCurrentDocument("id")}
                  />
                  <TextField
                    {...textFieldOptions}
                    name={"birthPlace"}
                    label="Lieu naissance"
                    required={true}
                    placeholder="Lieu naissance"
                    title="Lieu naissance"
                    onFocus={() => setCurrentDocument("id")}
                  />
                  <Autocomplete
                    size="small"
                    disablePortal
                    required={true}
                    label="Pays de naissance"
                    name="birthCountry"
                    options={["", ...LOV.birthCountry.map((a) => a.label)]}
                    sx={{ width: 490 }}
                    onFocus={() => setCurrentDocument("id")}
                  />
                </Stack>
                <Typography variant="h4">Contact et domicile</Typography>
                <Stack direction="row" spacing={2}>
                  <TextField
                    {...textFieldOptions}
                    label="Tel Fixe/Portable"
                    name="phone"
                    required={true}
                    placeholder="Tel Fixe/Portable"
                    title="Tel Fixe/Portable"
                    sx={{ width: 230 }}
                    onFocus={() => setCurrentDocument("da")}
                    inputProps={{
                      readOnly: batch.isLegionnaire === true,
                    }}
                  />
                  <TextField
                    {...textFieldOptions}
                    label="Email"
                    name="email"
                    required={true}
                    placeholder="Email"
                    title="Email"
                    sx={{ width: 190 }}
                    onFocus={() => setCurrentDocument("da")}
                    inputProps={{
                      readOnly: batch.isLegionnaire === true,
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <BanAddressField
                    name="banAddress"
                    label={"Recherche adresse domicile"}
                    {...textFieldOptions}
                    // fullWidth={true}
                    updateAddress={form.mutators.setAddressFields}
                    sx={{ width: 360 }}
                    onFocus={() => setCurrentDocument("da")}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextField
                    {...textFieldOptions}
                    label="Bâtiment / Chambre"
                    name="building"
                    required={false}
                    placeholder="Bâtiment / Chambre"
                    title="Bâtiment / Chambre"
                    sx={{ width: 190 }}
                    onFocus={() => setCurrentDocument("da")}
                  />
                  <TextField
                    {...textFieldOptions}
                    label="Complément d'adresse"
                    name="additional"
                    placeholder="Complément d'adresse"
                    title="Complément d'adresse"
                    sx={{ width: 230 }}
                    onFocus={() => setCurrentDocument("da")}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextField
                    {...textFieldOptions}
                    label="N°"
                    name="streetNumber"
                    required={false}
                    placeholder="N°"
                    title="N°"
                    sx={{ width: 90 }}
                    onFocus={() => setCurrentDocument("da")}
                  />
                  <Autocomplete
                    size="small"
                    disablePortal
                    name="streetType"
                    required={true}
                    label="Type voie"
                    options={["", ...LOV.streetType.map((a) => a.label)]}
                    sx={{ width: 200 }}
                    onFocus={() => setCurrentDocument("da")}
                  />
                  <TextField
                    {...textFieldOptions}
                    label="Voie"
                    name="street"
                    required={true}
                    placeholder="Voie"
                    title="Voie"
                    fullWidth={true}
                    onFocus={() => setCurrentDocument("da")}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextField
                    {...textFieldOptions}
                    label="Code Postal"
                    name="zipCode"
                    required={true}
                    placeholder="Code Postal"
                    title="Code Postal"
                    sx={{ width: 130 }}
                    onFocus={() => setCurrentDocument("da")}
                  />
                  <TextField
                    {...textFieldOptions}
                    label="Ville"
                    required={true}
                    placeholder="Ville"
                    title="Ville"
                    name="city"
                    sx={{ width: 200 }}
                    onFocus={() => setCurrentDocument("da")}
                  />
                  <Autocomplete
                    size="small"
                    name={"country"}
                    disablePortal
                    label="Pays"
                    required={true}
                    options={["", ...LOV.country.map((a) => a.label)]}
                    sx={{ width: 190 }}
                    onFocus={() => setCurrentDocument("da")}
                  />
                </Stack>

                <Typography variant="h4">Activité professionnelle</Typography>
                <Stack direction="row" spacing={2}>
                  <Autocomplete
                    disabled={batch.isLegionnaire === true}
                    size="small"
                    label="Statut métier"
                    disablePortal
                    options={["", ...LOV.jobStatus.map((a) => a.label)]}
                    sx={{ width: 250 }}
                    name={"jobStatus"}
                    required={true}
                    onFocus={() => setCurrentDocument("da")}
                    onInputChange={(e, newInputValue) =>
                      updateArmyPossibleValues(
                        newInputValue,
                        form.mutators.resetArmyCorps
                      )
                    }
                  />
                  <Autocomplete
                    disabled={batch.isLegionnaire === true}
                    disablePortal
                    label="Corps d'affectation"
                    size="small"
                    sx={{ width: 250 }}
                    options={["", ...armyCorpsPossibleValues]}
                    name={"armyCorps"}
                    required={true}
                    onFocus={() => setCurrentDocument("da")}
                  />
                </Stack>
                <Switches
                  name="isStudent"
                  data={{
                    label: "Elève (uniquement élève lycée technique)",
                    // value: subscription.isStudent,
                  }}
                />
              </Stack>

              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                sx={{ marginTop: "10px" }}
              >
                <Button color="secondary" variant="contained" onClick={goBack}>
                  Précédent
                </Button>
                <Button color="info" variant="contained" type="submit">
                  Valider
                </Button>
              </Stack>
            </div>

            <div className="Subscription__documentBlock-container">
              <div className="Subscription__btPrecedent">
                <Link to={`/batch-subscriptions/batches/${batchId}`}>
                  <Button
                    variant="outlined"
                    disabled
                    startIcon={<KeyboardArrowLeft />}
                  >
                    PRÉCÉDENT
                  </Button>
                </Link>
              </div>
              <DocumentBlock
                documents={documents}
                currentDocument={currentDocument}
                setCurrentDocument={setCurrentDocument}
                subscriptionId={subscriptionId}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default SubscriptionFormPerson;
