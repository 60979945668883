import React, { useState, useRef, useEffect, useContext } from "react";
import { Button, Icon, Stack } from "@mui/material";
import CardField from "../components/CardField/CardField";
import { BasketContext } from "../context";
import "./FleetField.scss";
import ClearIcon from "@mui/icons-material/Clear";
import { periodicityLabel } from "../model/Basket";

const Component = ({ registry, events, ...args }) => {
  const { navigationState, goTo, basket, product } = useContext(BasketContext);

  const [items, setItems] = useState([]);
  const [maxFleetItems, setMaxFleetItems] = useState(0);

  const addItemAndGo = () => {
    basket.addItem(args.asset.code);
    goTo(args.form.id, basket.getItems(args.asset.code).length - 1);
  };

  useEffect(() => {
    if (navigationState.asset) return;

    const { asset } = args;
    const fleetDef = product.fleet.find((a) => a.asset.id === asset.id);
    const assetDef = fleetDef?.asset;
    setMaxFleetItems(fleetDef?.max || 0);
    const _items = basket.getItems(asset.code).map((item, i) => {
      const type = assetDef.types.find((t) => t.code === item.type);
      if (!type) return item;
      const productChoice = product.choices.find((c) => c.code === args.choice);
      if (!productChoice) return item;
      const itemChoice = item.getChoice(args.choice);
      const productOption = itemChoice
        ? productChoice.options.find((o) => o.code === itemChoice.selected)
        : null;
      return {
        isComplete: item.isComplete(),
        currentPage: item.currentPage(),
        premium: item.premium,
        periodicPremium: item.periodicPremium,
        periodicity: item.periodicity,
        title: item.title || assetDef.title + " n°" + (i + 1),
        periodicPremiumDiscounted: item.periodicPremiumDiscounted,
        type,
        productOption,
        delta: item.periodicDiscount,
      };
    });
    setItems(_items);
    // Ajout
    if (_items.length === 0) {
      addItemAndGo();
    }
    // Reprise sur arrêt
    for (let i = 0; i < _items.length; i++) {
      if (_items[i].isComplete) continue;
      console.log("Fleet is impossible !");
      goTo(_items[i].currentPage || args.form.id, i);
      break;
    }
  }, [navigationState, basket, product]);

  return (
    <Stack direction={"row"} flexWrap={"wrap"} gap={2} alignItems={"stretch"}>
      {items.map((item, i) => (
        <CardField selected={false} key={i} className={"FleetItem"}>
          {item.type?.image && (
            <img className={"FleetItem__Image"} src={item.type.image.url} />
          )}
          {item.delta && (
            <div className={"FleetItem__Ruban"}>{item.delta.toFixed(2)}€</div>
          )}
          <div className={"FleetItem__Title"}>{item.title}</div>
          {item.delta && (
            <div className={"FleetItem__Price"}>
              {item.periodicPremium.toFixed(2)}€
              <span>/{periodicityLabel[item.periodicity]}</span>
            </div>
          )}
          <div className={"FleetItem__PriceDiscounted"}>
            {item.periodicPremiumDiscounted.toFixed(2)}€
            <span>/{periodicityLabel[item.periodicity]}</span>
          </div>
          <div className={"FleetItem__Option"}>{item.productOption?.title}</div>
          <Button
            variant={"contained"}
            color={"info"}
            onClick={() => goTo(args.form.id, i)}
          >
            Modifier
          </Button>
          {/*items.length > 1 && <Icon color={"error"} sx={{position:"absolute",top:5,right:5}}><ClearIcon/></Icon>*/}
        </CardField>
      ))}

      {items.length < maxFleetItems && (
        <CardField
          selected={true}
          onClick={() => addItemAndGo()}
          className={"FleetItem__Add"}
        >
          <svg
            width="36"
            height="35"
            viewBox="0 0 36 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.3389 19.9316H20.2317V34.5232H15.196V19.9316H0.0888672V15.0677H15.196V0.476074H20.2317V15.0677H35.3389V19.9316Z"
              fill="white"
            />
          </svg>
          Ajouter
        </CardField>
      )}
    </Stack>
  );
};

export default Component;
