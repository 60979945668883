import React, {useState, useRef} from 'react'

const Component = ({registry, events, ...args}) => {
    const [checked, setChecked] = useState(!!args.checked);
    const [label, setLabel] = useState(!!args.label);
    const ref = useRef(null);

    const api = {};
    Object.defineProperties(api, {
        'checked': {
            get: () => checked,
            set: (v) => setChecked(v)
        },
        'label': {
            get: () => checked,
            set: (v) => setChecked(v)
        },
    });

    registry.set(args.code, api);

    return <div ref={ref}>{label} {checked}</div>
}

export default Component;
