import { apiPublicGetOne, apiPublicPost } from "../../../core/services";

export const getBasketQuote = async (basketId) => {
  return (
    await apiPublicPost("/scripts/getQuote/execute", {
      basketId: basketId,
    })
  ).quote;
};

export const createQuote = async (basketId) => {
  return await apiPublicPost("/quotes/basket/" + basketId, {});
};

export const getQuoteSteps = async (quoteId) => {
  return await apiPublicGetOne(`/quotes/${quoteId}/steps`);
};

export const getQuote = async (quoteId) => {
  return await apiPublicGetOne(`/quotes/${quoteId}`);
};
