import { Stack, Typography } from "@mui/material";
import React from "react";
import "./GenerateDocumentStep.scss";

const GenerateDocumentStep = (props) => {
  const { title, outputs, documents } = props;

  const docs = documents
    ?.map((d) => {
      const output = outputs?.find((o) => o.type === d.code)?.id || null;

      return {
        ...d,
        id: output,
      };
    })
    .filter((d) => !!d.id);

  const apiUrl = process.env.REACT_APP_API_URL;
  return (
    <>
      <Typography color={"#024E7D"} variant="h6">
        {title || "Remises de documents"}
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        {docs.map((elt) => (
          <a
            className={"GenerateDocumentStep__Link"}
            key={elt.id}
            href={`${apiUrl}/ged?id=${elt.id}`}
          >
            {elt.title}
          </a>
        ))}
      </Stack>
    </>
  );
};
export default GenerateDocumentStep;
