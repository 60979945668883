import React, {useState} from 'react'
import ReactMarkdown from "react-markdown";

const Component = ({registry, events, ...args}) => {
    const [text, setText] = useState(args.text || '');
    const [visible, setVisible] = useState(true);

    const api = {};
    if(args.code) {
        Object.defineProperties(api, {

            'text': {
                get: () => text,
                set: (v) => setText(v)
            },

            'visible': {
                get: () => visible,
                set: (v) => setVisible(v)
            },
        });

        registry.set(args.code, api);
    }

    if(!visible) return <></>

    return <ReactMarkdown>{text}</ReactMarkdown>
}

export default Component;
