import {Box, Button, Container, Grid, Stack, Typography} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import stopSign from "../../assets/images/stop-sign.png";

const NotFoundPage = () => {
  return (
      <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 108px)",
          }}
      >
        <Container maxWidth="md">
          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} gap={3}>
            <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
              <Typography variant="h1" color="primary">
                Erreur 404
              </Typography>
              <Typography variant="h6">
                La page que vous demandez n'existe pas.
              </Typography>
              <Link to="/">
                <Button
                    sx={{ margin: "17px auto 23px auto" }}
                    variant="contained"
                >
                  Retour à l'accueil
                </Button>
              </Link>
            </Stack>
            <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
              <img
                  src={stopSign}
                  alt="panneau stop 404"
                  width={"100%"}
                  style={{ maxWidth: "400px" }}
                  height={"auto"}
              />
            </Stack>
          </Stack>
        </Container>
      </Box>
  );
};

export default NotFoundPage;
