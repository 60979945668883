import { Basket } from "../model/Basket";
import {
  apiPublicGetOne,
  apiPublicPatch,
  apiPublicPost,
} from "../../../core/services";

let _basketMap = new Map();

export const resetBasket = async (_basket) => {
  //_basket.fromJson({});
  localStorage.removeItem(_basket.product + "_bId");
  window.location.reload();
};

export const getBasket = async (product, externalRef) => {
  if (!_basketMap.has(product))
    _basketMap.set(product, await loadBasket(product, externalRef));
  return _basketMap.get(product);
};

export const getBasketByExternalRef = async (externalRef) => {
  const rawBasket = await apiPublicGetOne(
    "/baskets/externalRef/" + externalRef
  );
  return Basket(rawBasket);
};

export const saveBasket = async (_basket) => {
  const rawBasket = await apiPublicPatch(
    "/baskets/" + _basket.id,
    _basket.toJson()
  );
  _basket.fromJson(rawBasket);
};

export const reloadBasket = async (_basket) => {
  const rawBasket = await apiPublicGetOne("/baskets/" + _basket.id);
  _basket.fromJson(rawBasket);
};

const loadBasket = async (product, externalRef) => {
  let basketId = localStorage.getItem(product + "_bId");
  let rawBasket;

  if (basketId) {
    try {
      rawBasket = await apiPublicGetOne("/baskets/" + basketId);
    } catch (e) {
      console.error(e);
    }
  }

  if (!rawBasket) {
    const params = {
      product,
    };
    if (externalRef) params.externalRef = externalRef;
    rawBasket = await apiPublicPost("/baskets", params);
    localStorage.setItem(product + "_bId", rawBasket._id);
  }

  return Basket(rawBasket);
};

export const loadBasketById = async (basketId) => {
  return Basket(await apiPublicGetOne("/baskets/" + basketId));
};
