import React, {useEffect, useState} from 'react'
import {ImageList, Stack} from "@mui/material";
import CardField from "../components/CardField/CardField";
import LongLabel from "../components/LongLabel/LongLabel";
import {evalEvent, useTrackableState} from "../service/PageService";

const ChoiceField = ({registry, events, ...args}) => {
    const [value, setValue] = useTrackableState("");
    const [forbidden, setForbidden] = useTrackableState([]);
    const [label, setLabel] = useTrackableState(args.label);
    const [visible, setVisible] = useTrackableState(true);

    if(args.code) {
        const api = {};
        Object.defineProperties(api, {
            forbidden: {
                get: () => forbidden,
                set: (v) => setForbidden(v)
            },
            value: {
                get: () => value,
                set: (v) => setValue(v)
            },
            label: {
                get: () => label,
                set: (v) => setLabel(v)
            },
            visible: {
                get: () => visible,
                set: (v) => setVisible(v)
            },
        });
        registry.set(args.code, api);
    }

    useEffect(() => {
        evalEvent(events, 'change').catch();
    }, [value]);

    if(!visible) return <></>


    return <Stack direction={"column"} gap={1}>
        {label && <LongLabel label={label}/>}
        <Stack direction={"row"}>
            <ImageList cols={3} rowHeight={176} gap={21} sx={{
                padding: '21px',
                marginLeft: '-21px'
            }}>
                {args.options.map((option,i) =>
                    <CardField
                        key={i}
                        disabled={forbidden.indexOf(option.code)>=0}
                        onChange={(state) => {
                            if(state) setValue(option.code)
                        }}
                        selected={option.code === value}
                        onClick={()=>evalEvent(events, 'click')}
                    >
                        {option.image && <img src={option.image.url}/>}
                        {option.title}
                    </CardField>
                )}
            </ImageList>
        </Stack>
    </Stack>
}

export default ChoiceField;
