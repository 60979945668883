import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
    Dialog,
    DialogContent,
    Typography,
    Alert,
    AlertTitle, Button, CircularProgress,
} from "@mui/material";
import React from "react";
const IframeSignDialog = (props) => {
  const { open, onClose, circuit } = props;

  const [loading, setLoading] = useState(true);
  const [iframeSrc, setIframeSrc] = useState("");
  const handleClose = () => {
    onClose();
    setLoading(true);
  };
  useEffect(() => {
    if (circuit && circuit.eSigLink) {
      setIframeSrc(circuit.eSigLink);
    }
  }, [circuit]);
  return (
    <Dialog onClose={handleClose} open={open} fullScreen={true}>
        <Button variant={"contained"}
                sx={{
                    position: "absolute",
                    right: 80,
                    top: 14
                }}
                startIcon={<CloseIcon />}
                onClick={handleClose}>
            Retour
        </Button>
      <DialogContent sx={{
          padding:0,
          display: "flex",
          alignItems: "strech"
      }}>
          {iframeSrc && loading &&  <CircularProgress sx={{
              position: "absolute",
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
          }}/>}
          {iframeSrc ? (
            <iframe
              title={"Lien docapost"}
              src={iframeSrc}
              onLoad={() => setLoading(false)}
              style={{flex:1}}
            ></iframe>
          ) : (
            <Alert severity="error">
              <AlertTitle>Impossible d'ouvrir le lien DocaPost</AlertTitle>
              <Typography sx={{ whiteSpace: "pre" }}>
                {`Si le problème persiste, veuillez-vous rapprocher du support.
L'équipe technique de Carene.`}
              </Typography>
            </Alert>
          )}
      </DialogContent>
    </Dialog>
  );
};
export default IframeSignDialog;
