import React, {useEffect, useRef} from 'react'
import LongLabel from "../components/LongLabel/LongLabel";
import {Stack, TextField} from "@mui/material";
import {evalEvent, useTrackableState} from "../service/PageService";

import "./TextField.scss";

const Component = ({registry, events, ...args}) => {
    const [label, setLabel] = useTrackableState(args.label || '');
    const [required, setRequired] = useTrackableState(args.required || false);
    const [pattern, setPattern] = useTrackableState(args.pattern || '');
    const [error, setError] = useTrackableState(false);
    const [readonly, setReadonly] = useTrackableState(false);
    const [value, setValue] = useTrackableState('');
    const [firstTime, setFirstTime] = useTrackableState(true);
    const [placeholder, setPlaceHolder] = useTrackableState(args.placeholder || '');
    const [visible, setVisible] = useTrackableState(true);
    const ref = useRef();

    const validates = async () => {

        const state = {
            error: false,
            messages: []
        }

        if(firstTime) {
            setFirstTime(false);
            return state.error;
        }


        if(required && !value) {
            state.error = true;
            state.messages.push("Champ '"+label+"' obligatoire.");
        }

        if(value && pattern && !value.match(new RegExp(pattern))) {
            state.error = true;
            state.messages.push("Format incorrect.");
        }


        await evalEvent(events, 'validate', {
            status:state
        });

        setError(state.error ? state.messages.join("\n"):false);

        return state;
    };

    useEffect(  () => {
        validates().then(async () => evalEvent(events,'change'))
    }, [value, required, pattern]);

    useEffect(() => {
        evalEvent(events, 'change').catch();
    }, [value]);

    if(args.code) {

        const api = {
            'focus': () => {ref.current.focus()},
            'validates': validates,
        };
        Object.defineProperties(api, {
            'label': {
                get: () => label,
                set: (v) => setLabel(v)
            },
            'visible': {
                get: () => visible,
                set: (v) => setVisible(v)
            },
            'value': {
                get: () => value,
                set: (v) => setValue(v)
            },
            'readonly': {
                get: () => readonly,
                set: (v) => setReadonly(v)
            },
            'placeholder': {
                get: () => placeholder,
                set: (v) => setPlaceHolder(v)
            },
            'required': {
                get: () => required,
                set: (v) => setRequired(v)
            },
            'pattern': {
                get: () => pattern,
                set: (v) => setPattern(v)
            },
            'error': {
                get: () => error,
                set: (v) => setError(v)
            }
        });

        registry.set(args.code, api);

    }


    if(!visible) return <></>

    return <Stack direction={"column"} gap={1} style={{flex:1}}>
        {label && <LongLabel label={label} required={required}/>}
        <TextField value={value}
                   className={readonly?"TextField__readonly":""}
                   placeholder={placeholder}
                   error={!!error}
                   helperText={error}
                   InputProps={{
                       readOnly: readonly
                   }}
                   inputRef={ref}
                   onFocus={() => evalEvent(events, 'focus')}
                   onClick={() => evalEvent(events, 'click')}
                   onChange={(e) => setValue(e.target.value)}>
        </TextField>
    </Stack>
}

export default Component;
