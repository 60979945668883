import React, { useState, useEffect, useContext } from "react";
import { DataGrid, frFR } from "@mui/x-data-grid";
import {
  Box,
  Chip,
  CircularProgress,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getSessions } from "../../../../core/services";
import { statuses } from "../../../../core/utils/statuses";
import CustomPagination from "../../../../core/utils/CustomPagination";
import { LoadingContext } from "../../../../core/utils/loading";

const BatchSubscriptionList = () => {
  const columns = [
    {
      field: "SessionDate",
      headerName: "Date Session",
      width: 100,
      renderCell: (param) => (
        <>
          {new Date(param.row.sessionDate).toLocaleString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </>
      ),
    },
    {
      field: "sessionName",
      headerName: "Nom",
      width: 150,
    },
    {
      field: "createdBy",
      headerName: "Conseiller",
      width: 220,
      renderCell: (params) => `${params.row.createdBy || ""}`.toUpperCase(),
    },
    {
      field: "status",
      headerName: "Statut",
      width: 120,
      renderCell: (param) => (
        <Chip
          label={statuses[param.value]?.label || "Inconnu"}
          size="small"
          color={statuses[param.value]?.tag || "primary"}
        />
      ),
    },
    {
      field: "nbNotCompletedSubscriptions",
      headerName: "Nb à compléter",
      width: 120,
    },
    {
      field: "nbSavedSubscriptions",
      headerName: "Nb enregistrées",
      width: 120,
    },
  ];

  const { startLoading, endLoading } = useContext(LoadingContext);
  const [batches, setBatches] = useState(null);
  const [hideArchived, setHideArchived] = useState(() =>
    JSON.parse(localStorage.getItem("status=ne:ARCHIVED") || false)
  );
  const navigate = useNavigate();

  const reloadBatches = async () => {
    startLoading();
    await getSessions(hideArchived).then((res) => {
      endLoading();
      setBatches(res);
    });
  };

  useEffect(() => {
    localStorage.setItem("status=ne:ARCHIVED", hideArchived);
    reloadBatches();
  }, [hideArchived]);
  return (
    <div className="BatchSubscription Page">
      {!batches ? (
        <CircularProgress />
      ) : (
        <>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ marginBottom: "10px" }}
          >
            <Typography variant="h5">
              <FormControlLabel
                control={
                  <Switch
                    checked={hideArchived}
                    onChange={(e, v) => setHideArchived(v)}
                  />
                }
                label="Masquer souscriptions archivées"
              />
            </Typography>
          </Stack>
          {/* Grille */}
          <Box height={414.5}>
            <DataGrid
              rows={batches}
              onRowClick={(row) =>
                navigate(`/batch-subscriptions/batches/${row.id}`)
              }
              columns={columns}
              density="compact"
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              getRowId={(row) => row._id}
              pageSize={9}
              // rowsPerPageOptions={[10, 25, 50, 100, 200]}
              disableSelectionOnClick
              components={{
                Pagination: CustomPagination,
              }}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default BatchSubscriptionList;
