import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  IconButton,
  Typography,
  Alert,
} from "@mui/material";
import "./BatchSubscription.scss";
import SearchIcon from "@mui/icons-material/Search";
import { frFR, DataGrid } from "@mui/x-data-grid";
import {
  createSubscription,
  getBatch,
  saveBatch,
  saveSubscription,
} from "../../../../core/services/BatchSubscriptionService";
import { statuses } from "../../../../core/utils/statuses";
import deleteIcon from "../../../../assets/images/delete-icon.svg";
import editIcon from "../../../../assets/images/edit-icon.svg";
import CustomPagination from "../../../../core/utils/CustomPagination";
import EditBatchModal from "./EditBatchModal";

const BatchSubscription = () => {
  const { batchId } = useParams();
  const [batch, setBatch] = useState(null);
  const navigate = useNavigate();
  const [rows, setRows] = useState(null);
  const [showArchiveButton, setShowArchiveButton] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [sessionName, setSessionName] = React.useState("");
  const [sessionDate, setSessionDate] = React.useState("");
  const disableButton = false;
  const [loading, setLoading] = useState(false);

  const setIsLegionnaire = (v) => (batch.isLegionnaire = v);
  const setIsStudent = (v) => (batch.isStudent = v);
  const setRiskAddress = (v) => (batch.riskAddress = v);

  const handleAddSubscription = () => {
    let data = batch.isLegionnaire
      ? {
          isLegionnaire: batch.isLegionnaire,
          isStudent: batch.isStudent,
          periodicity: "A",
          policyHolder: {
            phone: "0149950046",
            email: "allianzprotect@carene.fr",
            jobStatus: "STATUT_PRO_1",
            armyCorps: "CORPS_AFF_01",
          },
        }
      : batch.isStudent
      ? {
          isStudent: true,
          periodicity: "A",
        }
      : {};
    createSubscription(batchId, data).then((subscription) => {
      handleEditSubscription(subscription);
    });
  };

  const handleDeleteSubscription = (subscription) => {
    subscription.status = "DELETED";
    updateRows();
    saveSubscription(subscription).catch((e) => {
      console.error(e);
      reloadBatch();
    });
  };

  const handleEditSubscription = (subscription) => {
    navigate(
      `/batch-subscriptions/${subscription.batchSubscriptionId}/subscriptions/${subscription._id}/`
    );
  };

  const handleBatchArchive = () => {
    batch.status = "ARCHIVED";
    saveBatch(batch).catch((e) => {
      console.error(e);
    });
    window.location.reload();
  };

  const handleDeleteArchive = () => {
    batch.status = "DELETED";
    saveBatch(batch)
      .catch((e) => {
        console.error(e);
        reloadBatch();
      })
      .then(() => {
        navigate("/batch-subscriptions");
      });
  };

  const reloadBatch = useCallback(() => {
    setLoading(true);
    getBatch(batchId, true)
      .then((batch) => {
        setBatch(batch);
        setSessionName(batch.sessionName);
        setSessionDate(batch.sessionDate);
        setLoading(false);
      })
      .catch((e) => {
        navigate("/batch-subscriptions/batches/list");
        setLoading(false);
      });
  }, [batchId, navigate]);

  const all = (arr, ...values) => {
    return arr && arr.length > 0 && arr.every((v) => values.indexOf(v) >= 0);
  };

  const isArchiavable =
    batch?.status !== "ARCHIVED" && batch?.status !== "DELETED";

  const updateRows = () => {
    setRows(
      (batch?.subscriptions || [])
        .filter((s) => s.status !== "DELETED")
        .filter(
          (s) =>
            (
              s.policyHolder.lastName +
              s.policyHolder.firstName +
              s.policyHolder.birthLastName +
              s.policyHolder.email +
              s.policyHolder.phone
            )
              .toUpperCase()
              .indexOf(searchedValue.toUpperCase()) >= 0
        )
    );
    setShowArchiveButton(
      isArchiavable &&
        all(
          batch?.subscriptions
            .filter((s) => s.status !== "DELETED")
            .map((s) => s.status),
          "SEF",
          "SUCCESS"
        )
    );
    setShowDeleteButton(
      batch?.subscriptions.filter((s) => s.status !== "DELETED").length === 0
    );
    setShowAddButton(isArchiavable);
  };

  useEffect(() => {
    reloadBatch();
  }, [batchId, reloadBatch]);

  useEffect(() => {
    updateRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batch, searchedValue]);

  const columns = [
    {
      field: "subscriber",
      headerName: "Souscripteur",
      width: 200,
      renderCell: (params) =>
        `${params.row.policyHolder?.lastName || ""} ${
          params.row.policyHolder?.firstName || ""
        }`.toUpperCase(),
    },
    {
      field: "status",
      headerName: "Statut",
      width: 120,
      renderCell: (param) => (
        <Chip
          label={statuses[param.value]?.label || "Inconnu"}
          size="small"
          color={statuses[param.value]?.tag || "primary"}
        />
      ),
    },
    {
      field: "createdBy",
      headerName: "Traçabilité",
      width: 250,
      renderCell: (param) => (
        <>
          Le{" "}
          {new Date(param.row.createdOn).toLocaleString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}{" "}
          par {param.value}
        </>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return (
          statuses[params.row.status]?.editable && (
            <>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleEditSubscription(params.row);
                }}
              >
                <img alt="edit icon" src={editIcon} />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteSubscription(params.row);
                }}
              >
                <img alt="delete icon" src={deleteIcon} />
              </IconButton>
            </>
          )
        );
      },
    },
  ];

  const nbSouscripteurs = batch?.subscriptions.filter(
    (s) => s.status !== "DELETED"
  ).length;

  return (
    <div className="BatchSubscription Page">
      {!batch || loading ? (
        <CircularProgress />
      ) : (
        <>
          {/* Titre & bouton ajout souscripteur */}
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ marginBottom: "10px" }}
          >
            <Typography variant="h5">
              {`${sessionName}
              (${new Date(sessionDate).toLocaleDateString("fr")}) - 
              ${nbSouscripteurs} ${
                nbSouscripteurs <= 1 ? "souscripteur" : "souscripteurs"
              }`}
            </Typography>
            {isArchiavable && (
              <EditBatchModal
                sessionId={batch._id}
                sessionName={sessionName}
                sessionDate={sessionDate}
                address={batch.riskAddress}
                isLegionnaire={batch.isLegionnaire}
                isStudent={batch.isStudent}
                setSessionName={setSessionName}
                setSessionDate={setSessionDate}
                setAddress={setRiskAddress}
                setIsLegionnaire={setIsLegionnaire}
                setIsStudent={setIsStudent}
              />
            )}
            {showAddButton && (
              <Button
                variant="contained"
                color="info"
                onClick={() => handleAddSubscription()}
              >
                Ajouter
              </Button>
            )}

            {showArchiveButton && (
              <Button
                variant="contained"
                color="secondary"
                disabled={disableButton}
                onClick={() => handleBatchArchive()}
              >
                Archiver
              </Button>
            )}
            {showDeleteButton && (
              <Button
                variant="contained"
                color="secondary"
                disabled={disableButton}
                onClick={() => handleDeleteArchive()}
              >
                Supprimer
              </Button>
            )}
          </Stack>
          {/* Menu recherche */}
          <div className="BatchSubscription__search">
            <TextField
              id="search"
              placeholder="Rechercher"
              onChange={(e) => setSearchedValue(e.target.value)}
              type="search"
              size="small"
              defaultValue=""
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ paddingBottom: 5 }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              sx={{
                width: 250,
                bgcolor: "white",
                padding: "5px 8px 0",
                margin: "20px 0",
              }}
              variant="standard"
            />
            {batch?.subscriptions.find(
              (subscription) => subscription.status === "ERROR"
            ) && (
              <Alert severity="warning">
                Votre session contient au moins une souscription en erreur,
                l'administrateur de l'outil est prévenu et se charge de
                débloquer la situation. Inutile de ressaisir votre liasse.
              </Alert>
            )}
          </div>
          {/* Grille */}
          <Box height={414.5}>
            <DataGrid
              rows={rows}
              columns={columns}
              density="compact"
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              getRowId={(row) => row._id}
              pageSize={9}
              // rowsPerPageOptions={[10, 25, 50, 100, 200]}
              disableSelectionOnClick
              components={{
                Pagination: CustomPagination,
              }}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default BatchSubscription;
