import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import ReactMarkdown from "react-markdown";
import InfoIcon from "@mui/icons-material/Info";
import "./AdviceCard.scss";
import rehypeRaw from "rehype-raw";
import Button from "@mui/material/Button";
import { evalEvent, evalScript } from "../../service/PageService";

const AdviceCard = ({ registry, advice }) => {
  const [visible, setVisible] = useState(true);

  if (advice.code) {
    const api = {};

    Object.defineProperties(api, {
      visible: {
        get: () => visible,
        set: (v) => setVisible(v),
      },
    });
    registry.set(advice.code, api);
  }

  if (!visible) return <></>;

  return (
    <Box className={"AdviceCard"}>
      {advice.image && (
        <div
          className={"AdviceCard__Image"}
          style={{ backgroundImage: "url(" + advice.image.url + ")" }}
        />
      )}
      <h2>
        <InfoIcon />
        {advice.title}
      </h2>
      <div className={"AdviceCard__Body"}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {advice.content}
        </ReactMarkdown>
      </div>
      {advice.buttons.map((button, i) => (
        <Button
          key={i}
          children={button.title || ""}
          variant={"contained"}
          color={button.style || "primary"}
          onClick={() => {
            evalScript(
              "Bouton " + button.title,
              button.script,
              window.$api,
              () => false
            ).catch();
          }}
        />
      ))}
    </Box>
  );
};

export default AdviceCard;
