import { Routes, Route, Navigate } from "react-router-dom"
import NewSession from "./components/NewSession/NewSession"
import SubscriptionIndex from './components/Subscription/SubscriptionIndex';
import BatchSubscriptionTabs from "./components/BatchSubscriptionTabs/BatchSubscriptionTabs";
import React, { createContext, useState } from "react";
import Navigation from "./components/Navigation/Navigation";

export const BatchSubscriptionContext = createContext({});

function BatchSubscriptions() {
  const [forceBatchTabsReload, setForceBatchTabsReload] = useState(0)
  return (
    <>
        <Navigation />

        <BatchSubscriptionContext.Provider
        value={{ forceBatchTabsReload, setForceBatchTabsReload }}
      >
        <Routes>
          <Route path="/*" element={<Navigate to="/batch-subscriptions/batches/list" />}></Route>
          <Route path="/batches/new" element={<NewSession />}></Route>
          <Route path="/batches/*" element={<BatchSubscriptionTabs />}></Route>
          <Route path="/:batchId/subscriptions/:subscriptionId/*" element={<SubscriptionIndex />}></Route>
        </Routes>
      </BatchSubscriptionContext.Provider>
    </>
  );
}

export default BatchSubscriptions;
