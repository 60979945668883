const countries = [
  {
    label: "France",
    id: "FRA",
    code: "250",
  },
  {
    label: "Afghanistan",
    id: "AFG",
    code: "004",
  },
  {
    label: "Afrique du Sud",
    id: "ZAF",
    code: "710",
  },
  {
    label: "Albanie",
    id: "ALB",
    code: "008",
  },
  {
    label: "Algérie",
    id: "DZA",
    code: "012",
  },
  {
    label: "Allemagne",
    id: "DEU",
    code: "276",
  },
  {
    label: "Andorre",
    id: "AND",
    code: "020",
  },
  {
    label: "Angola",
    id: "AGO",
    code: "024",
  },
  {
    label: "Anguilla",
    id: "AIA",
    code: "660",
  },
  {
    label: "Antarctique",
    id: "ATA",
    code: "010",
  },
  {
    label: "Antigua-et-Barbuda",
    id: "ATG",
    code: "028",
  },
  {
    label: "Arabie Saoudite",
    id: "SAU",
    code: "682",
  },
  {
    label: "Argentine",
    id: "ARG",
    code: "032",
  },
  {
    label: "Arménie",
    id: "ARM",
    code: "051",
  },
  {
    label: "Aruba",
    id: "ABW",
    code: "533",
  },
  {
    label: "Australie",
    id: "AUS",
    code: "036",
  },
  {
    label: "Autriche",
    id: "AUT",
    code: "040",
  },
  {
    label: "Azerbaïdjan",
    id: "AZE",
    code: "031",
  },
  {
    label: "Bahamas",
    id: "BHS",
    code: "044",
  },
  {
    label: "Bahreïn",
    id: "BHR",
    code: "048",
  },
  {
    label: "Bangladesh",
    id: "BGD",
    code: "050",
  },
  {
    label: "Barbade",
    id: "BRB",
    code: "052",
  },
  {
    label: "Belgique",
    id: "BEL",
    code: "056",
  },
  {
    label: "Belize",
    id: "BLZ",
    code: "084",
  },
  {
    label: "Bénin",
    id: "BEN",
    code: "204",
  },
  {
    label: "Bermudes",
    id: "BMU",
    code: "060",
  },
  {
    label: "Bhoutan",
    id: "BTN",
    code: "064",
  },
  {
    label: "Biélorussie",
    id: "BLR",
    code: "112",
  },
  {
    label: "Bolivie",
    id: "BOL",
    code: "068",
  },
  {
    label: "Bosnie-Herzégovine",
    id: "BIH",
    code: "070",
  },
  {
    label: "Botswana",
    id: "BWA",
    code: "072",
  },
  {
    label: "Brésil",
    id: "BRA",
    code: "076",
  },
  {
    label: "British Virgin Islands",
    id: "VGB",
    code: "092",
  },
  {
    label: "Brunei Darussalam",
    id: "BRN",
    code: "096",
  },
  {
    label: "Bulgarie",
    id: "BGR",
    code: "100",
  },
  {
    label: "Burkina Faso",
    id: "BFA",
    code: "854",
  },
  {
    label: "Burundi",
    id: "BDI",
    code: "108",
  },
  {
    label: "Cambodge",
    id: "KHM",
    code: "116",
  },
  {
    label: "Cameroun",
    id: "CMR",
    code: "120",
  },
  {
    label: "Canada",
    id: "CAN",
    code: "124",
  },
  {
    label: "Cap-Vert",
    id: "CPV",
    code: "132",
  },
  {
    label: "Chili",
    id: "CHL",
    code: "152",
  },
  {
    label: "Chine",
    id: "CHN",
    code: "156",
  },
  {
    label: "Chypre",
    id: "CYP",
    code: "196",
  },
  {
    label: "Colombie",
    id: "COL",
    code: "170",
  },
  {
    label: "Comores",
    id: "COM",
    code: "174",
  },
  {
    label: "Corée du Nord",
    id: "PRK",
    code: "408",
  },
  {
    label: "Corée du Sud",
    id: "KOR",
    code: "410",
  },
  {
    label: "Costa Rica",
    id: "CRI",
    code: "188",
  },
  {
    label: "Côte d’Ivoire",
    id: "CIV",
    code: "384",
  },
  {
    label: "Croatie",
    id: "HRV",
    code: "191",
  },
  {
    label: "Cuba",
    id: "CUB",
    code: "192",
  },
  {
    label: "Danemark",
    id: "DNK",
    code: "208",
  },
  {
    label: "Djibouti",
    id: "DJI",
    code: "262",
  },
  {
    label: "Dominique",
    id: "DMA",
    code: "212",
  },
  {
    label: "Égypte",
    id: "EGY",
    code: "818",
  },
  {
    label: "Émirats Arabes Unis",
    id: "ARE",
    code: "784",
  },
  {
    label: "Équateur",
    id: "ECU",
    code: "218",
  },
  {
    label: "Érythrée",
    id: "ERI",
    code: "232",
  },
  {
    label: "Espagne",
    id: "ESP",
    code: "724",
  },
  {
    label: "Estonie",
    id: "EST",
    code: "233",
  },
  {
    label: "Eswatini",
    id: "SWZ",
    code: "748",
  },
  {
    label: "États-Unis",
    id: "USA",
    code: "840",
  },
  {
    label: "Éthiopie",
    id: "ETH",
    code: "231",
  },
  {
    label: "Fidji",
    id: "FJI",
    code: "242",
  },
  {
    label: "Finlande",
    id: "FIN",
    code: "246",
  },
  {
    label: "Gabon",
    id: "GAB",
    code: "266",
  },
  {
    label: "Gambie",
    id: "GMB",
    code: "270",
  },
  {
    label: "Géorgie",
    id: "GEO",
    code: "268",
  },
  {
    label: "Géorgie du Sud et les îles Sandwich du Sud",
    id: "SGS",
    code: "239",
  },
  {
    label: "Ghana",
    id: "GHA",
    code: "288",
  },
  {
    label: "Gibraltar",
    id: "GIB",
    code: "292",
  },
  {
    label: "Grèce",
    id: "GRC",
    code: "300",
  },
  {
    label: "Grenade",
    id: "GRD",
    code: "308",
  },
  {
    label: "Groenland",
    id: "GRL",
    code: "304",
  },
  {
    label: "Guadeloupe",
    id: "GLP",
    code: "312",
  },
  {
    label: "Guam",
    id: "GUM",
    code: "316",
  },
  {
    label: "Guatemala",
    id: "GTM",
    code: "320",
  },
  {
    label: "Guernesey",
    id: "GGY",
    code: "831",
  },
  {
    label: "Guinée",
    id: "GIN",
    code: "324",
  },
  {
    label: "Guinée équatoriale",
    id: "GNQ",
    code: "226",
  },
  {
    label: "Guinée-Bissau",
    id: "GNB",
    code: "624",
  },
  {
    label: "Guyane",
    id: "GUY",
    code: "328",
  },
  {
    label: "Guyane française",
    id: "GUF",
    code: "254",
  },
  {
    label: "Haïti",
    id: "HTI",
    code: "332",
  },
  {
    label: "Honduras",
    id: "HND",
    code: "340",
  },
  {
    label: "Hong Kong",
    id: "HKG",
    code: "344",
  },
  {
    label: "Hongrie",
    id: "HUN",
    code: "348",
  },
  {
    label: "Île Bouvet",
    id: "BVT",
    code: "074",
  },
  {
    label: "Île Christmas",
    id: "CXR",
    code: "162",
  },
  {
    label: "Ile de Man",
    id: "IMN",
    code: "833",
  },
  {
    label: "Île Norfolk",
    id: "NFK",
    code: "574",
  },
  {
    label: "Îles Åland",
    id: "ALA",
    code: "248",
  },
  {
    label: "Iles Cayman",
    id: "CYM",
    code: "136",
  },
  {
    label: "Îles Cocos",
    id: "CCK",
    code: "166",
  },
  {
    label: "Îles Cook",
    id: "COK",
    code: "184",
  },
  {
    label: "Îles Falkland",
    id: "FLK",
    code: "238",
  },
  {
    label: "Îles Féroé",
    id: "FRO",
    code: "234",
  },
  {
    label: "Îles Heard-et-MacDonald",
    id: "HMD",
    code: "334",
  },
  {
    label: "Îles Mariannes du Nord",
    id: "MNP",
    code: "580",
  },
  {
    label: "Îles Marshall",
    id: "MHL",
    code: "584",
  },
  {
    label: "Îles mineures éloignées des États-Unis",
    id: "UMI",
    code: "581",
  },
  {
    label: "Îles Salomon",
    id: "SLB",
    code: "090",
  },
  {
    label: "Îles Turques-et-Caïques",
    id: "TCA",
    code: "796",
  },
  {
    label: "Îles Vierges américaines",
    id: "VIR",
    code: "850",
  },
  {
    label: "Inde",
    id: "IND",
    code: "356",
  },
  {
    label: "Indonésie",
    id: "IDN",
    code: "360",
  },
  {
    label: "Irak",
    id: "IRQ",
    code: "368",
  },
  {
    label: "Iran",
    id: "IRN",
    code: "364",
  },
  {
    label: "Irlande",
    id: "IRL",
    code: "372",
  },
  {
    label: "Islande",
    id: "ISL",
    code: "352",
  },
  {
    label: "Israël",
    id: "ISR",
    code: "376",
  },
  {
    label: "Italie",
    id: "ITA",
    code: "380",
  },
  {
    label: "Jamaïque",
    id: "JAM",
    code: "388",
  },
  {
    label: "Japon",
    id: "JPN",
    code: "392",
  },
  {
    label: "Jersey",
    id: "JEY",
    code: "832",
  },
  {
    label: "Jordanie",
    id: "JOR",
    code: "400",
  },
  {
    label: "Kazakhstan",
    id: "KAZ",
    code: "398",
  },
  {
    label: "Kenya",
    id: "KEN",
    code: "404",
  },
  {
    label: "Kirghizistan",
    id: "KGZ",
    code: "417",
  },
  {
    label: "Kiribati",
    id: "KIR",
    code: "296",
  },
  {
    label: "Koweït",
    id: "KWT",
    code: "414",
  },
  {
    label: "Laos",
    id: "LAO",
    code: "418",
  },
  {
    label: "Lesotho",
    id: "LSO",
    code: "426",
  },
  {
    label: "Lettonie",
    id: "LVA",
    code: "428",
  },
  {
    label: "Liban",
    id: "LBN",
    code: "422",
  },
  {
    label: "Libéria",
    id: "LBR",
    code: "430",
  },
  {
    label: "Libye",
    id: "LBY",
    code: "434",
  },
  {
    label: "Liechtenstein",
    id: "LIE",
    code: "438",
  },
  {
    label: "Lituanie",
    id: "LTU",
    code: "440",
  },
  {
    label: "Luxembourg",
    id: "LUX",
    code: "442",
  },
  {
    label: "Macao",
    id: "MAC",
    code: "446",
  },
  {
    label: "Macédoine",
    id: "MKD",
    code: "807",
  },
  {
    label: "Madagascar",
    id: "MDG",
    code: "450",
  },
  {
    label: "Malaisie",
    id: "MYS",
    code: "458",
  },
  {
    label: "Malawi",
    id: "MWI",
    code: "454",
  },
  {
    label: "Maldives",
    id: "MDV",
    code: "462",
  },
  {
    label: "Mali",
    id: "MLI",
    code: "466",
  },
  {
    label: "Malte",
    id: "MLT",
    code: "470",
  },
  {
    label: "Maroc",
    id: "MAR",
    code: "504",
  },
  {
    label: "Martinique",
    id: "MTQ",
    code: "474",
  },
  {
    label: "Maurice",
    id: "MUS",
    code: "480",
  },
  {
    label: "Mauritanie",
    id: "MRT",
    code: "478",
  },
  {
    label: "Mayotte",
    id: "MYT",
    code: "175",
  },
  {
    label: "Mexique",
    id: "MEX",
    code: "484",
  },
  {
    label: "Micronésie",
    id: "FSM",
    code: "583",
  },
  {
    label: "Moldavie",
    id: "MDA",
    code: "498",
  },
  {
    label: "Monaco",
    id: "MCO",
    code: "492",
  },
  {
    label: "Mongolie",
    id: "MNG",
    code: "496",
  },
  {
    label: "Monténégro",
    id: "MNE",
    code: "499",
  },
  {
    label: "Montserrat",
    id: "MSR",
    code: "500",
  },
  {
    label: "Mozambique",
    id: "MOZ",
    code: "508",
  },
  {
    label: "Myanmar",
    id: "MMR",
    code: "104",
  },
  {
    label: "Namibie",
    id: "NAM",
    code: "516",
  },
  {
    label: "Nauru",
    id: "NRU",
    code: "520",
  },
  {
    label: "Népal",
    id: "NPL",
    code: "524",
  },
  {
    label: "Nicaragua",
    id: "NIC",
    code: "558",
  },
  {
    label: "Niger",
    id: "NER",
    code: "562",
  },
  {
    label: "Nigeria",
    id: "NGA",
    code: "566",
  },
  {
    label: "Niue",
    id: "NIU",
    code: "570",
  },
  {
    label: "Norvège",
    id: "NOR",
    code: "578",
  },
  {
    label: "Nouvelle-Calédonie",
    id: "NCL",
    code: "540",
  },
  {
    label: "Nouvelle-Zélande",
    id: "NZL",
    code: "554",
  },
  {
    label: "Oman",
    id: "OMN",
    code: "512",
  },
  {
    label: "Ouganda",
    id: "UGA",
    code: "800",
  },
  {
    label: "Ouzbékistan",
    id: "UZB",
    code: "860",
  },
  {
    label: "Pakistan",
    id: "PAK",
    code: "586",
  },
  {
    label: "Palau",
    id: "PLW",
    code: "585",
  },
  {
    label: "Palestine",
    id: "PSE",
    code: "275",
  },
  {
    label: "Panama",
    id: "PAN",
    code: "591",
  },
  {
    label: "Papouasie-Nouvelle-Guinée",
    id: "PNG",
    code: "598",
  },
  {
    label: "Paraguay",
    id: "PRY",
    code: "600",
  },
  {
    label: "Pays-Bas",
    id: "NLD",
    code: "528",
  },
  {
    label: "Pérou",
    id: "PER",
    code: "604",
  },
  {
    label: "Philippines",
    id: "PHL",
    code: "608",
  },
  {
    label: "Pitcairn",
    id: "PCN",
    code: "612",
  },
  {
    label: "Pologne",
    id: "POL",
    code: "616",
  },
  {
    label: "Polynésie française",
    id: "PYF",
    code: "258",
  },
  {
    label: "Portugal",
    id: "PRT",
    code: "620",
  },
  {
    label: "Puerto Rico",
    id: "PRI",
    code: "630",
  },
  {
    label: "Qatar",
    id: "QAT",
    code: "634",
  },
  {
    label: "République centrafricaine",
    id: "CAF",
    code: "140",
  },
  {
    label: "République démocratique du Congo",
    id: "COD",
    code: "180",
  },
  {
    label: "République dominicaine",
    id: "DOM",
    code: "214",
  },
  {
    label: "République du Congo",
    id: "COG",
    code: "178",
  },
  {
    label: "République tchèque",
    id: "CZE",
    code: "203",
  },
  {
    label: "Réunion",
    id: "REU",
    code: "638",
  },
  {
    label: "Roumanie",
    id: "ROU",
    code: "642",
  },
  {
    label: "Royaume-Uni",
    id: "GBR",
    code: "826",
  },
  {
    label: "Russie",
    id: "RUS",
    code: "643",
  },
  {
    label: "Rwanda",
    id: "RWA",
    code: "646",
  },
  {
    label: "Sahara occidental",
    id: "ESH",
    code: "732",
  },
  {
    label: "Saint-Barthélemy",
    id: "BLM",
    code: "652",
  },
  {
    label: "Saint-Kitts-et-Nevis",
    id: "KNA",
    code: "659",
  },
  {
    label: "Saint-Marin",
    id: "SMR",
    code: "674",
  },
  {
    label: "Saint-Martin (partie française)",
    id: "MAF",
    code: "663",
  },
  {
    label: "Saint-Martin (partie néerlandaise)",
    id: "SXM",
    code: "534",
  },
  {
    label: "Saint-Pierre-et-Miquelon",
    id: "SPM",
    code: "666",
  },
  {
    label: "Saint-Siège (Vatican)",
    id: "VAT",
    code: "336",
  },
  {
    label: "Saint-Vincent-et-les Grenadines",
    id: "VCT",
    code: "670",
  },
  {
    label: "Sainte-Hélène",
    id: "SHN",
    code: "654",
  },
  {
    label: "Sainte-Lucie",
    id: "LCA",
    code: "662",
  },
  {
    label: "Salvador",
    id: "SLV",
    code: "222",
  },
  {
    label: "Samoa",
    id: "WSM",
    code: "882",
  },
  {
    label: "Samoa américaines",
    id: "ASM",
    code: "016",
  },
  {
    label: "Sao Tomé-et-Principe",
    id: "STP",
    code: "678",
  },
  {
    label: "Sénégal",
    id: "SEN",
    code: "686",
  },
  {
    label: "Serbie",
    id: "SRB",
    code: "688",
  },
  {
    label: "Seychelles",
    id: "SYC",
    code: "690",
  },
  {
    label: "Sierra Leone",
    id: "SLE",
    code: "694",
  },
  {
    label: "Singapour",
    id: "SGP",
    code: "702",
  },
  {
    label: "Slovaquie",
    id: "SVK",
    code: "703",
  },
  {
    label: "Slovénie",
    id: "SVN",
    code: "705",
  },
  {
    label: "Somalie",
    id: "SOM",
    code: "706",
  },
  {
    label: "Soudan",
    id: "SDN",
    code: "736",
  },
  {
    label: "Sri Lanka",
    id: "LKA",
    code: "144",
  },
  {
    label: "Sud-Soudan",
    id: "SSD",
    code: "728",
  },
  {
    label: "Suède",
    id: "SWE",
    code: "752",
  },
  {
    label: "Suisse",
    id: "CHE",
    code: "756",
  },
  {
    label: "Suriname",
    id: "SUR",
    code: "740",
  },
  {
    label: "Svalbard et Jan Mayen",
    id: "SJM",
    code: "744",
  },
  {
    label: "Syrie",
    id: "SYR",
    code: "760",
  },
  {
    label: "Tadjikistan",
    id: "TJK",
    code: "762",
  },
  {
    label: "Taiwan",
    id: "TWN",
    code: "158",
  },
  {
    label: "Tanzanie",
    id: "TZA",
    code: "834",
  },
  {
    label: "Tchad",
    id: "TCD",
    code: "148",
  },
  {
    label: "Terres australes et antarctiques françaises",
    id: "ATF",
    code: "260",
  },
  {
    label: "Territoire britannique de l’Océan Indien",
    id: "IOT",
    code: "086",
  },
  {
    label: "Thaïlande",
    id: "THA",
    code: "764",
  },
  {
    label: "Timor-Leste",
    id: "TLS",
    code: "626",
  },
  {
    label: "Togo",
    id: "TGO",
    code: "768",
  },
  {
    label: "Tokelau",
    id: "TKL",
    code: "772",
  },
  {
    label: "Tonga",
    id: "TON",
    code: "776",
  },
  {
    label: "Trinité-et-Tobago",
    id: "TTO",
    code: "780",
  },
  {
    label: "Tunisie",
    id: "TUN",
    code: "788",
  },
  {
    label: "Turkménistan",
    id: "TKM",
    code: "795",
  },
  {
    label: "Turquie",
    id: "TUR",
    code: "792",
  },
  {
    label: "Tuvalu",
    id: "TUV",
    code: "798",
  },
  {
    label: "Ukraine",
    id: "UKR",
    code: "804",
  },
  {
    label: "Uruguay",
    id: "URY",
    code: "858",
  },
  {
    label: "Vanuatu",
    id: "VUT",
    code: "548",
  },
  {
    label: "Venezuela",
    id: "VEN",
    code: "862",
  },
  {
    label: "Viêt Nam",
    id: "VNM",
    code: "704",
  },
  {
    label: "Wallis-et-Futuna",
    id: "WLF",
    code: "876",
  },
  {
    label: "Yémen",
    id: "YEM",
    code: "887",
  },
  {
    label: "Zambie",
    id: "ZMB",
    code: "894",
  },
  {
    label: "Zimbabwe",
    id: "ZWE",
    code: "716",
  },
];
export default countries;
