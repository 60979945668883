import * as React from "react";
import {TextField, Autocomplete, CircularProgress} from "@mui/material";
import { axiosInstance } from "../../services/Https";

let timeOut = null;

/*
Address object definition (BAN)
{
    "label": "8 Boulevard du Port 80000 Amiens",
    "score": 0.492192009569378,
    "housenumber": "8",
    "id": "80021_6590_00008",
    "name": "8 Boulevard du Port",
    "postcode": "80000",
    "citycode": "80021",
    "x": 648952.58,
    "y": 6977867.14,
    "city": "Amiens",
    "context": "80, Somme, Hauts-de-France",
    "type": "housenumber",
    "importance": 0.67727,
    "street": "Boulevard du Port"
}*/

export default function BanAddressField({updateAddress, ...props}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState("");
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;
    if (timeOut) clearTimeout(timeOut);
    if (inputValue.length < 8) return;

    timeOut = setTimeout(async () => {
      const result = await axiosInstance.get("/address", {
        params: {
          q: inputValue,
        },
      }).catch(e => console.error(e.message || e.code || e));

      if (active) {
        let newOptions = [];
        if (value) {
          updateAddress && updateAddress(value);
          newOptions = [value];
        }
        if (result?.data?.features) {
          newOptions = [
            ...newOptions,
            ...result?.data?.features
              .filter((f) => f.properties.type === "housenumber")
              .map((r) => ({
                label: r.properties.label,
                housenumber: r.properties.housenumber,
                postcode: r.properties.postcode,
                city: r.properties.city,
                street: r.properties.street,
                id: r.properties.id,
              })),
          ];
        }
        setOptions(newOptions);
      }
    }, 700);

    return () => {
      active = false;
    };
  }, [inputValue, value, open, updateAddress]);

  return (
    <Autocomplete
      id="ban-address-field"
      sx={{ width: 300 }}
      filterOptions={(x) => x}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
