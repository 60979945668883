import React, { useState, useRef, useContext } from "react";
import { BasketContext } from "../context";
import CardField from "../components/CardField/CardField";
import { ImageList, Stack } from "@mui/material";
import LongLabel from "../components/LongLabel/LongLabel";
import Text from "./Text";

const Component = ({ registry, events, ...args }) => {
  const { navigationState, basket, product } = useContext(BasketContext);
  const ref = useRef(null);
  const { asset } = args;

  const currentItem = basket.getItem(
    navigationState.asset,
    navigationState.itemId
  );
  const assetDef = product.fleet.find((a) => a.asset.id === asset.id);

  const [error, setError] = useState(!(currentItem && currentItem.type));
  const [label, setLabel] = useState(!!args.label);
  const [forbidden, setForbidden] = useState([]);

  if (!currentItem) return <></>;
  const validates = async () => {
    const state = {
      error: false,
      messages: [],
    };

    if (!currentItem.type) {
      state.error = true;
      state.messages.push(
        "Choisissez une typologie de " +
          assetDef.asset.title.toLowerCase() +
          "."
      );
    }

    setError(state.error ? state.messages.join("\n") : false);

    return state;
  };

  if (args.code) {
    const api = {
      validates: validates,
    };
    Object.defineProperties(api, {
      disabledChoice: {
        get: () => forbidden,
        set: (list) => setForbidden(list),
      },
      error: {
        get: () => error,
      },
      label: {
        get: () => label,
        set: (v) => setLabel(v),
      },
    });
    registry.set(args.code, api);
  }

  console.log("Render : ", error);

  return (
    <Stack direction={"column"} gap={1}>
      {label && <LongLabel label={label} />}
      <Stack direction={"row"}>
        <ImageList
          cols={3}
          rowHeight={176}
          gap={21}
          sx={{
            padding: "21px",
            marginLeft: "-21px",
          }}
        >
          {assetDef.asset.types.map((type, i) => (
            <CardField
              selected={currentItem.type === type.code}
              key={i}
              disabled={forbidden.indexOf(type.code) >= 0}
              onChange={(v) => {
                if (v) {
                  currentItem.type = type.code;
                  setError(false);
                }
              }}
            >
              {type.image && <img src={type.image.url} />}
              {type.title}
            </CardField>
          ))}
        </ImageList>
      </Stack>
      {error && <Text style={"alert"} text={error} key={error} />}
    </Stack>
  );
};

export default Component;
