import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  Typography,
  DialogActions,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SubmitErrorHandler from "../SubmitErrorHandler/SubmitErrorHandler";
import IframeSignDialog from "../IframeSignDialog/IframeSignDialog";
import { useMutation } from "@tanstack/react-query";
import { remoteSigningFn, sendPartsFn } from "../../api";
import subscriptionStepStore from "../../../../../../core/store/subscription-step-store";

const SignPaDialog = (props) => {
  const [quoteId] = subscriptionStepStore((state) => [state.quoteId]);
  const [errorFlag, setErrorFlag] = useState(false);
  const {
    onClose,
    open,
    title,
    code,
    advice,
    isSigningModeDefined,
    remoteSigningOnly,
  } = props;
  const [openIframe, setOpenIframe] = useState(false);

  const sendPartsBeforeSignature = useMutation({
    mutationFn: sendPartsFn,
    onError: (error) => {
      setErrorFlag(true);
    },
  });
  const sendLinkForRemoteSignin = useMutation({
    mutationFn: remoteSigningFn,
    onError: (error) => {
      setErrorFlag(true);
    },
  });
  const handleClose = () => {
    onClose();
  };
  const closeIframeDlg = () => {
    setOpenIframe(false);
  };
  const openIframeSignDlg = () => {
    setErrorFlag(false);
    setOpenIframe(true);

    sendPartsBeforeSignature.mutate({ quoteId, code });
    onClose();
  };
  const sendEsigLink = () => {
    /**
     * Send an email with the show_link
     * Use u-short to provide another URL for the show-link
     */
    setErrorFlag(false);
    sendLinkForRemoteSignin.mutate({ quoteId, code });
    onClose();
  };
  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle align={"center"} sx={{ color: "#024E7D" }}>
          {title || "Signature éléctronique"}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px",
              transition: "border .24s ease-in-out",
            }}
          >
            <Box
              component={"img"}
              alt="sign-document"
              src="/images/image1.png"
              sx={{
                width: "277px",
                height: "182px",
              }}
            ></Box>

            <Typography
              sx={{
                width: "297px",
                fontFamily: "Barlow Condensed",
                fontSize: "22px",
                fontWeight: "500",
                lineHeight: "26px",
                letterSpacing: "0px",
                textAlign: "center",
              }}
            >
              {advice || ""}
            </Typography>
            <SubmitErrorHandler display={errorFlag} />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Button
            disabled={
              isSigningModeDefined !== null &&
              isSigningModeDefined &&
              remoteSigningOnly
            }
            sx={{
              borderRadius: "35px",
              boxSizing: "border-box",
              background: "#024E7D",
              border: "1px solid #024E7D",
            }}
            variant="contained"
            onClick={openIframeSignDlg}
          >
            Démarrer la signature en ligne
          </Button>
          <Typography variant="h6">OU</Typography>
          <Button
            disabled={
              isSigningModeDefined !== null &&
              isSigningModeDefined &&
              !remoteSigningOnly
            }
            sx={{
              borderRadius: "35px",
              boxSizing: "border-box",
              background: "#024E7D",
              border: "1px solid #024E7D",
            }}
            variant="contained"
            onClick={sendEsigLink}
          >
            Envoyer un lien de signature
          </Button>
        </DialogActions>
      </Dialog>
      <IframeSignDialog
        open={openIframe}
        onClose={closeIframeDlg}
        circuit={props.circuit}
      />
    </>
  );
};
export default SignPaDialog;
