const summary = (fields, o) =>
  JSON.parse(JSON.stringify(fields.reduce((prev, current) => {
    prev[current] = o[current] || null;
    return prev;
  }, {})));

export const snapshotFactory = (fields = []) => {

  let _snapshotMap = new Map();

  let clear = () => _snapshotMap.clear();

  let snapshot = (o) => {
    _snapshotMap.set(o._id, summary(fields, o));
  }

  let compare = (o) => {
    const newObj = summary(fields, o);
    const prevObj = _snapshotMap.get(o._id) || {};
    return [fields.reduce((prev, current) => {
      if(JSON.stringify(newObj[current]) !== JSON.stringify(prevObj[current])) {
        prev[current] = newObj[current];
      }
      return prev;
    }, {}), newObj];
  }

  return {
    clear, snapshot, compare
  };
}
