import { Typography } from "@mui/material";
import React from "react";

const SubscriptionsHistory = () => {

  return <>
    <Typography variant="h6" mt={1}>Historique des souscriptions synchronisées dans VEOS</Typography>
  </>
}

export default SubscriptionsHistory;