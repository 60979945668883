import React, { useState, useContext, useEffect } from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import { SubscriptionContext } from "./SubscriptionIndex";
import "./Subscription.scss";
import SubscriptionDuplicates from "./SubscriptionDuplicates";
import SubscriptionFormContract from "./SubscriptionFormContract";
import SubscriptionFormPerson from "./SubscriptionFormPerson";
import {
  clearBatchCache,
  getSubscription,
} from "../../../../core/services/BatchSubscriptionService";

const SubscriptionFill = () => {
  let { batchId, subscriptionId } = useParams();
  const [loaded, setLoaded] = useState(false);
  const { subscription, setSubscription } = useContext(SubscriptionContext);
  const [nextStep, setNextStep] = useState(undefined);

  useEffect(() => {
    if (!subscription) return;
    clearBatchCache(batchId);
    getSubscription(batchId, subscriptionId).then((s) => {
      setSubscription(s);
      setLoaded(true);
      setNextStep(subscription.isDuplicatesSearched ? "person" : "duplicates");
    });
    // }, [location]);
  }, []);

  return (
    <div className="PagePaysage">
      {loaded && (
        <Routes>
          {nextStep && <Route path="/" element={<Navigate to={nextStep} />} />}
          <Route path="duplicates" element={<SubscriptionDuplicates />} />
          <Route path="person" element={<SubscriptionFormPerson />} />
          <Route path="contract" element={<SubscriptionFormContract />} />
        </Routes>
      )}
    </div>
  );
};

export default SubscriptionFill;
