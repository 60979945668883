import React from "react";
import "./CardBlock.scss";
import T from "prop-types";

const CardBlock = React.forwardRef(({ value, children, className, onActiveCard = () => null, disable, i }, CardRef) => {
    return (
      <div
        ref={CardRef}
        onClick={onActiveCard ? () => onActiveCard(i, value) : ""}
        className={`CardBlock ${className} ${disable && 'CardBlock__disabled'}`}
      >
        {children}
      </div>
    );
  }
);
CardBlock.propTypes = {
  value: T.string,
  children: T.array,
  className: T.string,
  onActiveCard: T.func,
  disable: T.bool,
  i: T.oneOfType([T.string, T.number]),
};

export default CardBlock;
