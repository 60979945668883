const dadsStatutProfessionel =  [{
  label: 'Militaire en activité',
  id: 'STATUT_PRO_1',
}, {
  label: 'Civil Défense Sécurité en activité',
  id: 'STATUT_PRO_2',
}, {
  label: 'Autre Profession civile en activité',
  id: 'STATUT_PRO_3'
}/*, {
  label: 'Sans activité professionnelle',
  id: 'STATUT_PRO_4'
}, {label: 'Agent de sécurité, gardien de prison', id: 'STATUT_PRO_5'}
*/]

export default dadsStatutProfessionel