import React from "react";
import "./TagBlock.scss";
import checkIcon from "../../../assets/images/check-icon.svg";
import warningIcon from "../../../assets/images/warning-icon.svg";
import hourglassIcon from "../../../assets/images/hourglass-icon.svg";
import T from "prop-types";

const TagBlock = ({ variant, name }) => {
  function Tag() {
    switch (variant) {
      case "active":
      case "empty":
        return <span className="TagBlock__filled">{name}</span>;
      case "success":
      case "analysed":
        return <span className="TagBlock__success">{name}</span>;
      case "check":
        return (
          <span className="TagBlock__check">
            <img src={checkIcon} alt="icon check" />
            {name}
          </span>
        );
      case "error":
        return <span className="TagBlock__toComplete">{name}</span>;
      case "dark":
        return <span className="TagBlock__dark">{name}</span>;
      case "waiting":
      case "captured":
      case "saved":
        return (
          <span className="TagBlock__downloading">
            <img src={hourglassIcon} alt="icon sablier" />
            {name}
          </span>
        );
      case "warning":
        return (
          <span className="TagBlock__undefined">
            <img src={warningIcon} alt="icon attention" />
            {name}
          </span>
        );
      default:
        return (
          <p>
            Invalid property variant?: "active/empty" | "sucess/analysed" |
            "check" | "error" | "filled" | "waiting/captured/saved" | "undefined"
            undefined "downloading"
          </p>
        );
    }
  }
  return (
    <div className="TagBlock">
      <Tag />
    </div>
  );
};

TagBlock.propTypes = {
  variant: T.string,
  name: T.string,
};
export default TagBlock;
