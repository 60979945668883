import React, { useState, useEffect } from "react";
import {
  useParams,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import SubscriptionCapture from "../Capture/Capture";
import SubscriptionFill from "./Subscription";
import {
  getBatch,
  getSubscription,
} from "../../../../core/services/BatchSubscriptionService";

export const SubscriptionContext = React.createContext({});

const Subscription = () => {
  let { batchId, subscriptionId } = useParams();
  const [subscription, setSubscription] = useState(null);
  const [batch, setBatch] = useState(null);
  const [allDocContent, setAllDocContent] = useState({});
  const [allDocOk, setAllDocOk] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // initialize context subscription object each time url is changing
    getSubscription(batchId, subscriptionId)
      .then((s) => {
        setSubscription(s);
      })
      .catch((e) => {
        console.error(e);
      });

    getBatch(batchId).then((b) => {
      setBatch(b);
    });
  }, [location, batchId, subscriptionId]);

  useEffect(() => {
    // initialize context subscription documents cache object
    setAllDocContent({});
  }, [batchId, subscriptionId]);

  useEffect(() => {
    if (!subscription || !subscription?.supportingDocuments?.length > 0) return;
    setAllDocOk(
      subscription?.supportingDocuments?.every(
        (d) => ["stored", "STORED", "ANALYZED"].indexOf(d.status) >= 0
      )
    );
  }, [subscription, batchId, subscriptionId]);

  return (
    <div>
      <SubscriptionContext.Provider
          value={{ subscription, setSubscription, batch, setBatch, allDocContent, setAllDocContent }}
      >
        {subscription && allDocOk !== null && (
          <Routes>
            <Route
              path="/"
              element={<Navigate to={allDocOk ? "fill" : "capture"} />}
            />
            <Route path="/capture/*" element={<SubscriptionCapture />} />
            <Route path="/fill/*" element={<SubscriptionFill />} />
          </Routes>
        )}
      </SubscriptionContext.Provider>
    </div>
  );
};

export default Subscription;
