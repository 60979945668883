import { useEffect, useRef, useState } from "react";
//import ImageCamCapture from "./ImageCamCapture";
import ImageCropper from "./ImageCropper";
import { ImageCaptureService } from "./ImageCaptureService";
import {
  Button, CircularProgress, Grid, Input,
  List, ListItem, ListItemButton,
  ToggleButton,
  ToggleButtonGroup, Typography
} from "@mui/material";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CheckIcon from '@mui/icons-material/Check';
import Compressor from 'compressorjs';
import Rotator from 'exif-auto-rotate';

import "./index.scss";


const ImageCapture = ({ specification, output, cropper, capturing }) => {
  const [pages, setPages] = useState([]);
  const [variant, setVariant] = useState(0);
  const [index, setIndex] = useState(0);
  const [image, setImage] = useState(null);
  const [selectedButton, setSelectedButton] = useState(0)
  const itemsRef = useRef([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isPdfMakeRunning, setIsPdfMakeRunning] = useState(false);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const handleCompressedUpload = async (e) => {
    let image = e.target.files[0];
    if (!image) return;
    await Rotator.createRotatedImageAsync(image, 'blob')
      .then(res => image = res)
      .catch(e => console.error('Rotator', e));
    new Compressor(image, {
      // maxWidth: 3840, // 4K
      // maxHeight: 3840, // 4K
      // maxWidth: 1920, // FHD
      // maxHeight: 1920, // FHD
      maxWidth: 1280, // HD
      maxHeight: 1280, // HD
      quality: 0.8,
      success: async (res) => {
        done(await toBase64(res), !cropper)
      },
      error: (e) => console.error('Compressor', e)
    });
  };

  const done = async (i, next) => {
    setImage(i);
    if (i && next) {
      let copy = [...pages];
      copy[index] = i;
      setPages(copy);
      // setIndex(index + 1);
      setImage(null);
    }
  };

  useEffect(() => {
    let check = false;
    (function (a) { if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(a.substr(0, 4))) check = true; })(navigator.userAgent || navigator.vendor || window.opera);
    setIsMobile(check);
  }, []);

  useEffect(() => {
    setPages([]);
    setVariant(0);
    setIndex(0);
    setSelectedButton(0);
  }, [specification])

  useEffect(() => {
    if (!specification && variant !== false) return;
    if (pages.filter(i => i).length >= specification.variants[variant].pages.length) {
      setIsPdfMakeRunning(true)
      setTimeout(() => {
        ImageCaptureService.pdfMake(pages).then((blob) => {
          output({ pdf: blob, images: pages })
          setIsPdfMakeRunning(false)
          setIsCapturing(false)
        });
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, variant]);

  useEffect(() => {
    setPages([]);
    setIndex(0);
    setImage(null);
    setVariant(selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    setIsCapturing(!!image || index > 0)
  }, [image, index]);

  useEffect(() => {
    capturing && capturing(isCapturing)
  }, [isCapturing]);

  return (
    <>
      <div className="ImageCapture__container">
        {/*<ImageCamCapture*/}
        {/*  title={variant === false ? '' : specification?.variants[variant]?.pages[index]}*/}
        {/*  output={(i) => done(i, !cropper)}*/}
        {/*/>*/}
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="video-container">
          <List>
            {specification?.variants[variant]?.pages.map((i, k) =>
              <ListItem key={k} secondaryAction={
                <>
                  {pages[k] && <Button edge="end" variant="contained" color='success'
                    onClick={() => {
                      setIndex(k);
                      itemsRef.current[k].click()
                    }}>
                    <CheckIcon />
                  </Button>
                  }
                  {!pages[k] && isMobile &&
                    <Button edge="end" variant="contained" color='primary' sx={{ right: -135 }}
                      onClick={() => {
                        itemsRef.current[k].setAttribute('accept', 'image/*,capture');
                        itemsRef.current[k].setAttribute('capture', 'environment');
                        setIndex(k);
                        itemsRef.current[k].click()
                      }}>
                      <PhotoCameraIcon />
                    </Button>
                  }
                  {!pages[k] &&
                    <Button edge="end" variant="contained" color='primary'
                      onClick={() => {
                        itemsRef.current[k].setAttribute('accept', 'image/*');
                        itemsRef.current[k].removeAttribute('capture');
                        setIndex(k);
                        itemsRef.current[k].click()
                      }}>
                      <FolderOpenIcon />
                    </Button>
                  }
                </>
              }>
                <ListItemButton sx={{ whiteSpace: "nowrap" }} onClick={() => {
                  setIndex(k);
                  itemsRef.current[k].click()
                }}>
                  <Typography variant="h2" mt={0} mb={0} pt={0} pb={0}>{i}</Typography>
                </ListItemButton>
                <Input type="file"
                  inputRef={el => itemsRef.current[k] = el}
                  name={i} title={i} placeholder={i}
                  onChange={handleCompressedUpload}
                  style={{ display: 'none' }} />
              </ListItem>
            )}
          </List>
        </Grid>
        {isPdfMakeRunning && <CircularProgress />}
        <Grid container
          direction="row"
          justifyContent="center"
          className="overlay-toolbar bottom-2nd">
          <ToggleButtonGroup
            value={selectedButton}
            exclusive
            sx={{ gap: "12px" }}
            size="small"
          >
            {specification?.variants?.length > 1 && specification.variants.map((i, k) =>
              <ToggleButton key={k} onClick={() => { setSelectedButton(k); /*setVariant(k);*/ }} value={k}>
                {i.label}
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Grid>
        {image !== null && <div className="ImageCapture__container-overlay">
          <ImageCropper input={image} output={(i) => done(i, true)} />
        </div>}
      </div>
    </>
  );
};

export default ImageCapture;
