import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { patchDocument } from "../../../../core/services";
import { useParams } from "react-router-dom";
import { CaptureContext } from "./Capture";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

const textFieldOptions = {
  variant: "outlined",
  size: "small",
  sx: {
    width: "100%",
    mb: 1.7,
    mt: 0.7,
  },
};

const iconButtonStyle = {
  display: "flex",
  marginLeft: "auto",
};

const buttonStyle = {
  display: "block",
  margin: "auto",
  my: 2
};

const CaptureModal = ({ document, goNext }) => {
  let { subscriptionId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const { refreshDocumentState } = useContext(CaptureContext);

  const handleSubmit = async () => {
    document.status = "MISSING";
    patchDocument(subscriptionId, document._id, { status: "MISSING" })
      .then(() => {
        setOpen(false);
        goNext();
        refreshDocumentState();
      })
      .catch((e) => {
        console.error(e);
      });
  };
  
  return (
    <>
      <Button
        color="secondary"
        sx={buttonStyle}
        variant="contained"
        onClick={() => setOpen(true)}
      >
        Pièce Manquante
      </Button>
      <Modal sx={{ zIndex: 10000 }} open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <IconButton sx={iconButtonStyle} onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h1" mb={1.7}>
            Pièce manquante
          </Typography>
          <Typography variant="p">
            Nom
          </Typography>
          <TextField
            {...textFieldOptions}
            type="text"
            placeholder="Nom"
            required={true}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Typography variant="p">
            Prénom
          </Typography>
          <TextField
            {...textFieldOptions}
            type="text"
            placeholder="Prénom"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <Button
            color="info"
            variant="contained"
            onClick={()=>firstName && lastName && handleSubmit()}
            sx={buttonStyle}
          >
            Valider
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default CaptureModal;
