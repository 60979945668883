import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBasket, getBasketByExternalRef } from "../../service/BasketService";
import { getProductConfig } from "../../service/ProductService";
import { BasketContext } from "../../context";
import Header from "../Header/Header";
import RaterForm from "../RaterForm/RaterForm";
import AdviceCard from "../AdviceCard/AdviceCard";
import Footer from "../Footer/Footer";
import "./Rater.scss";
import { getQuote } from "../../service/QuoteService";

const Rater = () => {
  const { productCode } = useParams();
  const [basket, setBasket] = useState(null);
  const [product, setProduct] = useState(null);
  const [brand, setBrand] = useState(null);
  const [navigationState, setNavigationState] = useState(null);
  const [formState, setFormState] = useState(null);
  const [advices, setAdvices] = useState([]);
  const currentForm = useRef();
  const navigate = useNavigate();

  // Méthode de navigation
  const goTo = function (targetFormId, targetItemId) {
    const formDef = product.forms.find((f) => f.id === targetFormId);
    const assetDef =
      formDef && formDef.asset
        ? product.fleet.find((f) => f.asset.id === formDef.asset.id)?.asset
        : null;

    if (navigationState) {
      const { asset, itemId } = navigationState;

      if (targetItemId === -1 && asset === assetDef?.code) {
        targetItemId = itemId;
      }
    }

    setNavigationState({
      gen: navigationState ? navigationState.gen + 1 : 1,
      formId: targetFormId,
      asset: assetDef ? assetDef.code : null,
      itemId: targetItemId,
    });
  };

  const changeFormState = (newState) => {
    setFormState((prevState) => ({
      ...prevState,
      ...newState,
    }));
    currentForm.current = newState.formId || formState.formId;
  };

  const getCurrentFormId = () => {
    return currentForm.current;
  };

  const setAdviceVisible = (adviceCode, v) => {
    setAdvices((prev) => {
      if (v) prev.add(adviceCode);
      else if (prev.has(adviceCode)) prev.delete(adviceCode);
      return prev;
    });
  };
  const setAdvicesVisible = (advices) => {
    setAdvices(new Set(advices));
  };

  // Contexte pour les enfants
  const context = {
    basket,
    setBasket,
    formState,
    changeFormState,
    setNavigationState,
    product,
    goTo,
    navigationState,
    getCurrentFormId,
    brand,
    setAdvices,
    advices,
  };

  const fetchBasket = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    let basket,
      idDevis = null;

    if (urlParams.has("idDevis")) {
      idDevis = urlParams.get("idDevis");
      try {
        basket = await getBasketByExternalRef(idDevis);
      } catch (e) {
        basket = null;
        if (!urlParams.has("origin")) {
          localStorage.removeItem(`${productCode}_bId`);
        }
      }
    }

    if (!basket) {
      basket = await getBasket(productCode, idDevis || null);
    }
    if (basket.isComplete()) {
      if (basket.quoteId) {
        const quote = await getQuote(basket.quoteId);
        if (basket.id === quote.basket) {
          navigate("/quote/" + quote._id);
          return;
        } else {
          basket.quoteId = null;
          basket.goBegin();
        }
      }
    }
    // TODO : On prend le panier, si fin alors : quoteId.
    // On lit la quote, on s'assure du basketId sur la quote, sinon on retire la quote.
    // On bascule vers la quote, sinon on réinit l'historique.
    setBasket(basket);
    setProduct(await getProductConfig(basket.product));
  };

  // Chargement du panier et de la config produit
  useEffect(() => {
    fetchBasket().catch();
  }, [productCode]);

  // Navigation initiale...
  useEffect(() => {
    if (product) {
      // Recherche theme par...
      // a) nom de domaine
      let _brand = product.brands.find(
        (b) => b.url.indexOf(window.location.hostname) >= 0
      );
      // b) variable environnement
      if (!_brand && process.env.REACT_APP_BRAND) {
        _brand = product.brands.find(
          (b) => b.theme === process.env.REACT_APP_BRAND
        );
      }
      if (!_brand) return;
      setBrand(_brand);
      goTo(-1, -1);
    }
  }, [product]);

  if (!brand) return <></>;

  return (
    <BasketContext.Provider value={context}>
      <div className="Rater_Layout">
        <div className="Rater_Layout__header">
          <Header product={product} brand={brand} />
        </div>
        <div className="Rater_Layout__center">
          <div className="Rater_Layout__content">
            <RaterForm />
          </div>
          <aside
            className="Rater_Layout__sidebar"
            style={{
              backgroundImage: "url('" + product.backgroundImage.url + "')",
            }}
          >
            {advices
              .filter((advice) => {
                return advice.visible;
              })
              .map((advice, i) => (
                <AdviceCard
                  key={i}
                  registry={formState.registry}
                  advice={advice}
                ></AdviceCard>
              ))}
          </aside>
        </div>
        <div className="Rater_Layout__footer">
          <Footer product={product} brand={brand} />
        </div>
      </div>
    </BasketContext.Provider>
  );
};

export default Rater;
