import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const initialState = {
  quote: {},
  steps: [],
  quoteId: "",
  currentStepCode: "",
  processingStepCode: "",
};

const subscriptionStepStore = create()(
  persist(
    (set) => ({
      ...initialState,
      setQuote: (/** @type {any} */ quote) => set({ quote: quote }),
      setSteps: (/** @type {[]} */ steps) => {
        set({ steps: steps });
        const res = steps.find((elt) => elt.status === "todo");
        if (res) {
          set({ currentStepCode: res.code });
        } else {
          set({ currentStepCode: "" });
        }
      },
      setQuoteId: (/** @type {string} */ quoteId) => set({ quoteId: quoteId }),
      setCurrentStepCode: (/** @type {string} */ stepCode) =>
        set({ currentStepCode: stepCode }),
      reset: () => set(initialState),
    }),
    {
      name: "subscription-step",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default subscriptionStepStore;
