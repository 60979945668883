import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import { SubscriptionContext } from "../Subscription/SubscriptionIndex";
import ImageCapture from "../../../ImageCapture";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

import "./CaptureDocument.scss";
import { getToken } from "../../../../core/services/TokenService";
import {
  getDocumentSpecification,
  updateDocument,
} from "../../../../core/services/BatchSubscriptionService";
import { CaptureContext } from "./Capture";
import CaptureModal from "./CaptureModal";
import { Box } from "@mui/system";
import { apiGetDocument } from "../../../../core/services";

const CaptureDocument = () => {
  const { documentType, batchId, subscriptionId } = useParams();
  const { subscription, allDocContent, setAllDocContent } =
    useContext(SubscriptionContext);
  const { refreshDocumentState } = useContext(CaptureContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [documentSpec, setDocumentSpec] = useState(null);
  const [document, setDocument] = useState(null);
  const [result, setResult] = useState(null);
  const [modeCapture, setModeCapture] = useState(true);
  const [isCapturing, setIsCapturing] = useState(false);

  /* Chargement spec document */
  useEffect(() => {
    let specs = getDocumentSpecification().find((d) => d.type === documentType);
    setDocumentSpec({
      ...specs,
      variants:
        (subscription?.isLegionnaire &&
          specs.variants.filter(
            (i) => typeof i.isLegionnaire === "undefined" || i.isLegionnaire
          )) ||
        specs.variants,
    });
  }, [subscription, documentType]);

  /* Chargement objet document */
  useEffect(() => {
    if (!subscription) return;
    setDocument(
      subscription.supportingDocuments?.find(
        (d) => d.documentType === documentType
      )
    );
  }, [subscription, documentType]);

  useEffect(() => {
    // load && cache subscription.document
    if (!allDocContent[documentType] && document && document.status !== "NEW") {
      allDocContent[documentType] = apiGetDocument(
        `${process.env.REACT_APP_API_URL}/subscriptions/${subscriptionId}/documents/${document._id}/download`
      ).then((res) => {
        allDocContent[documentType] = new File([res.data], documentType);
        setAllDocContent({ ...allDocContent });
      });
    }
  }, [document]);

  /* Mode capture */
  useEffect(() => {
    if (!document) return;
    setModeCapture(
      searchParams.get("retake") ||
        document.status === "NEW" ||
        document.status === "MISSING"
    );
  }, [document, searchParams]);

  const goNext = () => {
    // const next = documentType === "id" ? "rib" : 'da';
    const next = documentType === "da" ? "id" : "rib";
    // documentType === "da" ?
    documentType === "rib"
      ? navigate(
          `/batch-subscriptions/${batchId}/subscriptions/${subscriptionId}/capture/summary`
        )
      : navigate(
          `/batch-subscriptions/${batchId}/subscriptions/${subscriptionId}/capture/${next}`
        );
  };

  const goPrevious = () => {
    // const previous = documentType === "da" ? "rib" : 'id';
    const previous = documentType === "rib" ? "id" : "da";
    // documentType === "id" ?
    documentType === "da"
      ? navigate(`/batch-subscriptions/batches/${batchId}`)
      : navigate(
          `/batch-subscriptions/${batchId}/subscriptions/${subscriptionId}/capture/${previous}`
        );
  };

  /* Resultat */
  useEffect(() => {
    if (!result) return;
    document.status = "SAVING";
    document.pdf = result.pdf;
    setResult(null);

    let filename;
    switch (document.documentType) {
      case "id":
        filename = "Piece Identite";
        break;
      case "rib":
        filename = "RIB";
        break;
      case "da":
        filename = "Demande Adhesion";
        break;
      default:
        filename = document.documentType;
        break;
    }

    refreshDocumentState();
    updateDocument(
      subscriptionId,
      document._id,
      document.pdf,
      `${filename}.pdf`
    ).then((res) => {
      refreshDocumentState();
      const findUpdatedDoc = res.supportingDocuments.find(
        (doc) => doc.documentType === document.documentType
      );
      document.status = findUpdatedDoc.status;
    });

    // save && cache subscription.document
    allDocContent[document.documentType] = new File(
      [document.pdf],
      document.documentType
    );
    setAllDocContent({ ...allDocContent });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={"CaptureDocument"}
    >
      <Box>
        {modeCapture && (
          <ImageCapture
            specification={documentSpec}
            output={(i) => setResult(i)}
            cropper={true}
            capturing={setIsCapturing}
          />
        )}
        {!modeCapture && document && (
          <div className={"CaptureDocument__Frame"}>
            {allDocContent[documentType] && (
              <Document
                // file={`${process.env.REACT_APP_API_URL}/subscriptions/${subscriptionId}/documents/${document._id}/download`}
                file={allDocContent[documentType]}
                options={{
                  httpHeaders: { Authorization: `Bearer ${getToken()}` },
                }}
                onLoadSuccess={onDocumentLoadSuccess}
                loading="Chargement du pdf..."
                error="Pas de pdf"
              >
                {numPages &&
                  Array.from({ length: numPages }, (_, i) => i + 1).map((i) => (
                    <Page
                      key={i}
                      pageNumber={i}
                      className="CaptureDocument__pdf-document"
                      height={500}
                    />
                  ))}
              </Document>
            )}
          </div>
        )}
        <Stack spacing={2} my={2} direction={"row"} justifyContent="center">
          <Button
            variant="contained"
            color="info"
            onClick={() => goPrevious()}
            disabled={isCapturing}
          >
            Retour
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={() => goNext()}
            disabled={isCapturing}
          >
            Suivant
          </Button>
        </Stack>
        {documentType !== "da" && (
          <CaptureModal document={document} goNext={goNext} />
        )}
      </Box>
    </Stack>
  );
};

export default CaptureDocument;
