import React, {useRef} from 'react'
import {evalEvent, useTrackableState} from "../service/PageService";
import {Button, Icon, Stack} from "@mui/material";

const Component = ({registry, events, style, icon, ...args}) => {
    const [label, setLabel] = useTrackableState(args.title || '');
    const [visible, setVisible] = useTrackableState(true);

    if(args.code) {

        const api = {};

        Object.defineProperties(api, {
            'label': {
                get: () => label,
                set: (v) => setLabel(v)
            },
            'visible': {
                get: () => visible,
                set: (v) => setVisible(v)
            }
        });

        registry.set(args.code, api);

    }

    const handleClick = () => {
        evalEvent(events, 'click', {}).catch();
    }

    if(!visible) return <></>


    return <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} style={{margin:'10px'}}>
        <Button variant={"contained"}
                color={style||'primary'}
                startIcon={icon ? <Icon sx={{marginTop: '-5px'}}><img src={icon.url}/></Icon>:null}
                onClick={() => handleClick()}>
            {label}
        </Button>
    </Stack>
}

export default Component;
