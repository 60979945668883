import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";
import { Autocomplete, TextField, makeValidate, Switches } from "mui-rff";
import { DatePicker } from "../../../../core/blocks/FormField/DatePicker";
import CardBlockTextIcon from "../../../../core/blocks/CardBlockTextIcon/CardBlockTextIcon";
import { postBatch } from "../../../../core/services";
import protectIcon from "../../../../assets/images/protect-icon.svg";
import "./NewSession.scss";
import { KeyboardArrowLeft } from "@mui/icons-material";
import BanAddressField from "../../../../core/blocks/BanAddressField/BanAddressField";
import countries from "../../../../assets/countries";
import streetTypes from "../../../../assets/streetTypes";
import stringSimilarity from "string-similarity";
import { Form } from "react-final-form";
import * as Yup from "yup";
import moment from "moment";

const textFieldOptions = {
  variant: "outlined",
  size: "small",
};

const LOV = {
  country: countries,
  streetType: streetTypes,
};

const NewSession = () => {
  const navigate = useNavigate();

  const fieldsValidate = makeValidate(
    Yup.object().shape({
      //Nom&Date
      name: Yup.string()
        .min(8, "Doit contenir au minimum 8 caractères")
        .max(50, "Doit contenir au maximum 50 caractères")
        .required("Obligatoire"),
      date: Yup.date()
        .required("Obligatoire")
        .nullable()
        .typeError("Date invalide"),
      //Domicile
      building: Yup.string().notRequired(),
      additional: Yup.string().test("test-additional", (v, o) =>
        !!v || (!!o.parent.street && !!o.parent.streetType)
          ? true
          : o.createError({
              message: "Complément d'adresse ou\nType voie + Voie",
            })
      ),
      // 'street': Yup.string().required('Obligatoire'),
      street: Yup.string().test("test-street", (v, o) =>
        !!v || !!o.parent.additional
          ? true
          : o.createError({ message: "Obligatoire" })
      ),
      streetNumber: Yup.string().notRequired(),
      // 'streetType': Yup.string().required('Obligatoire').nullable(),
      streetType: Yup.string()
        .test("test-streetType", (v, o) =>
          !!v || !!o.parent.additional
            ? true
            : o.createError({ message: "Obligatoire" })
        )
        .nullable(),
      zipCode: Yup.string()
        .matches(/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/, "CP incorrect.")
        .required("Obligatoire"),
      city: Yup.string().required("Obligatoire"),
      country: Yup.string().required("Obligatoire").nullable(),
      isLegionnaire: Yup.boolean(),
      isStudent: Yup.boolean(),
    })
  );

  const validate = async (values) => {
    const errors = await fieldsValidate(values);
    return errors;
  };

  const onSubmit = async (values) => {
    const riskAddress = {
      building: values.building,
      additional: values.additional,
      streetNumber: values.streetNumber,
      streetType: LOV.streetType.find((o) => o.label === values.streetType)?.id,
      street: values.street,
      city: values.city,
      zipCode: values.zipCode,
      country: LOV.country.find((o) => o.label === values.country)?.id,
    };
    const res = await postBatch({
      sessionName: values.name,
      sessionDate: moment(values.date).format("YYYY-MM-DD"),
      isLegionnaire: values.isLegionnaire,
      isStudent: values.isStudent,
      riskAddress,
    });
    navigate(`/batch-subscriptions/batches/${res._id}`);
  };

  const setAddressFields = (args, state, { setIn, changeValue }) => {
    // streetType
    const res = stringSimilarity.findBestMatch(
      args[0].street.split(" ").shift() || "Rue",
      streetTypes.map((elt) => elt.label)
    );
    let streetType = "Rue";
    if (res.bestMatch.rating > 0) {
      streetType = streetTypes[res.bestMatchIndex].label;
    }
    // street
    const index = streetTypes.indexOf(
      streetTypes.find((i) => i.label === streetType)
    );
    let street = args[0].street
      ?.replace(
        new RegExp(streetTypes[index]?.label?.replace(/-+/g, " "), "gi"),
        ""
      )
      ?.replace(
        new RegExp(streetTypes[index]?.label?.replace(/ +/g, "-"), "gi"),
        ""
      )
      ?.trim();
    state.fields["streetNumber"].change(args[0].housenumber);
    state.fields["streetType"].change(streetType);
    state.fields["street"].change(street);
    state.fields["zipCode"].change(args[0].postcode);
    state.fields["city"].change(args[0].city);
    state.fields["country"].change("France");
  };

  return (
    <Form
      mutators={{ setAddressFields }}
      validate={validate}
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <form className="NewSession Page" onSubmit={handleSubmit} noValidate>
          <div className="NewSession__header">
            <Typography variant="h2">Nouvel import</Typography>
            <div className="NewSession__header-btPrécédent">
              <Link to={`/`}>
                <Button
                  variant="outlined"
                  disabled
                  startIcon={<KeyboardArrowLeft />}
                >
                  PRÉCÉDENT
                </Button>
              </Link>
            </div>
          </div>
          <Typography variant="h4">Session de signature</Typography>
          <Typography variant="p">
            Vérifier et compléter les informations du bénéficiaire
          </Typography>
          <div className="NewSession__session">
            <Typography variant="h5">Session</Typography>
            <div className="NewSession__session-card">
              <CardBlockTextIcon
                item={{ text: "Protect", icon: protectIcon }}
              />{" "}
            </div>
          </div>

          <div className="NewSession__name">
            <Typography variant="h5">Nom</Typography>
            <div className="NewSession__name-input">
              <TextField
                {...textFieldOptions}
                name="name"
                type="text"
                required={true}
                placeholder="Le nom de la session / exemple le lieu"
                fullWidth={true}
              />
            </div>
          </div>
          <div className="NewSession__date">
            <Typography variant="h5">Date</Typography>
            <div className="NewSession__date-input">
              <DatePicker
                name="date"
                inputFormat="DD/MM/YYYY"
                placeholder="jj/mm/aaaa"
                required={true}
                textFieldProps={{ ...textFieldOptions }}
              />
            </div>
          </div>
          <Stack direction="row" sx={{ marginTop: "15px" }} spacing={2}>
            <Switches
              name="isLegionnaire"
              data={{ label: "Légionnaire", value: false }}
            />
          </Stack>
          <Stack direction="row" sx={{ marginTop: "15px" }} spacing={2}>
            <Switches
              name="isStudent"
              data={{
                label: "Elève (uniquement élève lycée technique)",
                value: false,
              }}
            />
          </Stack>
          <Typography variant="h5" sx={{ marginTop: "30px" }}>
            Adresse
          </Typography>
          <Stack direction="row" sx={{ marginTop: "15px" }} spacing={2}>
            <BanAddressField
              name="banAddress"
              label={"Recherche adresse risque"}
              {...textFieldOptions}
              fullWidth={true}
              updateAddress={form.mutators.setAddressFields}
              sx={{ width: 500 }}
            />
          </Stack>
          <Stack direction="row" sx={{ marginTop: "15px" }} spacing={2}>
            <TextField
              {...textFieldOptions}
              label="Bâtiment / Chambre"
              name="building"
              required={false}
              placeholder="Bâtiment / Chambre"
              title="Bâtiment / Chambre"
              sx={{ width: 190 }}
            />
            <TextField
              {...textFieldOptions}
              label="Complément d'adresse"
              name="additional"
              placeholder="Complément d'adresse"
              title="Complément d'adresse"
              sx={{ width: 230 }}
            />
          </Stack>
          <Stack direction="row" sx={{ marginTop: "15px" }} spacing={2}>
            <TextField
              {...textFieldOptions}
              label="N°"
              name="streetNumber"
              required={false}
              placeholder="N°"
              title="N°"
              sx={{ width: 150 }}
            />
            <Autocomplete
              size="small"
              disablePortal
              name="streetType"
              required={true}
              label="Type voie"
              options={["", ...LOV.streetType.map((a) => a.label)]}
              sx={{ width: 300 }}
            />
            <TextField
              {...textFieldOptions}
              label="Voie"
              name="street"
              required={true}
              placeholder="Voie"
              title="Voie"
              fullWidth={true}
            />
          </Stack>
          <Stack direction="row" sx={{ marginTop: "15px" }} spacing={2}>
            <TextField
              {...textFieldOptions}
              label="Code Postal"
              name="zipCode"
              required={true}
              placeholder="Code Postal"
              title="Code Postal"
              sx={{ width: 400 }}
            />
            <TextField
              {...textFieldOptions}
              label="Ville"
              required={true}
              placeholder="Ville"
              title="Ville"
              name="city"
              sx={{ width: 500 }}
            />
            <Autocomplete
              size="small"
              name={"country"}
              disablePortal
              label="Pays"
              required={true}
              options={["", ...LOV.country.map((a) => a.label)]}
              fullWidth={true}
            />
          </Stack>
          <div className="NewSession__btDemarrer">
            <Button type="submit" variant="contained" color="info">
              DEMARRER NUMERISATION
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default NewSession;
