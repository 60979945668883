import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useMutation } from "@tanstack/react-query";
import { processStepsFn } from "../../api";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import subscriptionStepStore from "../../../../../../core/store/subscription-step-store";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  height: 148,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const UploadDocumentDialog = (props) => {
  const [quoteId] = subscriptionStepStore((state) => [state.quoteId]);
  const {
    onClose,
    open,
    title,
    submitButtonText,
    advice,
    code,
    type,
    nbPages,
  } = props;
  const [loading, setLoading] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);

  const [imageFiles, setImagesFiles] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);

  const imageFileRegex = RegExp("image/*");
  const pdfFileRegex = RegExp("application/pdf");

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "image/*": [],
        "application/pdf": [],
      },
      onDrop: (acceptedFiles) => {
        if (Array.isArray(acceptedFiles) && acceptedFiles.length === 1) {
          const xor = (a, b) => (a && !b) || (!a && b);

          if (
            xor(
              pdfFileRegex.test(acceptedFiles[0].type) &&
                pdfFiles.length < 1 &&
                imageFiles.length === 0,
              imageFileRegex.test(acceptedFiles[0].type) &&
                imageFiles.length < nbPages &&
                pdfFiles.length === 0
            )
          ) {
            if (pdfFileRegex.test(acceptedFiles[0].type)) {
              // @ts-ignore
              setPdfFiles((arr) => [
                ...arr,
                Object.assign(acceptedFiles[0], {
                  preview: URL.createObjectURL(acceptedFiles[0]),
                }),
              ]);
            }
            if (imageFileRegex.test(acceptedFiles[0].type))
              // @ts-ignore
              setImagesFiles((arr) => [
                ...arr,
                Object.assign(acceptedFiles[0], {
                  preview: URL.createObjectURL(acceptedFiles[0]),
                }),
              ]);
          }
        }
        return imageFiles;
      },
      multiple: false,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const resetFiles = () => {
    // @ts-ignore
    imageFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    imageFiles.splice(0, imageFiles.length);
    // @ts-ignore
    pdfFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    pdfFiles.splice(0, imageFiles.length);
  };

  const handleClose = () => {
    resetFiles();
    onClose();
  };

  const fileList = () => {
    if (imageFiles.length > 0) {
      return imageFiles.map((file, index) => (
        <li
          key={`file-path-${index}`}
        >{`${file.path} - ${file.size} octets`}</li>
      ));
    }
    if (pdfFiles.length > 0) {
      return pdfFiles.map((file, index) => (
        <li
          key={`file-path-${index}`}
        >{`${file.path} - ${file.size} octets`}</li>
      ));
    }
    return [];
  };

  useEffect(() => {
    return () => resetFiles();
  }, []);

  const imageThumbs = imageFiles.map((file, index) => {
    return (
      <div
        // @ts-ignore
        style={thumb}
        // @ts-ignore
        key={`file-${index}`}
      >
        <div style={thumbInner}>
          <img
            // @ts-ignore
            alt={file.name}
            // @ts-ignore
            src={file.preview}
            style={img}
            onLoad={() => {
              // @ts-ignore
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
      </div>
    );
  });

  const pdfThumbs = pdfFiles.map((file, index) => {
    return (
      <div
        // @ts-ignore
        style={thumb}
        // @ts-ignore
        key={`file-${index}`}
      >
        <div style={thumbInner}>
          <Document
            file={file}
            options={options}
            onLoadError={(error) => console.error(error)}
          >
            <Page height={150} pageNumber={1} />
          </Document>
        </div>
      </div>
    );
  });

  // Mutation definition
  const { mutate } = useMutation({
    mutationFn: processStepsFn,
    // @ts-ignore
    onSuccess: () => {
      resetFiles();
      onClose();
      setLoading(false);
      window.location.reload();
    },
    // @ts-ignore
    onError: () => {
      setErrorFlag(true);
      setLoading(false);
    },
  });
  // Upload documents
  const handleUpload = () => {
    setErrorFlag(false);
    setLoading(true);
    if (imageFiles.length) {
      mutate({
        stepCode: code,
        quoteId: quoteId,
        files: imageFiles,
        formFields: {
          code: code,
          type: type,
        },
      });
    } else if (pdfFiles.length) {
      mutate({
        stepCode: code,
        quoteId: quoteId,
        files: pdfFiles,
        formFields: {
          code: code,
          type: type,
        },
      });
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md">
      <DialogTitle align={"center"}>
        {title || "Envoi de pièces manquantes"}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Paper
          elevation={2}
          {...getRootProps({
            // @ts-ignore
            style,
          })}
        >
          <Button
            variant="outlined"
            disabled
            sx={{
              width: "153px",
              height: "153px",
              borderRadius: "35px",
              marginBottom: "48px",
              marginTop: "48px",
            }}
          >
            <AddIcon sx={{ fontSize: 80 }} />
          </Button>
          <input {...getInputProps()} />
          <Typography variant="h6">{advice || ""}</Typography>
          {Array.isArray(fileList()) && fileList().length > 0 && (
            <>
              <Typography>Liste des fichiers</Typography>
              <ul>{fileList()}</ul>
              {imageThumbs && (
                <aside
                  // @ts-ignore
                  style={thumbsContainer}
                >
                  {imageThumbs}
                </aside>
              )}
              {pdfThumbs && (
                <aside
                  // @ts-ignore
                  style={thumbsContainer}
                >
                  {pdfThumbs}
                </aside>
              )}
            </>
          )}
          {errorFlag && (
            <Alert severity="error">
              <AlertTitle>Erreur lors du traitement des données</AlertTitle>
              <Typography sx={{ whiteSpace: "pre" }}>
                {`Si le problème persiste, veuillez-vous rapprocher du support.
L'équipe technique de Carene.`}
              </Typography>
            </Alert>
          )}
        </Paper>
      </DialogContent>
      <DialogActions
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Box style={{ margin: 1, position: "relative" }}>
          <Button
            disabled={
              (Array.isArray(fileList()) && fileList().length === 0) || loading
            }
            sx={{
              borderRadius: "35px",
              boxSizing: "border-box",
              padding: "4px 20px",
              gap: "10px",
              background: "#024E7D",
              border: "1px solid #024E7D",
            }}
            variant="contained"
            size="small"
            onClick={handleUpload}
          >
            {submitButtonText || "Envoyer"}
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default UploadDocumentDialog;
