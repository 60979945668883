import React, {useState, useEffect, useRef} from 'react'
import LongLabel from "../components/LongLabel/LongLabel";
import {Autocomplete, Stack, TextField} from "@mui/material";
import {evalEvent, useTrackableState} from "../service/PageService";

const Component = ({registry, events, ...args}) => {
    const [label, setLabel] = useTrackableState(args.label || '');
    const [required, setRequired] = useTrackableState(args.required || false);
    const [error, setError] = useTrackableState(false);
    const [value, setValue] = useTrackableState(null);
    const [firstTime, setFirstTime] = useTrackableState(true);
    const [placeholder, setPlaceHolder] = useTrackableState(args.placeholder || '');
    const [options, setOptions] = useTrackableState((args.options||[]).map(o => ({id: o.code, label:o.label})));
    const [visible, setVisible] = useTrackableState(true);
    const ref = useRef();


    const validates = async () => {

        const state = {
            error: false,
            messages: []
        }

        if(firstTime) {
            setFirstTime(false);
            return state.error;
        }
        if(required && !value) {
            state.error = true;
            state.messages.push("Champ '"+label+"' obligatoire.");
        }

        await evalEvent(events, 'validate', {
            error:(msg) => {
                state.error = true;
                state.messages.push(msg);
            }
        });

        setError(state.error ? state.messages.join("\n"):false);

        return state;

    };

    useEffect(  () => {
        validates().then(async () => evalEvent(events,'change'))
    }, [value, required]);

    if(args.code) {
        const api = {
            'focus': () => {ref.current.focus()},
            'validates': validates,
        };
        Object.defineProperties(api, {
            'label': {
                get: () => label,
                set: (v) => setLabel(v)
            },
            'visible': {
                get: () => visible,
                set: (v) => setVisible(v)
            },
            'placeholder': {
                get: () => placeholder,
                set: (v) => setPlaceHolder(v)
            },
            'value': {
                get: () => {
                    return typeof value === 'string' || value === null ? value : value.id;
                },
                set: (v) => {
                    const opts = options;
                    let opt = opts.find(o => typeof o === 'string' ? o === v : o.id === v);
                    if(!opt) {
                        opt = v;
                        if(v) {
                            if(opts.length && typeof opts[0] === 'object' && typeof opt === 'string') {
                                opt = {
                                    id: v,
                                    label: v
                                };
                            }
                            opts.unshift(opt);
                        }
                        setOptions(opts);
                    }
                    setValue(opt);
                }
            },
            'options': {
                get: () => options,
                set: (v) => setOptions(v)
            },
            'required': {
                get: () => required,
                set: (v) => setRequired(v)
            },
            'error': {
                get: () => error,
                set: (v) => setError(v)
            }
        });

        registry.set(args.code, api);

    }


    if(!visible) return <></>

    return <Stack direction={"column"} gap={1} style={{flex:1}}>
        {label && <LongLabel label={label} required={required}/>}
        <Autocomplete
            disablePortal
            value={value}
            options={options}
            onChange={(e, newValue) => setValue(newValue)}
            renderInput={(params) => <TextField {...params}
                                                inputRef={ref}
                                                error={!!error}
                                                helperText={error}
                                                fullWidth={true}
                                                placeholder={placeholder}/>}
        />
    </Stack>
}

export default Component;
