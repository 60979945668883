import React, {useState, useRef, useEffect} from 'react'
import {evalEvent, useTrackableState} from "../service/PageService";
import {Button, CircularProgress, Stack, TextField, Box} from "@mui/material";
import LongLabel from "../components/LongLabel/LongLabel";

import "./Numberplate.scss";
import {apiPublicPost} from "../../../core/services";


const Component = ({registry, events,...args}) => {
    const [label, setLabel] = useTrackableState(args.label || '');
    const [required, setRequired] = useTrackableState(args.required || false);
    const [error, setError] = useTrackableState(false);
    const [inputValue, setInputValue] = useTrackableState('');
    const [value, setValue] = useTrackableState({immatSiv:''});
    const [firstTime, setFirstTime] = useTrackableState(true);
    const [visible, setVisible] = useTrackableState(true);
    const [loading, setLoading] = useState(false);
    const ref = useRef();

    const validates = async () => {

        const state = {
            error: false,
            messages: []
        }

        if(firstTime) {
            setFirstTime(false);
            return state.error;
        }

        if(required && !inputValue) {
            state.error = true;
            state.messages.push("Champ '"+label+"' obligatoire.");
        }

        await evalEvent(events, 'validate', {
            error:(msg) => {
                state.error = true;
                state.messages.push(msg);
            }
        });

        setError(state.error ? state.messages.join("\n"):false);

        return state;
    };

    useEffect(  () => {
        validates().catch()
    }, [value, required]);

    useEffect(() => {
        evalEvent(events, 'change').catch();
    }, [value]);

    if(args.code) {

        const api = {
            'focus': () => {ref.current.focus()},
            'validates': validates,
        };
        Object.defineProperties(api, {
            'label': {
                get: () => label,
                set: (v) => setLabel(v)
            },
            'visible': {
                get: () => visible,
                set: (v) => setVisible(v)
            },
            'value': {
                get: () => value,
                set: (v) => {
                    setInputValue(v?.immatSiv || "")
                    setValue(v)
                }
            },
            'inputValue': {
                get:() => inputValue
            },
            'required': {
                get: () => required,
                set: (v) => setRequired(v)
            },
            'error': {
                get: () => error,
                set: (v) => setError(v)
            }
        });

        registry.set(args.code, api);

    }

    const handleSearch = async () => {
        setLoading(true);
        const immat = inputValue.toUpperCase().replaceAll(/[^0-9A-Z]/gi,'');
        const {vehicule} = await apiPublicPost("/scripts/3ADATA/execute", {
            vehicule: null,
            immat: immat
        });
        if(!vehicule) {
            setError(true);
            setValue({
                immatSiv: immat
            });
        } else {
            setValue(vehicule);
            setInputValue(vehicule.immatSiv);
        }
        setLoading(false);
    }


    if(!visible) return <></>

    return <Stack direction={"column"} gap={1} style={{flex:1}}>
        {label && <LongLabel label={label} required={required}/>}
        <div className={"Numberplate"}>
            <span className={"Numberplate__Logo"}>
                <svg width="15" height="33" viewBox="0 0 15 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_21_1892)">
                <path d="M7.243 14.154C10.6909 14.154 13.486 11.3589 13.486 7.91097C13.486 4.46306 10.6909 1.66797 7.243 1.66797C3.79509 1.66797 1 4.46306 1 7.91097C1 11.3589 3.79509 14.154 7.243 14.154Z" stroke="white" strokeWidth="1.086" strokeLinecap="round" strokeDasharray="0.54 2.71"/>
                </g>
                <path d="M10.192 22.3288C10.192 22.4354 10.1387 22.4888 10.032 22.4888H6.352C6.30933 22.4888 6.288 22.5101 6.288 22.5528V26.5208C6.288 26.5634 6.30933 26.5848 6.352 26.5848H8.672C8.77867 26.5848 8.832 26.6381 8.832 26.7448V27.4328C8.832 27.5394 8.77867 27.5928 8.672 27.5928H6.352C6.30933 27.5928 6.288 27.6141 6.288 27.6568V32.5368C6.288 32.6434 6.23467 32.6968 6.128 32.6968H5.312C5.20533 32.6968 5.152 32.6434 5.152 32.5368V21.6568C5.152 21.5501 5.20533 21.4968 5.312 21.4968H10.032C10.1387 21.4968 10.192 21.5501 10.192 21.6568V22.3288Z" fill="white"/>
                <defs>
                <clipPath id="clip0_21_1892">
                <rect width="15" height="14" fill="white" transform="translate(0 0.696777)"/>
                </clipPath>
                </defs>
                </svg>
            </span>
            <TextField value={inputValue}
                       error={!!error}
                       inputRef={ref}
                       onFocus={() => evalEvent(events, 'focus')}
                       onClick={() => evalEvent(events, 'click')}
                       onChange={(e) => setInputValue(e.target.value)}>
            </TextField>
            <Box style={{ m: 1, position: 'relative' }}>
                <Button variant={"contained"} color={"primary"} onClick={handleSearch}>
                    {loading ? "":"Rechercher"}
                </Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: 'white',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
        </div>
    </Stack>
}

export default Component;
