import React from "react";

export const LoadingContext = React.createContext({
  startLoading: () => {},
  endLoading: () => {},
});
/*
const loadingMap = new Map();
let loadingKey = 1;
let loadingStack = 0;

export const useLoading = () => {
  const [curLoad, setCurLoading] = useState(true);
  return [curLoad, setCurLoading];
}*/
/*
export const useLoading = () => {
  const [curLoad, setCurLoading] = useState(true);
  const {loading, setLoading} = useGlobalLoading();
  const uniqKey = loadingKey++;


  const start = function () {
    if(!loadingMap.get(uniqKey)) {
      loadingMap.set(uniqKey, true);
      loadingStack++;
      setCurLoading(true);
      setLoading && setLoading(true);
    }
  }
  const stop = function () {
    if(loadingMap.get(uniqKey)) {
      setCurLoading(false);
      loadingMap.set(uniqKey, false);
      loadingStack--;
      setLoading && setLoading(loadingStack > 0);
    }
  }
  start();
  return [curLoad, (b) => b?start():stop()];
};*/