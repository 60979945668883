import { Alert, AlertTitle, Typography } from "@mui/material";
import React from "react";
const SubmitErrorHandler = ({ display }) => (<>
    {display && (<Alert severity="error">
        <AlertTitle>Erreur lors du traitement des données</AlertTitle>
        <Typography sx={{ whiteSpace: "pre" }}>
          {`Si le problème persiste, veuillez-vous rapprocher du support.
L'équipe technique de Carene.`}
        </Typography>
      </Alert>)}
  </>);
export default SubmitErrorHandler;
