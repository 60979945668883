import React, {useContext, useEffect, useState} from "react"
import "./StepNavBar.scss"
import {BasketContext} from "../../context";

const StepNavBar = props => {
  const {product, navigationState} = useContext(BasketContext);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
      const formIdBySteps = product.steps.map(step => step.forms.map(f => f.id));
      setStepIndex(
          formIdBySteps.findIndex( formList => formList.indexOf(navigationState.formId) >= 0)
      );
  }, [navigationState, product]);


  return (
    <div className="StepNavBar">
        <ul className="StepNavBar__bar">
            {product.steps.map( (step,i) =>  <li key={i} className={i<=stepIndex?"StepNavBar__active":""}>{step.title}</li>)}
        </ul>
    </div>
  )
}
export default StepNavBar
