import { createTheme } from "@mui/material";
import { frFR } from '@mui/material/locale';

let theme = createTheme({
    palette: {
        primary: { main: "#024E7D", contrastText: "#ffffff" },
        secondary: { main: "#8e7e7a", contrastText: "#ffffff" },
        error: { main: "#ff8283", contrastText: "#ffffff" },
        warning: { main: "#F3B516", contrastText: "#ffffff" },
        info: { main: "#0ddfb5", contrastText: "#ffffff" },
        success: { main: "#15c15d", contrastText: "#ffffff" },
    },
}, frFR)

theme = createTheme(theme, {
    typography: {
        h1: {
            fontSize: "40px",
            fontWeight: 600,
        },
        h2: {
            fontSize: "32px",
            fontWeight: 600,
        },
        h3: {
            fontSize: "25px",
            fontWeight: 600,
        },
        h4: {
            fontSize: "22px",
            fontWeight: 600,
        },
        h5: {
            fontSize: "18px",
            fontWeight: 600,
        },
        h6: {
            fontSize: "16px",
            fontWeight: 600,
        },
        p: {
            fontWeight: 400,
            fontSize: "16px",
        },
        fontFamily: [
            "Barlow Condensed",
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontFamily: "Barlow Condensed",
                }
            } 
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: theme.palette.primary.main
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Barlow Condensed",
                    fontSize: 17,
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    border: 0,
                    fontSize: 14,
                    color: theme.palette.primary.main,
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    color: theme.palette.primary.contrastText,
                    outline: "1px solid",
                    "&.Mui-selected,  &.Mui-selected:hover": {
                        backgroundColor: theme.palette.success.main,
                        color: theme.palette.primary.contrastText,
                    },
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    color: theme.palette.primary.main,
                    border: "none",
                    ".MuiDataGrid-columnHeaders": {
                        backgroundColor: "#F3F5F7",
                    },
                    ".MuiDataGrid-columnHeaders svg": {
                        display: "none"
                    },
                    ".MuiDataGrid-footerContainer": {
                        justifyContent: "center",
                        background: "#FAFAFA"
                    },
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                }
            }
        }
    }
});

export default theme