export const statuses = {
  DRAFT: { tag: "error", label: "À compléter", editable: true },
  PMQ: { tag: "warning", label: "Pièces manquantes", editable: true },
  SYNC: { tag: "error", label: "Synchro..." },
  SUCCESS: { tag: "info", label: "Complété" },
  SEF: { tag: "secondary", label: "Sans effet" },
  ERROR: { tag: "error", label: "Erreur" },
  ARCHIVED: { tag: "success", label: "Archivé" },
  PENDING: { tag: "error", label: "En attente" },
  NEW: { tag: "empty", label: "À scanner" },
  SAVING: { tag: "waiting", label: "Sauvegarde..." },
  STORED: { tag: "captured", label: "Sauvegardé" },
  ANALYZED: { tag: "captured", label: "Analysé" },
  IN_STORAGE_ERROR: { tag: "success", label: "Erreur" },
  IN_ANALYZE_ERROR: { tag: "success", label: "Erreur" },
  IN_ERROR: { tag: "success", label: "Erreur" },
  DELETED: { tag: "success", label: "Supprimé" },
  MISSING: { tag: "warning", label: "Pièce manquante", editable: true },
};
