import {
  apiDelete,
  apiGetMany,
  apiGetOne,
  apiPost,
  apiUrl,
  apiPatch,
} from "./ApiService";

export const ROWS_PER_PAGE = 25;

export const getBatches = async (_params = {}) => {
  const { page, filter } = _params;
  let params = [];
  if (page) params.push(`page=${page}&limit=${ROWS_PER_PAGE}`);
  if (filter) params.push(filter);
  params.push(`sort=sessionDate`);
  return apiGetMany(`${apiUrl}/batch-subscriptions?${params.join("&")}`);
};

export const getSessions = async (archived) => {
  return await apiGetMany(`${apiUrl}/sessions?archived=${archived}`);
};

export const getBatch = (id) => {
  return apiGetOne(`${apiUrl}/batch-subscriptions/${id}`);
};

export const postBatch = async (data) =>
  apiPost(`${apiUrl}/batch-subscriptions`, data);

export const patchBatch = async (id, data) =>
  apiPatch(`${apiUrl}/batch-subscriptions/${id}`, data);

export const deleteBatch = async (id) =>
  apiDelete(`${apiUrl}/batch-subscriptions/${id}`);
