import IBAN from "iban";

export const checkRib = (bqe, gui, cpt, clef) => {
    let _cpt = cpt.toUpperCase();
    const tab = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const tab1 = "123456789123456789234567890123456789".split("");
    while (_cpt.match(/\D/) != null)
        _cpt = _cpt.replace(/\D/, tab1[tab.indexOf(_cpt.match(/\D/))]);
    const cp = parseInt(_cpt, 10);

    let a = bqe % 97;
    a = a * 100000 + parseInt(gui, 10);
    a = a % 97;
    a = a * Math.pow(10, 11) + cp;
    a = a % 97
    a = a * 100;
    a = a % 97
    a = 97 - a;
    const isValid = parseInt(clef) === a;
    const iban = isValid ? IBAN.fromBBAN('FR', bqe + gui + cpt + clef) : '';

    return { isValid, iban };
}