import React, { useContext } from "react";
import { Button, IconButton, Modal, Typography, Stack } from "@mui/material";
import { Form } from "react-final-form";
import { Autocomplete, TextField, makeValidate, Switches } from "mui-rff";
import BanAddressField from "../../../../core/blocks/BanAddressField/BanAddressField";
import countries from "../../../../assets/countries";
import streetTypes from "../../../../assets/streetTypes";
import { DatePicker } from "../../../../core/blocks/FormField/DatePicker";
import stringSimilarity from "string-similarity";
import { Box } from "@mui/system";
import editIcon from "../../../../assets/images/edit-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { patchBatch } from "../../../../core/services";
import * as Yup from "yup";
import { BatchSubscriptionContext } from "../..";

const textFieldOptions = {
  variant: "outlined",
  size: "small",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

const iconButtonStyle = {
  display: "flex",
  marginLeft: "auto",
};

const buttonStyle = {
  display: "block",
  margin: "auto",
  mb: 1,
  mt: 4,
};

const LOV = {
  country: countries,
  streetType: streetTypes,
};

const EditBatchModal = ({
  sessionId,
  sessionName,
  sessionDate,
  setSessionName,
  setSessionDate,
  address,
  setAddress,
  isLegionnaire,
  setIsLegionnaire,
  isStudent,
  setIsStudent,
}) => {
  const [open, setOpen] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({});
  const { forceBatchTabsReload, setForceBatchTabsReload } = useContext(
    BatchSubscriptionContext
  );

  const fieldsValidate = makeValidate(
    Yup.object().shape({
      //Nom&Date
      name: Yup.string()
        .min(8, "Doit contenir au minimum 8 caractères")
        .max(50, "Doit contenir au maximum 50 caractères")
        .required("Obligatoire"),
      date: Yup.date()
        .required("Obligatoire")
        .nullable()
        .typeError("Date invalide"),
      //Domicile
      building: Yup.string().notRequired(),
      additional: Yup.string().test("test-additional", (v, o) =>
        !!v || (!!o.parent.street && !!o.parent.streetType)
          ? true
          : o.createError({
              message: "Complément d'adresse ou\nType voie + Voie",
            })
      ),
      // 'street': Yup.string().required('Obligatoire'),
      street: Yup.string().test("test-street", (v, o) =>
        !!v || !!o.parent.additional
          ? true
          : o.createError({ message: "Obligatoire" })
      ),
      streetNumber: Yup.string().notRequired(),
      // 'streetType': Yup.string().required('Obligatoire').nullable(),
      streetType: Yup.string()
        .test("test-streetType", (v, o) =>
          !!v || !!o.parent.additional
            ? true
            : o.createError({ message: "Obligatoire" })
        )
        .nullable(),
      zipCode: Yup.string()
        .matches(/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/, "CP incorrect.")
        .required("Obligatoire"),
      city: Yup.string().required("Obligatoire"),
      country: Yup.string().required("Obligatoire").nullable(),
      isLegionnaire: Yup.boolean(),
      isStudent: Yup.boolean(),
    })
  );

  const validate = async (values) => {
    const errors = await fieldsValidate(values);
    return errors;
  };

  React.useEffect(() => {
    const init = {
      name: sessionName,
      date: moment(sessionDate),
      isLegionnaire: isLegionnaire,
      isStudent: isStudent,
      city: address.city,
      building: address.building,
      additional: address.additional,
      streetNumber: address.streetNumber,
      street: address.street,
      country:
        LOV.country.find((elt) => elt.id === address.country)?.label || "",
      streetType:
        LOV.streetType.find((elt) => elt.id === address.streetType)?.label ||
        "",
      zipCode: address.zipCode,
    };
    setInitialValues(init);
  }, [sessionId, address, sessionDate, sessionName, isLegionnaire, isStudent]);

  const setAddressFields = (args, state, { setIn, changeValue }) => {
    // streetType
    const res = stringSimilarity.findBestMatch(
      args[0].street.split(" ").shift() || "Rue",
      streetTypes.map((elt) => elt.label)
    );
    let streetType = "Rue";
    if (res.bestMatch.rating > 0) {
      streetType = streetTypes[res.bestMatchIndex].label;
    }
    // street
    const index = streetTypes.indexOf(
      streetTypes.find((i) => i.label === streetType)
    );
    let street = args[0].street
      ?.replace(
        new RegExp(streetTypes[index]?.label?.replace(/-+/g, " "), "gi"),
        ""
      )
      ?.replace(
        new RegExp(streetTypes[index]?.label?.replace(/ +/g, "-"), "gi"),
        ""
      )
      ?.trim();
    state.fields["streetNumber"].change(args[0].housenumber);
    state.fields["streetType"].change(streetType);
    state.fields["street"].change(street);
    state.fields["zipCode"].change(args[0].postcode);
    state.fields["city"].change(args[0].city);
    state.fields["country"].change("France");
  };

  const onSubmit = async (values) => {
    try {
      const riskAddress = {
        building: values.building,
        additional: values.additional,
        streetNumber: values.streetNumber,
        streetType: LOV.streetType.find((o) => o.label === values.streetType)
          ?.id,
        street: values.street,
        city: values.city,
        zipCode: values.zipCode,
        country: LOV.country.find((o) => o.label === values.country)?.id,
      };
      await patchBatch(sessionId, {
        sessionName: values.name,
        sessionDate: values.date,
        isLegionnaire: values.isStudent,
        isStudent: values.isLegionnaire,
        riskAddress: riskAddress,
      });
      setSessionName(values.name);
      setSessionDate(values.date);
      setAddress(riskAddress);
      setIsLegionnaire(values.isLegionnaire);
      setIsStudent(values.isStudent);
      setInitialValues({
        name: values.name,
        date: moment(values.date),
        isLegionnaire: values.isLegionnaire,
        isStudent: values.isStudent,
        city: values.city,
        building: values.building,
        additional: values.additional,
        streetNumber: values.streetNumber,
        streetType: values.streetType,
        street: values.street,
        country: values.country,
        zipCode: values.zipCode,
      });
      setForceBatchTabsReload(forceBatchTabsReload + 1);
      setOpen(false);
    } catch (e) {
      console.error("error", e);
    }
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <img alt="" src={editIcon} />
      </IconButton>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <Form
            mutators={{ setAddressFields }}
            validate={validate}
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Box sx={style}>
                  <IconButton
                    sx={iconButtonStyle}
                    onClick={() => setOpen(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h3" mb={2}>
                    Modifier la session
                  </Typography>
                  <Typography variant="h5" mb={1}>
                    Nom
                  </Typography>
                  <TextField
                    {...textFieldOptions}
                    name="name"
                    type="text"
                    placeholder="Le nouveau nom de la session"
                    fullWidth={true}
                  />
                  <Typography variant="h5" mb={1} mt={2}>
                    Date
                  </Typography>
                  <DatePicker
                    name="date"
                    inputFormat="DD/MM/YYYY"
                    placeholder="jj/mm/aaaa"
                    textFieldProps={{ ...textFieldOptions }}
                  />
                  <Stack direction="row" sx={{ marginTop: "15px" }} spacing={2}>
                    <Switches
                      name="isLegionnaire"
                      data={{ label: "Légionnaire", value: false }}
                    />
                  </Stack>
                  <Stack direction="row" sx={{ marginTop: "15px" }} spacing={2}>
                    <Switches
                      name="isStudent"
                      data={{
                        label: "Elève (uniquement élève lycée technique)",
                        value: false,
                      }}
                    />
                  </Stack>
                  <Typography variant="h5" sx={{ marginTop: "30px" }}>
                    Adresse
                  </Typography>
                  <Stack direction="row" sx={{ marginTop: "15px" }} spacing={2}>
                    <BanAddressField
                      name="banAddress"
                      label="Recherche adresse domicile"
                      {...textFieldOptions}
                      fullWidth
                      updateAddress={form.mutators.setAddressFields}
                    />
                  </Stack>
                  <Stack direction="row" sx={{ marginTop: "15px" }} spacing={2}>
                    <TextField
                      {...textFieldOptions}
                      label="Bâtiment / Chambre"
                      name="building"
                      required={false}
                      placeholder="Bâtiment / Chambre"
                      title="Bâtiment / Chambre"
                      sx={{ width: 190 }}
                    />
                    <TextField
                      {...textFieldOptions}
                      label="Complément d'adresse"
                      name="additional"
                      placeholder="Complément d'adresse"
                      title="Complément d'adresse"
                      sx={{ width: 230 }}
                    />
                  </Stack>
                  <Stack direction="row" sx={{ marginTop: "15px" }} spacing={2}>
                    <TextField
                      {...textFieldOptions}
                      label="N°"
                      name="streetNumber"
                      required={false}
                      placeholder="N°"
                      title="N°"
                      sx={{ width: 150 }}
                    />
                    <Autocomplete
                      size="small"
                      disablePortal
                      name="streetType"
                      required={true}
                      label="Type voie"
                      options={["", ...LOV.streetType.map((a) => a.label)]}
                      sx={{ width: 300 }}
                    />
                    <TextField
                      {...textFieldOptions}
                      label="Voie"
                      name="street"
                      required={true}
                      placeholder="Voie"
                      title="Voie"
                      fullWidth={true}
                    />
                  </Stack>
                  <Stack direction="row" sx={{ marginTop: "15px" }} spacing={2}>
                    <TextField
                      {...textFieldOptions}
                      label="Code Postal"
                      name="zipCode"
                      required={true}
                      placeholder="Code Postal"
                      title="Code Postal"
                      sx={{ width: 400 }}
                    />
                    <TextField
                      {...textFieldOptions}
                      label="Ville"
                      required={true}
                      placeholder="Ville"
                      title="Ville"
                      name="city"
                      sx={{ width: 500 }}
                    />
                    <Autocomplete
                      size="small"
                      name="country"
                      disablePortal
                      label="Pays"
                      required={true}
                      options={["", ...LOV.country.map((a) => a.label)]}
                      fullWidth={true}
                    />
                  </Stack>
                  <Button
                    type="submit"
                    color="info"
                    variant="contained"
                    sx={buttonStyle}
                  >
                    Valider
                  </Button>
                </Box>
              </form>
            )}
          />
        </div>
      </Modal>
    </>
  );
};

export default EditBatchModal;
