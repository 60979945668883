import { useCallback, useRef } from 'react'
import { Button, Grid } from '@mui/material';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import Cropper from './components/cropper'
import T from 'prop-types'

import './index.scss'

const ImageCropper = ({ input, output }) => {
    const cropperRef = useRef();

    const onDragStop = useCallback(s => s, [])
    const onChange = useCallback(s => s, [])

    const retake = () => output(null)
    const capture = useCallback(
        async () => cropperRef.current && output(await cropperRef.current.done({ preview: true, filterCvParams: { grayScale: false, th: false } }).then((blob => {
            return new Promise((resolve, _) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(blob);
            });
        })).catch(_ => null)),
        [cropperRef, output]
    )
    const rotate = async () => cropperRef.current && await cropperRef.current.rotate()

    return (<>
        <div className="camera-component">
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="photo-container">
                <Cropper ref={cropperRef}
                    openCvPath="/opencv/opencv.js"
                    image={input}
                    onChange={onChange}
                    onDragStop={onDragStop}
                    maxWidth={(window.innerHeight - 310) / 1.34}
                    maxHeight={window.innerHeight - 394}
                    className="photo-container" />
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                className="camera-toolbar top">
                <Button onClick={retake} variant='contained' color='secondary'>REPRENDRE</Button>
                <Button onClick={rotate} variant='contained' color='primary'><CropRotateIcon/></Button>
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                className="camera-toolbar">
                <Button onClick={capture} variant='contained' color='primary'>SUIVANT</Button>
            </Grid>
        </div>
    </>);
}

ImageCropper.propTypes = {
    input: T.string,
    output: T.func,
};
export default ImageCropper;
