import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  Fragment
} from 'react'
import T from 'prop-types'

import { useOpenCv } from '../../opencv/useOpenCv'

import CropPoints from './CropPoints'
import { applyFilter, rotate, transform } from './imageTransform'
import { calcDims, readFile, isCrossOriginURL } from './utils'
import CropPointsDelimiters from './CropPointsDelimiters'

const buildImgContainerStyle = (previewDims) => ({
  width: previewDims.width,
  height: previewDims.height
})

const imageDimensions = { width: 0, height: 0 }
let imageResizeRatio

const Canvas = ({
  image,
  onDragStop,
  onChange,
  cropperRef,
  pointSize = 30,
  lineWidth,
  pointBgColor,
  pointBorder,
  lineColor,
  maxWidth,
  maxHeight
}) => {
  // eslint-disable-next-line
  let { loaded: cvLoaded, cv } = useOpenCv()
  const canvasRef = useRef()
  const previewCanvasRef = useRef()
  const magnifierCanvasRef = useRef()
  const [previewDims, setPreviewDims] = useState()
  const [cropPoints, setCropPoints] = useState()
  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState('crop')

  useImperativeHandle(cropperRef, () => ({
    backToCrop: () => {
      setMode('crop')
    },
    done: async (opts = {}) => {
      if (!cropPoints) return Promise.reject('cropPoints null');
      return new Promise((resolve) => {
        setLoading(true)
        transform(
          cv,
          canvasRef.current,
          cropPoints,
          imageResizeRatio,
          setPreviewPaneDimensions
        )
        applyFilter(cv, canvasRef.current, opts.filterCvParams)
        if (opts.preview) {
          setMode('preview')
        }
        canvasRef.current.toBlob((blob) => {
          blob.name = image.name
          resolve(blob)
          setLoading(false)
        }, image.type)
      })
    },
    rotate: async () => {
      rotate(
        cv,
        canvasRef.current,
        setPreviewPaneDimensions,
        showPreview,
        detectContours,
      );
    }
  }))

  useEffect(() => {
    if (mode === 'preview') {
      showPreview()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  const setPreviewPaneDimensions = () => {
    // set preview pane dimensions
    const newPreviewDims = calcDims(
      canvasRef.current.width,
      canvasRef.current.height,
      maxWidth,
      maxHeight
    )
    setPreviewDims(newPreviewDims)

    previewCanvasRef.current.width = newPreviewDims.width
    previewCanvasRef.current.height = newPreviewDims.height

    imageResizeRatio = newPreviewDims.width / canvasRef.current.width
  }

  const createCanvas = (src) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img')
      img.onload = async () => {
        // set edited image canvas and dimensions
        canvasRef.current = document.createElement('canvas')
        canvasRef.current.width = img.width
        canvasRef.current.height = img.height
        const ctx = canvasRef.current.getContext('2d')
        ctx.imageSmoothingEnabled = false
        ctx.drawImage(img, 0, 0)
        imageDimensions.width = canvasRef.current.width
        imageDimensions.height = canvasRef.current.height
        setPreviewPaneDimensions()
        resolve()
      }
      if (isCrossOriginURL(src)) img.crossOrigin = "anonymous"
      img.src = src
    })
  }

  const showPreview = (image) => {
    const src = image || cv.imread(canvasRef.current)
    const dst = new cv.Mat()
    const dsize = new cv.Size(0, 0)
    cv.resize(
      src,
      dst,
      dsize,
      imageResizeRatio,
      imageResizeRatio,
      cv.INTER_AREA
    )
    cv.imshow(previewCanvasRef.current, dst)
    src.delete()
    dst.delete()
  }

  const detectContours = () => {
    // load the image and compute the ratio of the old height to the new height, clone it, and resize it
    const dst = cv.imread(canvasRef.current)
    // const ksize = new cv.Size(5, 5)
    const dsize = new cv.Size(0, 0);
    const w = 500;
    const ratio = Math.min(w / dst.matSize[0], w / dst.matSize[1]);
    let M;

    // convert the image to grayscale, blur it, and find edges in the image
    cv.resize(dst, dst, dsize, ratio, ratio, cv.INTER_AREA);

    // 1) Denoise
    cv.medianBlur(dst, dst, 3);

    // 2) Threshold
    cv.cvtColor(dst, dst, cv.COLOR_BGR2GRAY, 0);
    M = cv.Mat.ones(5, 5, cv.CV_8U);
    cv.erode(dst, dst, M);
    cv.dilate(dst, dst, M);

    cv.threshold(dst, dst, 0, 255, cv.THRESH_BINARY + cv.THRESH_OTSU);

    // Remove letters...
    M = cv.Mat.ones(3, 3, cv.CV_8U)
    cv.morphologyEx(dst, dst, cv.MORPH_CLOSE, M);

    // 3) Canny
    cv.Canny(dst, dst, 75, 200)

    // 4) Hough lines
    let lines = new cv.Mat();
    cv.HoughLines(dst, lines, 1, Math.PI / 60, 45, 0, 0, 0, Math.PI);

    const intersections = [];
    for (let i = 0; i < lines.rows; i++) {
      for (let j = i + 1; j < lines.rows; j++) {
        const rho1 = lines.data32F[i * 2];
        const theta1 = lines.data32F[i * 2 + 1];
        const rho2 = lines.data32F[j * 2];
        const theta2 = lines.data32F[j * 2 + 1];
        const m1 = -(Math.cos(theta1) / Math.sin(theta1))
        const m2 = -(Math.cos(theta2) / Math.sin(theta2))
        const angle = Math.abs(Math.atan(Math.abs(m2 - m1) / (1 + m2 * m1))) * (180 / Math.PI);
        if (angle && angle > 80 && angle < 100) {
          const A = [[Math.cos(theta1), Math.sin(theta1)],
          [Math.cos(theta2), Math.sin(theta2)]]
          const b = [rho1, rho2];
          const y0 = (b[1] - (A[1][0] * b[0]) / A[0][0]) / ((-A[1][0] * A[0][1]) / A[0][0] + A[1][1]);
          const x0 = (b[0] - y0 * A[0][1]) / A[0][0];

          if (x0 > 0 && x0 < dst.matSize[1] && y0 > 0 && y0 < dst.matSize[0]) {
            intersections.push([x0, y0]);
          }
        }
      }
    }

    let corners;

    if (intersections.length >= 4) {
      // Use KMEANS to find corners.

      const samples = new cv.Mat(intersections.length, 2, cv.CV_32F);
      for(let i=0; i < intersections.length; i++) {
        samples.floatPtr(i)[0] = intersections[i][0];
        samples.floatPtr(i)[1] = intersections[i][1];
      }

      const labels = new cv.Mat();
      const centers = new cv.Mat();
      const criteria = new cv.TermCriteria(cv.TermCriteria_EPS + cv.TermCriteria_MAX_ITER, 100, 0.1);
      cv.kmeans(samples, 4, labels, criteria, 100, cv.KMEANS_RANDOM_CENTERS, centers);
      corners = [[centers.floatPtr(0)[0],centers.floatPtr(0)[1]],
                       [centers.floatPtr(1)[0],centers.floatPtr(1)[1]],
                       [centers.floatPtr(2)[0],centers.floatPtr(2)[1]],
                       [centers.floatPtr(3)[0],centers.floatPtr(3)[1]]];

      corners.sort((a, b) => {
        return b[1] - a[1];
      });

      if(corners[0][0] > corners[1][0]) {
        let s = corners[0][0];
        corners[0][0] = corners[1][0];
        corners[1][0] = s;
      }
      if(corners[2][0] > corners[3][0]) {
        let s = corners[2][0];
        corners[2][0] = corners[3][0];
        corners[3][0] = s;
      }
      // console.log(corners);

      const L = Math.sqrt(Math.pow(corners[3][0] - corners[0][0], 2) + Math.pow(corners[3][1] - corners[0][1], 2));
      const H = Math.sqrt(Math.pow(corners[2][0] - corners[1][0], 2) + Math.pow(corners[2][1] - corners[1][1], 2));
      // console.log("L ("+L+") x H ("+H+")");

      if (L < 150 || H < 150) {
        // console.log("Too Small ! We abort corner detection.");
        corners = null;
      }
    }

    // draw lines
    /*for (let i = 0; i < lines.rows; ++i) {
      let rho = lines.data32F[i * 2];
      let theta = lines.data32F[i * 2 + 1];
      let a = Math.cos(theta);
      let b = Math.sin(theta);
      let x0 = a * rho;
      let y0 = b * rho;
      let startPoint = {x: x0 - 1000 * b, y: y0 + 1000 * a};
      let endPoint = {x: x0 + 1000 * b, y: y0 - 1000 * a};
      cv.line(dst, startPoint, endPoint, [255, 0, 0, 255]);
    }
    if (corners) corners.forEach(c => {
      cv.rectangle(dst, {x: c[0]-2, y: c[1]-2},{x: c[0]+2, y: c[1]+2}, [255, 0, 0, 255], 2, cv.LINE_8, 0)
    })
    cv.imshow(previewCanvasRef.current, dst);*/

    // disable border recognition
    corners = null;

    // if the detected polygon is smaller than 200px (width or height) we can guess that the document is not well identified
    if (!corners || !corners.reduce((prev, c) => {
        // console.log(c[0], c[1], dst.matSize[0], dst.matSize[1]);
        return prev && c[0] > 0 && c[0] < dst.matSize[0] && c[1] > 0 && c[1] < dst.matSize[1]
      }, true)) {
      corners = [[10, dst.matSize[0] - 10], [dst.matSize[1] - 10, dst.matSize[0] - 10], [10, 10], [dst.matSize[1] - 10, 10]];
    }

    if (corners) corners.forEach(c => c.forEach((_, i) => c[i] = c[i] / ratio * imageResizeRatio));

    // console.log("corners : ", corners);
    const contourCoordinates = {
      'left-top': {
        x: corners[2][0],
        y: corners[2][1]
      },
      'right-top': {
        x: corners[3][0],
        y: corners[3][1]
      },
      'right-bottom': {
        x: corners[1][0],
        y: corners[1][1]
      },
      'left-bottom': {
        x: corners[0][0],
        y: corners[0][1]
      }
    }

    setCropPoints(contourCoordinates)
  }

  const clearMagnifier = () => {
    const magnCtx = magnifierCanvasRef.current.getContext('2d')
    magnCtx.clearRect(
      0,
      0,
      magnifierCanvasRef.current.width,
      magnifierCanvasRef.current.height
    )
  }

  useEffect(() => {
    if (onChange) {
      onChange({ ...cropPoints, loading })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropPoints, loading])

  useEffect(() => {
    const bootstrap = async () => {
      try {
        const src = await readFile(image)
        await createCanvas(src)
        showPreview()
        detectContours()
        setLoading(false)
      } catch (e) {
        console.error(e);
      }
    }

    if (image && previewCanvasRef.current && cv && mode === 'crop') {
      bootstrap()
    } else {
      setLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, previewCanvasRef.current, cv, mode])

  const onDrag = useCallback((position, area) => {
    const { x, y } = position

    const magnCtx = magnifierCanvasRef.current.getContext('2d')
    clearMagnifier()

    // to the point size
    magnCtx.drawImage(
      previewCanvasRef.current,
      x - (pointSize - 10),
      y - (pointSize - 10),
      pointSize + 5,
      pointSize + 5,
      x + 10,
      y - 90,
      pointSize + 20,
      pointSize + 20
    )

    setCropPoints((cPs) => ({ ...cPs, [area]: { x, y } }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onStop = useCallback((position, area, cropPoints) => {
    const { x, y } = position
    clearMagnifier()
    setCropPoints((cPs) => ({ ...cPs, [area]: { x, y } }))
    if (onDragStop) {
      onDragStop({ ...cropPoints, [area]: { x, y } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        ...(previewDims && buildImgContainerStyle(previewDims))
      }}
    >
      {previewDims && mode === 'crop' && cropPoints && (
        <Fragment>
          <CropPoints
            pointSize={pointSize}
            pointBgColor={pointBgColor}
            pointBorder={pointBorder}
            cropPoints={cropPoints}
            previewDims={previewDims}
            onDrag={onDrag}
            onStop={onStop}
            bounds={{
              left: previewCanvasRef?.current?.offsetLeft - pointSize / 2,
              top: previewCanvasRef?.current?.offsetTop - pointSize / 2,
              right:
                previewCanvasRef?.current?.offsetLeft -
                pointSize / 2 +
                previewCanvasRef?.current?.offsetWidth,
              bottom:
                previewCanvasRef?.current?.offsetTop -
                pointSize / 2 +
                previewCanvasRef?.current?.offsetHeight
            }}
          />
          <CropPointsDelimiters
            previewDims={previewDims}
            cropPoints={cropPoints}
            lineWidth={lineWidth}
            lineColor={lineColor}
            pointSize={pointSize}
          />
          <canvas
            style={{
              position: 'absolute',
              zIndex: 5,
              pointerEvents: 'none'
            }}
            width={previewDims.width}
            height={previewDims.height}
            ref={magnifierCanvasRef}
          />
        </Fragment>
      )}

      <canvas
        style={{ zIndex: 5, pointerEvents: 'none' }}
        ref={previewCanvasRef}
      />
    </div>
  )
}

export default Canvas

Canvas.propTypes = {
  // image: T.object.isRequired,
  image: T.string.isRequired,
  onDragStop: T.func,
  onChange: T.func,
  cropperRef: T.shape({
    current: T.shape({
      done: T.func.isRequired,
      backToCrop: T.func.isRequired
    })
  }),
  pointSize: T.number,
  lineWidth: T.number,
  pointBgColor: T.string,
  pointBorder: T.string,
  lineColor: T.string
}
