import React, { useContext, useEffect, useState } from "react";
import "./SummaryBlock.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SubscriptionContext } from "../Subscription/SubscriptionIndex";
import { CaptureContext } from "./Capture";
import {
  createSubscription,
  getDocumentSpecification,
} from "../../../../core/services/BatchSubscriptionService";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { statuses } from "../../../../core/utils/statuses";
import { KeyboardArrowLeft } from "@mui/icons-material";

const SummaryBlock = ({ documentTypes }) => {
  let { batchId, subscriptionId } = useParams();
  const { subscription, setSubscription } = useContext(SubscriptionContext);
  const [loading, setLoading] = useState(false);
  const { forceRefresh } = useContext(CaptureContext);
  const [documents, setDocuments] = useState([]);
  const [allDocOk, setAllDocOk] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!subscription || !subscription.supportingDocuments) {
      setDocuments([]);
      return;
    }
    const specifications = getDocumentSpecification();
    setDocuments(
      subscription.supportingDocuments.map((doc) => ({
        ...doc,
        specification: specifications.find((s) => s.type === doc.documentType),
      }))
    );
  }, [forceRefresh, subscription]);

  useEffect(() => {
    let id = setInterval(() => {
      setAllDocOk(
        subscription.supportingDocuments.every(
          (d) => ["stored", "STORED", "ANALYZED"].indexOf(d.status) >= 0
        )
      );
    }, 500);
    return () => clearInterval(id);
  }, [subscription, forceRefresh]);

  const handleRetake = (doc) => {
    navigate(
      `/batch-subscriptions/${batchId}/subscriptions/${subscriptionId}/capture/${doc.documentType}?retake=1`
    );
  };
  const goFill = async () => {
    try {
      // Update the local context of subscription before getting to the sub.
      await navigate(
        `/batch-subscriptions/${batchId}/subscriptions/${subscriptionId}/fill`
      );
    } catch (e) {
      console.error(e);
    }
  };
  const goNextSubscriber = () => {
    setLoading(true);
    createSubscription(batchId).then((sub) => {
      setLoading(false);
      setSubscription(sub);
      setTimeout(
        () =>
          navigate(
            `/batch-subscriptions/${batchId}/subscriptions/${sub._id}/capture`
          ),
        100
      );
    });
  };

  return (
    <div className="SummaryBlock__container">
      {loading && (
        <div className="progress-bar">
          <div className="progress-bar-value" />
        </div>
      )}
      {documents.map((doc, i) => {
        return (
          <div key={doc._id} className="SummaryBlock">
            <div className="SummaryBlock__docType">
              {doc.specification?.label}
            </div>
            <div>{statuses[documentTypes[i].status]?.label}</div>
            <div className="SummaryBlock__docPic">
              {/* <DocumentViewer doc={doc} /> */}

              {(documentTypes[i].status === "NEW" ||
                documentTypes[i].status === "ERROR") && (
                <Typography mt={0.6} textAlign={"left"} variant="body2">
                  Erreur de transfert, veuillez recommencer
                </Typography>
              )}
              {documentTypes[i].status === "SAVING" && (
                <>
                  <LinearProgress sx={{ width: "86%" }} />
                  <Typography mt={0.6} textAlign={"left"} variant="body2">
                    Document en cours d'envoi au serveur
                  </Typography>
                </>
              )}
              {(documentTypes[i].status === "STORED" ||
                documentTypes[i].status === "ANALYZED") && (
                <LinearProgress
                  variant="determinate"
                  value={100}
                  sx={{ width: "86%" }}
                />
              )}
            </div>
            <div className="SummaryBlock__button">
              {documentTypes[i].status !== "SAVING" && (
                <Button onClick={() => handleRetake(doc)} variant="outlined">
                  RECOMMENCER
                </Button>
              )}
            </div>
          </div>
        );
      })}
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        mt={2}
      >
        <Link to={`/batch-subscriptions/batches/${batchId}`}>
          <Button variant="outlined" disabled startIcon={<KeyboardArrowLeft />}>
            PRÉCÉDENT
          </Button>
        </Link>
        <Button
          onClick={() => goNextSubscriber()}
          color="info"
          variant="contained"
        >
          NOUVEAU SOUSCRIPTEUR
        </Button>
        <Button
          onClick={() => goFill()}
          disabled={!allDocOk}
          color="secondary"
          variant="contained"
        >
          PASSER À LA SAISIE
        </Button>
      </Grid>
    </div>
  );
};

export default SummaryBlock;
