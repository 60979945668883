const dadsCorps =  [
  {
    "id": "CORPS_AFF_01",
    "label": "Armée de Terre",
    "parent": "STATUT_PRO_1"
  },
  {
    "id": "CORPS_AFF_02",
    "label": "Armée de l'Air",
    "parent": "STATUT_PRO_1"
  },
  {
    "id": "CORPS_AFF_03",
    "label": "Marine Nationale",
    "parent": "STATUT_PRO_1"
  },
  {
    "id": "CORPS_AFF_04",
    "label": "Gendarmerie",
    "parent": "STATUT_PRO_1"
  },
  {
    "id": "CORPS_AFF_05",
    "label": "Service de Santé",
    "parent": "STATUT_PRO_1"
  },
  {
    "id": "CORPS_AFF_06",
    "label": "Armement",
    "parent": "STATUT_PRO_1"
  },
  {
    "id": "CORPS_AFF_07",
    "label": "Essence des Armées",
    "parent": "STATUT_PRO_1"
  },
  {
    "id": "CORPS_AFF_08",
    "label": "Contrôle",
    "parent": "STATUT_PRO_1"
  },
  {
    "id": "CORPS_AFF_09",
    "label": "Armée étrangère",
    "parent": "STATUT_PRO_1"
  },
  {
    "id": "CORPS_AFF_10",
    "label": "Civil Défense Sécurité",
    "parent": "STATUT_PRO_2"
  },
  {
    "id": "CORPS_AFF_11",
    "label": "Police Nationale",
    "parent": "STATUT_PRO_2"
  },
  {
    "id": "CORPS_AFF_12",
    "label": "Pompier professionnel",
    "parent": "STATUT_PRO_2"
  },
  {
    "id": "CORPS_AFF_13",
    "label": "Police municpale",
    "parent": "STATUT_PRO_2"
  },
  {
    "id": "CORPS_AFF_14",
    "label": "Entreprise défense-sécurité",
    "parent": "STATUT_PRO_3"
  },
  {
    "id": "CORPS_AFF_15",
    "label": "Autre profession",
    "parent": "STATUT_PRO_3"
  },
  {
    "id": "CORPS_AFF_16",
    "label": "Retraité militaire"
  },
  {
    "id": "CORPS_AFF_17",
    "label": "Retraité Civil défense-sécurité"
  },
  {
    "id": "CORPS_AFF_18",
    "label": "Retraité autre"
  },
  {
    "id": "CORPS_AFF_19",
    "label": "Conjoint ou enfant d'adhérent"
  },
  {
    "id": "CORPS_AFF_20",
    "label": "Elève lycée militaire"
  },
  {
    "id": "CORPS_AFF_21",
    "label": "Autre"
  },
  {
    "id": "CORPS_AFF_22",
    "label": "Douanier"
  },
  {
    "id": "CORPS_AFF_23",
    "label": "Reconversion"
  },
  {
    "id": "CORPS_AFF_24",
    "label": "Enseignant"
  },
  {
    "id": "CORPS_AFF_25",
    "label": "Autres Assimilés"
  }
]

export default dadsCorps