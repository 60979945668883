import React, {useContext} from 'react'
import { useTrackableState} from "../service/PageService";
import ReactMarkdown from "react-markdown";
import {Stack} from "@mui/material";
import {BasketContext} from "../context";

import "./BasketSummary.scss";
import {periodicityLabel} from "../model/Basket";

const Component = ({registry, events, ...args}) => {
    //const [label, setLabel] = useTrackableState(args.title || '');
    const {navigationState, basket, product} = useContext(BasketContext);
    const [visible, setVisible] = useTrackableState(true);
    const [description, setDescription] = useTrackableState(args.description || '');
    const [title, setTitle] = useTrackableState(args.title || '');
    const [subtitle, setSubtitle] =useTrackableState(args.subtitle || '');


    if(args.code) {

        const api = {};

        Object.defineProperties(api, {
            'visible': {
                get: () => visible,
                set: (v) => setVisible(v)
            },
            'title': {
                get: () => title,
                set: (v) => setTitle(v)
            },
            'subtitle': {
                get: () => subtitle,
                set: (v) => setSubtitle(v)
            },
            'description': {
                get: () => description,
                set: (v) => setDescription(v)
            }
        });

        registry.set(args.code, api);

    }

    /*const handleClick = () => {
        evalEvent(events, 'click', {}).catch();
    }*/

    if(!visible) return <></>

    let per = periodicityLabel[basket.periodicity];
    let ttc = basket.periodicPremiumDiscounted;
    let ttcInt = Math.floor(ttc);
    let ttcCents = (Math.floor((ttc - ttcInt)*100)+"").padStart(2,"0");
    let ttcA = basket.premiumDiscounted;


    return <Stack direction={"column"} className={"BasketSummary"}>
        <h1>{title}
        <p>{subtitle}</p>
        </h1>
        <Stack direction={"row"} gap={2} alignItems={"center"} alignContent={"center"}>
            <div className={"BasketSummary__card"}>
                <img className={"BasketSummary__picto"} src={product.picto.url}/>
                <div className={"BasketSummary__title"}>{product.title}</div>
                <div className={"BasketSummary__price"}>
                    <div className={"firstRow"}>
                        <span className={"euro"}>{ttcInt}</span>
                        <div className={"euroDetail"}>
                    <span className={"centimes"}>
                        ,{ttcCents}€
                        <span className={"ttc"}>ttc</span>
                    </span>
                            <span className={"period"}>/{per}</span>
                        </div>
                    </div>
                    <span className={"subprice"}>ou {ttcA.toFixed(2)} € TTC /an</span>
                </div>
            </div>
            <div className={"BasketSummary__description"}>
                <ReactMarkdown>
                    {description}
                </ReactMarkdown>
            </div>
        </Stack>
    </Stack>
}

export default Component;
