import axios from "axios";
import * as rax from "retry-axios";

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

export const publicAxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  withCredentials: true,
});

publicAxiosInstance.defaults.headers.common["Content-Type"] =
  "application/json";
const tokenAccess = localStorage.getItem("token");
if (tokenAccess) {
  publicAxiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${tokenAccess}`;
}

/**
 * Intercept response
 */
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Clear previous token
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

/**
 * Intercept request
 * At this level, we can add the token for example if it exists on the storage
 */
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error("Request error : ", error);
    return Promise.reject(error);
  }
);

/**
 * Config retry-axios
 */
[axiosInstance, publicAxiosInstance].forEach(
  (inst) =>
    (inst.defaults.raxConfig = {
      // Retry 3 times on requests that return a response (500, etc) before giving up.  Defaults to 3.
      retry: 3,

      // Retry 4 times on errors that don't return a response (ENOTFOUND, ETIMEDOUT, etc).
      noResponseRetries: 4,

      // Milliseconds to delay at first.  Defaults to 100. Only considered when backoffType is 'static'
      retryDelay: 300,

      // HTTP methods to automatically retry.  Defaults to:
      // ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT']
      httpMethodsToRetry: ["GET", "HEAD", "OPTIONS", "DELETE", "PUT"],

      // The response status codes to retry.  Supports a double
      // array with a list of ranges.  Defaults to:
      // [[100, 199], [429, 429], [500, 599]]
      statusCodesToRetry: [
        [100, 199],
        [429, 429],
        [500, 599],
      ],

      // If you are using a non static instance of Axios you need
      // to pass that instance here (const ax = axios.create())
      instance: axiosInstance,

      // You can set the backoff type.
      // options are 'exponential' (default), 'static' or 'linear'
      backoffType: "exponential",

      // You can detect when a retry is happening, and figure out how many
      // retry attempts have been made
      onRetryAttempt: (err) => {
        const cfg = rax.getConfig(err);
        console.log(`Retry attempt #${cfg.currentRetryAttempt}`);
      },

      // Override the decision making process on if you should retry
      shouldRetry: (err) => {
        const cfg = rax.getConfig(err);
        if (cfg.currentRetryAttempt >= cfg.retry) return false; // ensure max retries is always respected

        // Always retry this status text, regardless of code or request type
        if (err.code.includes("ERR_NETWORK")) return true;

        // Handle the request based on your other config options, e.g. `statusCodesToRetry`
        return rax.shouldRetryRequest(err);
      },
    })
);
