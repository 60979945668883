import React, { useState, useEffect, useRef } from "react";
import LongLabel from "../components/LongLabel/LongLabel";
import { Stack, TextField } from "@mui/material";
import { evalEvent, useTrackableState } from "../service/PageService";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment/moment";

const Component = ({ registry, events, ...args }) => {
  const [label, setLabel] = useTrackableState(args.label || "");
  const [required, setRequired] = useTrackableState(args.required || false);
  const [error, setError] = useTrackableState(false);
  const [dateValue, setDateValue] = useTrackableState(null);
  const [placeholder, setPlaceHolder] = useTrackableState(
    args.placeholder || ""
  );
  const [visible, setVisible] = useTrackableState(true);
  const ref = useRef();

  const validates = async () => {
    const state = {
      error: false,
      messages: [],
    };

    if (required && !dateValue) {
      state.error = true;
      state.messages.push("Champ '" + label + "' obligatoire.");
    }

    await evalEvent(events, "validate", {
      error: (msg) => {
        state.error = true;
        state.messages.push(msg);
      },
    });

    setError(state.error ? state.messages.join("\n") : false);

    return state;
  };

  useEffect(() => {
    evalEvent(events, "change").catch();
  }, [dateValue]);
  /*
    useEffect(  () => {
        validates().then(async () => evalEvent(events,'change'))
    }, [value, required, pattern]);*/

  if (args.code) {
    const api = {
      focus: () => {
        ref.current.focus();
      },
      validates: validates,
    };
    Object.defineProperties(api, {
      label: {
        get: () => label,
        set: (v) => setLabel(v),
      },
      value: {
        get: () => (dateValue ? dateValue.format("YYYY-MM-DD") : ""),
        set: (v) => {
          if (v) {
            setDateValue(moment(v));
          }
        },
      },
      dateValue: {
        get: () => dateValue,
        set: (v) => {
          setDateValue(v);
        },
      },
      placeholder: {
        get: () => placeholder,
        set: (v) => setPlaceHolder(v),
      },
      required: {
        get: () => required,
        set: (v) => setRequired(v),
      },
      error: {
        get: () => error,
        set: (v) => setError(v),
      },
      visible: {
        get: () => visible,
        set: (v) => setVisible(v),
      },
    });
    registry.set(args.code, api);
  }

  if (!visible) return <></>;

  return (
    <Stack direction={"column"} gap={1} style={{ flex: 1 }}>
      {label && <LongLabel label={label} required={required} />}
      <DatePicker
        openTo="year"
        onChange={(e) => setDateValue(e)}
        onClick={() => evalEvent(events, "click")}
        views={["year", "month", "day"]}
        value={dateValue}
        renderInput={(inputProps) => (
          <TextField
            fullWidth={true}
            inputRef={ref}
            helperText={error}
            required={required}
            placeholder={placeholder || "jj/mm/aaaa"}
            {...inputProps}
            error={!!error}
          />
        )}
      />
    </Stack>
  );
};

export default Component;

/*
        <TextField value={value}
                   placeholder={placeholder}
                   error={error}
                   onFocus={() => evalEvent(events, 'focus')}
                   onClick={() => evalEvent(events, 'click')}
                   onChange={(e) => setValue(e.target.value)}>
        </TextField>

 */
