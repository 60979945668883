import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

export const ImageCaptureService = {

  pdfMake: async (images) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    let docDef = {
      compress: true,
      pageSize: "A4",
      pageOrientation: "portrait",
      pageMargins: [15, 15, 15, 15],
      pageBreakBefore: function (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) {
        return currentNode.pageBreak;
      },
      content: images.map((i, index) => {
        return {
          image: i,
          alignment: "center",
          fit: [560, 810],
          margin: [0, index === 0 ? 0 : 15, 0, 0],
          // pageBreak: index > 0
        };
      }),
    };

    return await new Promise((resolve, reject) => {
      try {
        pdfMake.createPdf(docDef).getBlob((res) => {
          resolve(res);
        });
      } catch (e) {
        reject(e);
      }
    }).then((data) => {
      return data;
    });
  },
};
