import React, {useEffect, useState} from 'react'
import './AssetValueVield.scss'
import {Stack} from "@mui/material";
import {evalEvent, useTrackableState} from "../service/PageService";




const AssetValueCard = ({title, desc, selected, onSelect, value, onChangeValue}) => {

    const [_value, _setValue] = useState(value || '');

    const handleSelect = () => {
        if(!selected) onSelect();
    }

    return <div className={"AssetValueCard "+(selected?"":"disabled")} onClick={handleSelect}>
        <Stack direction={"column"} alignItems={"center"} gap={1}>
            <div className={"AssetValueCard__Title"}>{title}</div>
            <div className={"AssetValueCard__Subtitle"}>{desc}</div>
            <input
                className={"AssetValueCard__Input"}
                placeholder={"Montant en euros"}
                onChange={(e) => {
                    _setValue(e.target.value);
                    onChangeValue(e.target.value);
                }}
                value={_value}
                onFocus={handleSelect}
                inputMode={'numeric'}
                pattern={'[0-9]*'}
            />
        </Stack>
    </div>
}

const Component = ({registry, events,...args}) => {
    const [visible, setVisible] = useTrackableState(true);
    const [gen, setGen] = useTrackableState(0);
    const [error, setError] = useTrackableState(0);
    const [value, setValue] = useTrackableState(args.options.reduce((v, o)=>{
        v[o.code] = 0;
        return v;
    },{}));
    const [selected, setSelected] = useTrackableState(args.options.length > 0 ? args.options[0].code:'');

    useEffect(() => {
        if(value && selected && value[selected] === 0) {
            setValue(args.options.reduce((v, o)=>{
                v[o.code] = 0;
                return v;
            },{}));
            setGen(gen+1);
        }
    }, [selected])

    const validates = async () => {

        const state = {
            error: false,
            messages: []
        }

        if(!(selected && parseInt(value[selected]) > 0)) {
            state.error = true;
            state.messages.push("La valeur saisie doit être supérieure à 0.");
        }


        setError(state.error ? state.messages.join("\n"):false);

        return state;
    };

    if(args.code) {
        const api = {
            'validates': validates,
        };
        Object.defineProperties(api, {
            'visible': {
                get: () => visible,
                set: (v) => setVisible(v)
            },
            'value': {
                get: () => ({
                    selected: selected,
                    values: value
                }),
                set: (v) => {
                    setSelected(v.selected || selected)
                    setValue(args.options.reduce((nv, o) => {
                            nv[o.code] = v.values ? v.values[o.code] || 0: 0;
                            return nv;
                        }, {}));
                    setGen(gen+1);
                }
            },
            'error': {
                get: () => error,
                set: (v) => setError(v)
            }
        });
        registry.set(args.code, api);
    }

    if(args.options.length === 0) {
        return <></>
    }

    return <Stack direction={"row"} flex={1} justifyContent={"center"} className={"AssetValueStack"}>
        {args.options.map((option,i) => <AssetValueCard
            key={gen+'-'+i}
            title={option.title}
            desc={option.description}
            onChangeValue={(value) => setValue((prev) => {
                let valDict = {}; valDict[option.code] = value;
                return {
                ...prev,
                ...valDict,
                }
            })}
            value={value[option.code]||undefined}
            onSelect={() => setSelected(option.code)}
            selected={selected===option.code}
        />)}
    </Stack>
}

export default Component;
