import {apiPublicGetMany, apiPublicGetOne} from "../../../core/services";


export const getProductConfig = async (productType) => {
    const row = await apiPublicGetMany('/products?code='+productType);
    if(row.data.length === 0) {
        throw "No product "+productType;
    }
    return row.data[0].config;
}

export const getProductById = async (id) => {
    return await apiPublicGetOne('/products/'+id);
}