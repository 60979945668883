import React, {useState, useRef} from 'react'
import './Title.scss'

const Component = ({registry, events, ...args}) => {
    const [title, setTitle] = useState(args.title||"");
    const [subtitle, setSubtitle] = useState(args.subtitle||"");
    const ref = useRef(null);

    /*
    const api = {};
    Object.defineProperties(api, {
        'title': {
            get: () => title,
            set: (v) => setTitle(v)
        },
        'subtitle': {
            get: () => subtitle,
            set: (v) => setSubtitle(v)
        },
    });

    registry.set(args.code, api);*/

    return <div ref={ref} className="TitleBlock">
        <div className="TitleBlock__container">
            <h1 className="TitleBlock__title">{title}</h1>
            <p className="TitleBlock__text">{subtitle}</p>
        </div>
    </div>
}

export default Component;
