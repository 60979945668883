import {
  Container,
  List,
  ListItem,
  Avatar,
  Card,
  CardContent,
  Skeleton,
} from "@mui/material";
import UploadMissingPartStep from "../UploadMissingPartStep/UploadMissingPartStep";
import GenerateDocumentStep from "../GenerateDocumentStep/GenerateDocumentStep";
import SignDocumentStep from "../SignDocumentStep/SignDocumentStep";
// Icons
import CheckIcon from "@mui/icons-material/Check";
import React from "react";

import "./SubscriptionStep.scss";
import subscriptionStepStore from "../../../../../../core/store/subscription-step-store";

const SubscriptionStep = () => {
  const [quote, steps] = subscriptionStepStore((state) => [
    state.quote,
    state.steps,
    state.setCurrentStepCode,
  ]);
  const stepsInProgress = steps
    .filter((elt) => elt.visible)
    .map((step) => {
      const stepComponent = (stepType) => {
        switch (stepType) {
          case "subscribe.piece-manquante":
            return <UploadMissingPartStep {...step} />;
          case "subscribe.e-sign":
            return <SignDocumentStep {...step} />;
          case "subscribe.generer-document":
            return <GenerateDocumentStep {...step} />;
          default:
            console.error(`Step type ${stepType} not defined !`);
            return null;
        }
      };
      return (
        <Card
          elevation={6}
          className={
            "SubscriptionStep__Card SubscriptionStep__Card_" + step.status
          }
        >
          {step.status === "done" && (
            <Avatar
              sx={{
                position: "absolute",
                top: 20,
                right: 26,
                backgroundColor: "#0DDFB5",
                width: "16px",
                height: "16px",
              }}
            >
              <CheckIcon fontSize="small" />
            </Avatar>
          )}
          <CardContent>{stepComponent(step.__component)}</CardContent>
        </Card>
      );
    });

  const currentList = quote.steps
    .filter((elt) => elt.visible)
    .map((step) => {
      const stepComponent = (stepType) => {
        switch (stepType) {
          case "subscribe.piece-manquante":
            return <UploadMissingPartStep {...step} />;
          case "subscribe.e-sign":
            return <SignDocumentStep {...step} />;
          case "subscribe.generer-document":
            return <GenerateDocumentStep {...step} />;
          default:
            console.error(`Step type ${stepType} not defined !`);
            return null;
        }
      };
      return (
        <>
          {step.status !== "done" ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={{ width: "100%" }}
              height={80}
            />
          ) : (
            <Card
              elevation={6}
              className={
                "SubscriptionStep__Card SubscriptionStep__Card_" + step.status
              }
            >
              <Avatar
                sx={{
                  position: "absolute",
                  top: 20,
                  right: 26,
                  backgroundColor: "#0DDFB5",
                  width: "16px",
                  height: "16px",
                }}
              >
                <CheckIcon fontSize="small" />
              </Avatar>

              <CardContent>{stepComponent(step.__component)}</CardContent>
            </Card>
          )}
        </>
      );
    });

  return (
    <Container fixed>
      <List>
        {Array.isArray(steps) && steps.length ? (
          <>
            {stepsInProgress.map((value, index) => (
              <ListItem key={`step_${index}`}>{value}</ListItem>
            ))}
          </>
        ) : (
          <>
            {currentList.map((value, index) => (
              <ListItem key={`step_${index}`}>{value}</ListItem>
            ))}
          </>
        )}
      </List>
    </Container>
  );
};
export default SubscriptionStep;
