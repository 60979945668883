import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import errorIcon from "../../assets/images/error-icon.svg";

const ErrorPage = ({ errorMsg }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(90vh - 108px)",
      }}
    >
      <Container maxWidth="md">
        <Grid container alignItems={"center"} spacing={1}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h1">Erreur</Typography>
            <Typography variant="h6">{errorMsg}</Typography>
            <Box>
              <img
                src={errorIcon}
                alt="panneau stop 404"
                width={"100%"}
                style={{ maxWidth: "190px", marginTop: "16px" }}
                height={"auto"}
              />
            </Box>
            <Link to="/">
              <Button sx={{ margin: "26px auto 0 auto" }} variant="contained">
                Retour à l'accueil
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ErrorPage;
