import React, {useState, useRef} from 'react'
import './Title.scss'

const Title = ({registry, events, styles, ...component}) => {
    const [title, setTitle] = useState(component.title||"");
    const [subtitle, setSubtitle] = useState(component.subtitle||"");
    const ref = useRef(null);

    if(component.code) {
        const api = {};
        Object.defineProperties(api, {
            'title': {
                get: () => title,
                set: (v) => setTitle(v)
            },
            'subtitle': {
                get: () => subtitle,
                set: (v) => setSubtitle(v)
            },
        });
        registry.set(component.code, api);
    }

    return <div ref={ref} className="TitleBlock" style={styles}>
        <div className="TitleBlock__container">
            <h1 className="TitleBlock__title">{title}</h1>
            <p className="TitleBlock__text">{subtitle}</p>
        </div>
    </div>
}

export default Title;
