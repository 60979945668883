import React, { useContext, useEffect, useState } from "react";
import CardField from "../components/CardField/CardField";
import { Box, Button, Modal, Stack } from "@mui/material";
import { BasketContext } from "../context";

import "./ProductChoiceField.scss";
import ReactMarkdown from "react-markdown";
import LongLabel from "../components/LongLabel/LongLabel";
import { evalEvent } from "../service/PageService";
import { periodicityLabel } from "../model/Basket";

const Component = ({ registry, events, ...args }) => {
  const [visible, setVisible] = useState(true);
  const [label, setLabel] = useState(args.label);
  const { navigationState, basket, product } = useContext(BasketContext);
  const [choice, setChoice] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [addOnClick, setAddOnClick] = useState(!!args.addOnClick);
  const [basketChoice, setBasketChoice] = useState(null);
  const [readonly, setReadonly] = useState(!!args.readonly);

  useEffect(() => {
    evalEvent(events, "change").catch();
  }, []);

  useEffect(() => {
    const item = basket.getItem(navigationState.asset, navigationState.itemId);
    const scope = navigationState.asset ? item : basket;
    if (!scope) return;
    setCurrentItem(item);
    const c = product.choices.find((c) => c.code === args.choice);
    const options = c.options
      .map((o) => {
        const periodicPremium = scope
          ?.getChoice(c.code)
          ?.options.find((op) => op.code === o.code)?.periodicPremium;
        return {
          ...o,
          periodicPremium:
            typeof periodicPremium === "number" ? periodicPremium : false,
        };
      })
      .filter((o) => o.periodicPremium !== false);

    setChoice({
      ...c,
      options,
    });
    if (!c.mandatory) setAddOnClick(true);
  }, [navigationState, product]);

  if (args.code) {
    const api = {};
    Object.defineProperties(api, {
      visible: {
        get: () => visible,
        set: (v) => setVisible(v),
      },
      readonly: {
        get: () => readonly,
        set: (v) => setReadonly(v),
      },
      label: {
        get: () => label,
        set: (v) => setLabel(v),
      },
    });

    registry.set(args.code, api);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 730,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (!choice || !visible || (choice.asset && !currentItem)) {
      setBasketChoice(null);
      return;
    }

    const choiceScope = choice.asset ? currentItem : basket;
    setBasketChoice(choiceScope.getChoice(args.choice));
    evalEvent(events, "change").catch();
  }, [basket, choice, currentItem]);

  if (!basketChoice) return <></>;

  return (
    <>
      {label && <LongLabel label={label} />}
      <Stack
        direction={"row"}
        gap={2}
        justifyContent={"center"}
        sx={{ margin: "20px" }}
      >
        {!choice.mandatory && (
          <CardField disabled={false} selected={!basketChoice.selected}>
            <div
              className={"ProductChoiceField__No"}
              onClick={() => {
                if (!readonly) {
                  basketChoice.selected = false;
                  evalEvent(events, "change").catch();
                }
              }}
            >
              NON SOUSCRITE
            </div>
          </CardField>
        )}
        {choice.options.map((option, i) => {
          const isSelected =
            basketChoice && basketChoice.selected === option.code;

          return (
            <CardField disabled={false} selected={isSelected} key={i}>
              <div
                className={"ProductChoiceField"}
                style={{ height: addOnClick ? 120 : 200 }}
                onClick={() => {
                  if (addOnClick && !readonly) {
                    basketChoice.selected = option.code;
                    evalEvent(events, "change").catch();
                  }
                }}
              >
                <div className={"ProductChoiceField__Title"}>
                  {option.title}
                </div>
                <div className={"ProductChoiceField__Price"}>
                  {option.periodicPremium.toFixed(2)} €
                  <span>/{periodicityLabel[option.periodicity]}</span>
                </div>
                <div className={"ProductChoiceField__Description"}>
                  {option.shortDescription}
                </div>
                <a
                  className={"ProductChoiceField__Link"}
                  onClick={() => setShowModal(option)}
                >
                  Détail des garanties
                </a>
                {!isSelected && !addOnClick && !readonly && (
                  <Button
                    variant={"contained"}
                    color={"info"}
                    onClick={() => {
                      basketChoice.selected = option.code;
                      evalEvent(events, "change").catch();
                    }}
                  >
                    Choisir
                  </Button>
                )}
              </div>
            </CardField>
          );
        })}
      </Stack>
      <Modal open={!!showModal} onClose={() => setShowModal(null)}>
        <Box sx={style} className={"ProductChoiceField__Modal"}>
          <Stack direction="column" spacing={2}>
            {showModal && (
              <Stack
                direction="column"
                alignItems="stretch"
                spacing={3}
                className={"ProductChoiceField__ModalBody"}
              >
                <h2>Détail des garanties &#8220;{showModal.title}&#8221;</h2>
                <ReactMarkdown children={showModal.description} />
              </Stack>
            )}
            <Stack direction="row" justifyContent={"center"} spacing={2}>
              <Button variant="contained" onClick={() => setShowModal(false)}>
                Fermer
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default Component;
