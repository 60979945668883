import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import T from "prop-types";
import "./DocumentBlock.scss";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { getToken } from "../../../../core/services/TokenService";
import { useContext } from "react";
import { SubscriptionContext } from "../Subscription/SubscriptionIndex";
import { apiGetDocument } from "../../../../core/services";

const DocumentBlock = ({
  documents,
  currentDocument,
  setCurrentDocument = () => null,
  subscriptionId,
}) => {
  const { allDocContent, setAllDocContent } = useContext(SubscriptionContext);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState({});

  useEffect(() => {
    const found = documents.find(i => i.value === currentDocument)
    // load && cache subscription.document
    if (!allDocContent[currentDocument] && found && found.status !== 'NEW') {
      setLoading(true);
      allDocContent[currentDocument] = apiGetDocument(`${process.env.REACT_APP_API_URL}/subscriptions/${subscriptionId}/documents/${found._id}/download`).then(res => {
        setLoading(false);
        allDocContent[currentDocument] = new File([res.data], currentDocument);
        setAllDocContent({...allDocContent});
      })
    }
  }, [documents, currentDocument]);

  function onDocumentLoadSuccess(i, _numPages) {
    numPages[i] = _numPages;
    setNumPages({ ...numPages });
  }

  return (
    <>
      <div className="Subscription__menu-block">
        {documents?.map((item) => {
          return (
            <span
              key={item._id}
              onClick={() => setCurrentDocument(item.value)}
              className="DocumentBlock__title"
              style={
                (currentDocument === item.value && {
                  borderBottom: "2px solid var(--alert)",
                }) ||
                {}
              }
            >
              {item.label}
            </span>
          );
        })}
      </div>
      <div className="DocumentBlock">
        {documents?.map(
          (item) =>
            currentDocument === item.value && (
              <TransformWrapper
                key={item._id}
                initialScale={1}
                initialPositionX={70}
                initialPositionY={5}
              >
                <TransformComponent>
                  <Document
                    // file={`${process.env.REACT_APP_API_URL}/subscriptions/${subscriptionId}/documents/${item._id}/download`}
                    file={allDocContent[currentDocument]}
                    options={{
                      httpHeaders: { Authorization: `Bearer ${getToken()}` },
                    }}
                    onLoadSuccess={({ numPages }) =>
                      onDocumentLoadSuccess(item.value, numPages)
                    }
                    className="DocumentBlock__pdf-document"
                    renderMode="svg"
                    noData={loading ? 'Chargement du pdf...' : 'Pas de pdf'}
                    loading="Chargement du pdf..."
                    error="Erreur lors de la récupération du pdf."
                  >
                    {numPages &&
                      Array.from(
                        { length: numPages[item.value] || 1 },
                        (_, i) => i + 1
                      ).map((i) => (
                        <Page
                          key={i}
                          pageNumber={i}
                          className="DocumentBlock__pdf-page"
                          height={550}
                        />
                      ))}
                  </Document>
                </TransformComponent>
              </TransformWrapper>
            )
        )}
      </div>
    </>
  );
};

DocumentBlock.propTypes = {
  documents: T.arrayOf(
    T.shape({
      value: T.string,
      label: T.string,
      externalRef: T.string,
    })
  ),
  currentDocument: T.string,
  setCurrentDocument: T.func,
};
export default DocumentBlock;
