import { Button, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import UploadDocumentDialog from "../UploadDocumentDialog/UploadDocumentDialog";
import React from "react";

const UploadMissingPartStep = (props) => {
  const { title, subtitle, status, code } = props;
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography color={"#024E7D"} variant="h6">
            {title || "Envoi de pièces manquantes"}
          </Typography>
          <Typography color={"#024E7D"} variant="subtitle2">
            {subtitle || ""}
          </Typography>
        </Grid>
        <Grid item xs={4} display={"flex"}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            width={"100%"}
          >
            {status === "todo" && (
              <>
                <Button
                  sx={{
                    borderRadius: "35px",
                    boxSizing: "border-box",
                    padding: "4px 20px",
                    gap: "10px",
                    background: "#024E7D",
                    border: "1px solid #024E7D",
                  }}
                  onClick={handleClickOpen}
                  variant="contained"
                  size="small"
                >
                  Fournir
                </Button>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
      <UploadDocumentDialog
        open={open}
        onClose={handleClose}
        code={code}
        {...props}
      />
    </>
  );
};
export default UploadMissingPartStep;
