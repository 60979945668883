import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoutIcon from "../../../../assets/images/logout-icon.svg";
import logo from "../../../../assets/images/logo-carene.svg";
import { getUserName } from "../../../../core/services/TokenService";
import "../../../Navigation/index.scss";
import { Button, Icon } from "@mui/material";

const Navigation = ({ }) => {
  const navigate = useNavigate();
  const username = getUserName();
  const [disable, setDisable] = useState(false);
  const origin = localStorage.getItem("origin");
  useEffect(() => {
    setDisable(origin === "AZDADS" );
  }, [origin]);

  const submit = () => {
    localStorage.removeItem("token");
    navigate("/login");
    window.location.reload();
  };

  return (
    username &&
        <header className="Navbar">
          <Link to="/">
            <img src={logo} alt="logo carene" />
          </Link>
          <div className="Navbar__help-button">
            <span>
              {username}
              <br />
              <span style={{ fontWeight: "600", fontStyle: "italic" }}>
                Allianz DADS
              </span>
            </span>
            {!disable ? (
              <Button
                onClick={() => submit()}
                variant="outlined"
                startIcon={
                  <Icon>
                    <img src={logoutIcon} alt="logout icon" />
                  </Icon>
                }
              >
                DÉCONNEXION
              </Button>
            ) : (
              ""
            )}
          </div>
        </header>
  );
};

export default Navigation;
