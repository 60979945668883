const streetTypes =  [
  {
    "id": "ABE",
    "label": "Abbaye"
  },
  {
    "id": "ACH",
    "label": "Ancien chemin"
  },
  {
    "id": "AERD",
    "label": "Aérodrome"
  },
  {
    "id": "AERG",
    "label": "Aérogare"
  },
  {
    "id": "AERP",
    "label": "Aéroport"
  },
  {
    "id": "AGL",
    "label": "Agglomération"
  },
  {
    "id": "AIRE",
    "label": "Aire"
  },
  {
    "id": "ALL",
    "label": "Allée"
  },
  {
    "id": "ANSE",
    "label": "Anse"
  },
  {
    "id": "ARC",
    "label": "Arcade"
  },
  {
    "id": "ART",
    "label": "Ancienne route"
  },
  {
    "id": "AUT",
    "label": "Autoroute"
  },
  {
    "id": "AV",
    "label": "Avenue"
  },
  {
    "id": "BASE",
    "label": "Base"
  },
  {
    "id": "BASN",
    "label": "Bassin"
  },
  {
    "id": "BAST",
    "label": "Bastion"
  },
  {
    "id": "BAT",
    "label": "Batiment"
  },
  {
    "id": "BCH",
    "label": "Bas chemin"
  },
  {
    "id": "BCLE",
    "label": "Boucle"
  },
  {
    "id": "BD",
    "label": "Boulevard"
  },
  {
    "id": "BEGI",
    "label": "Béguinage"
  },
  {
    "id": "BER",
    "label": "Berge"
  },
  {
    "id": "BOIS",
    "label": "Bois"
  },
  {
    "id": "BRE",
    "label": "Barrière"
  },
  {
    "id": "BRG",
    "label": "Bourg"
  },
  {
    "id": "BSTD",
    "label": "Bastide"
  },
  {
    "id": "BUT",
    "label": "Butte"
  },
  {
    "id": "CALE",
    "label": "Cale"
  },
  {
    "id": "CAMP",
    "label": "Camp"
  },
  {
    "id": "CAN",
    "label": "Canal"
  },
  {
    "id": "CAR",
    "label": "Carrefour"
  },
  {
    "id": "CARE",
    "label": "Carrière"
  },
  {
    "id": "CARR",
    "label": "Carré"
  },
  {
    "id": "CASR",
    "label": "Caserne"
  },
  {
    "id": "CAU",
    "label": "Carreau"
  },
  {
    "id": "CAV",
    "label": "Cavée"
  },
  {
    "id": "CCAL",
    "label": "Centre commercial"
  },
  {
    "id": "CD",
    "label": "Chemin départemental"
  },
  {
    "id": "CGNE",
    "label": "Campagne"
  },
  {
    "id": "CHA",
    "label": "Chasse"
  },
  {
    "id": "CHE",
    "label": "Chemin"
  },
  {
    "id": "CHEM",
    "label": "Cheminement"
  },
  {
    "id": "CHEZ",
    "label": "Chez"
  },
  {
    "id": "CHI",
    "label": "Charmille"
  },
  {
    "id": "CHL",
    "label": "Chalet"
  },
  {
    "id": "CHP",
    "label": "Chapelle"
  },
  {
    "id": "CHS",
    "label": "Chaussée"
  },
  {
    "id": "CHT",
    "label": "Château"
  },
  {
    "id": "CHV",
    "label": "Chemin vicinal"
  },
  {
    "id": "CITE",
    "label": "Cité"
  },
  {
    "id": "CLOI",
    "label": "Cloître"
  },
  {
    "id": "CLOS",
    "label": "Clos"
  },
  {
    "id": "CLR",
    "label": "Couloir"
  },
  {
    "id": "COL",
    "label": "Col"
  },
  {
    "id": "COLI",
    "label": "Colline"
  },
  {
    "id": "COLL",
    "label": "Collège"
  },
  {
    "id": "COR",
    "label": "Corniche"
  },
  {
    "id": "COTE",
    "label": "Côte"
  },
  {
    "id": "COTT",
    "label": "Cottage"
  },
  {
    "id": "COUR",
    "label": "Cour"
  },
  {
    "id": "CPG",
    "label": "Camping"
  },
  {
    "id": "CRS",
    "label": "Cours"
  },
  {
    "id": "CRX",
    "label": "Croix"
  },
  {
    "id": "CST",
    "label": "Castel"
  },
  {
    "id": "CTR",
    "label": "Contour"
  },
  {
    "id": "CTRE",
    "label": "Centre"
  },
  {
    "id": "DARS",
    "label": "Darse"
  },
  {
    "id": "DEG",
    "label": "Degré"
  },
  {
    "id": "DIG",
    "label": "Digue"
  },
  {
    "id": "DOM",
    "label": "Domaine"
  },
  {
    "id": "DSC",
    "label": "Descente"
  },
  {
    "id": "EC",
    "label": "Ecole"
  },
  {
    "id": "ECA",
    "label": "Ecart"
  },
  {
    "id": "ECL",
    "label": "Ecluse"
  },
  {
    "id": "EGL",
    "label": "Eglise"
  },
  {
    "id": "EN",
    "label": "Enceinte"
  },
  {
    "id": "ENC",
    "label": "Enclos"
  },
  {
    "id": "ENT",
    "label": "Entrée"
  },
  {
    "id": "ENV",
    "label": "Enclave"
  },
  {
    "id": "ESC",
    "label": "Escalier"
  },
  {
    "id": "ESP",
    "label": "Esplanade"
  },
  {
    "id": "ESPA",
    "label": "Espace"
  },
  {
    "id": "ETNG",
    "label": "Etang"
  },
  {
    "id": "FG",
    "label": "Faubourg"
  },
  {
    "id": "FON",
    "label": "Fontaine"
  },
  {
    "id": "FOR",
    "label": "Forêt"
  },
  {
    "id": "FORM",
    "label": "Forum"
  },
  {
    "id": "FORT",
    "label": "Fort"
  },
  {
    "id": "FOS",
    "label": "Fosse"
  },
  {
    "id": "FOYR",
    "label": "Foyer"
  },
  {
    "id": "FRM",
    "label": "Ferme"
  },
  {
    "id": "GAL",
    "label": "Galerie"
  },
  {
    "id": "GARE",
    "label": "Gare"
  },
  {
    "id": "GARN",
    "label": "Garenne"
  },
  {
    "id": "GBD",
    "label": "Grand boulevard"
  },
  {
    "id": "GEND",
    "label": "Gendarmerie"
  },
  {
    "id": "GPE",
    "label": "Groupe"
  },
  {
    "id": "GPT",
    "label": "Groupement"
  },
  {
    "id": "GR",
    "label": "Grande rue"
  },
  {
    "id": "GRI",
    "label": "Grille"
  },
  {
    "id": "GRIM",
    "label": "Grimpette"
  },
  {
    "id": "HAM",
    "label": "Hameau"
  },
  {
    "id": "HCH",
    "label": "Haut Chemin"
  },
  {
    "id": "HIP",
    "label": "Hippodrome"
  },
  {
    "id": "HLE",
    "label": "Halle"
  },
  {
    "id": "HLM",
    "label": "HLM"
  },
  {
    "id": "HOP",
    "label": "Hôpital"
  },
  {
    "id": "HOT",
    "label": "Hôtel"
  },
  {
    "id": "ILE",
    "label": "Ile"
  },
  {
    "id": "ILOT",
    "label": "Ilot"
  },
  {
    "id": "IMM",
    "label": "Immeuble"
  },
  {
    "id": "IMP",
    "label": "Impasse"
  },
  {
    "id": "JARD",
    "label": "Jardin"
  },
  {
    "id": "JTE",
    "label": "Jetée"
  },
  {
    "id": "LD",
    "label": "Lieu-dit"
  },
  {
    "id": "LEVE",
    "label": "Levée"
  },
  {
    "id": "LOT",
    "label": "Lotissement"
  },
  {
    "id": "LYC",
    "label": "Lycée"
  },
  {
    "id": "MAIL",
    "label": "Mail"
  },
  {
    "id": "MAIS",
    "label": "Maison"
  },
  {
    "id": "MAN",
    "label": "Manoir"
  },
  {
    "id": "MAR",
    "label": "Marche"
  },
  {
    "id": "MAS",
    "label": "Mas"
  },
  {
    "id": "MET",
    "label": "Métro"
  },
  {
    "id": "MF",
    "label": "Mais forestière"
  },
  {
    "id": "MLN",
    "label": "Moulin"
  },
  {
    "id": "MOLE",
    "label": "Mole"
  },
  {
    "id": "MRIE",
    "label": "Mairie"
  },
  {
    "id": "MTE",
    "label": "Montée"
  },
  {
    "id": "MUS",
    "label": "Musée"
  },
  {
    "id": "NTE",
    "label": "Nouvelle route"
  },
  {
    "id": "PAE",
    "label": "Petite avenue"
  },
  {
    "id": "PAL",
    "label": "Palais"
  },
  {
    "id": "PARC",
    "label": "Parc"
  },
  {
    "id": "PAS",
    "label": "Passage"
  },
  {
    "id": "PASS",
    "label": "Passe"
  },
  {
    "id": "PAT",
    "label": "Patio"
  },
  {
    "id": "PAV",
    "label": "Pavillon"
  },
  {
    "id": "PCH",
    "label": "Petit chemin"
  },
  {
    "id": "PERI",
    "label": "Périphérique"
  },
  {
    "id": "PERS",
    "label": "Perspective"
  },
  {
    "id": "PIM",
    "label": "Petite impasse"
  },
  {
    "id": "PIST",
    "label": "Piste"
  },
  {
    "id": "PKG",
    "label": "Parking"
  },
  {
    "id": "PL",
    "label": "Place"
  },
  {
    "id": "PLAG",
    "label": "Plage"
  },
  {
    "id": "PLAN",
    "label": "Plan"
  },
  {
    "id": "PLCI",
    "label": "Placis"
  },
  {
    "id": "PLE",
    "label": "Passerelle"
  },
  {
    "id": "PLN",
    "label": "Plaine"
  },
  {
    "id": "PLT",
    "label": "Plateau"
  },
  {
    "id": "PN",
    "label": "Pass. À niveau"
  },
  {
    "id": "PNT",
    "label": "Pointe"
  },
  {
    "id": "PONT",
    "label": "Pont"
  },
  {
    "id": "PORQ",
    "label": "Portique"
  },
  {
    "id": "PORT",
    "label": "Port"
  },
  {
    "id": "POT",
    "label": "Poterne"
  },
  {
    "id": "POUR",
    "label": "Pourtour"
  },
  {
    "id": "PRBY",
    "label": "Presbytère"
  },
  {
    "id": "PRE",
    "label": "Pré"
  },
  {
    "id": "PROM",
    "label": "Promenade"
  },
  {
    "id": "PRQ",
    "label": "Presqu'île"
  },
  {
    "id": "PRT",
    "label": "Petite route"
  },
  {
    "id": "PRV",
    "label": "Parvis"
  },
  {
    "id": "PSTY",
    "label": "Péristyle"
  },
  {
    "id": "PTA",
    "label": "Petite allée"
  },
  {
    "id": "PTE",
    "label": "Porte"
  },
  {
    "id": "PTR",
    "label": "Petite rue"
  },
  {
    "id": "QU",
    "label": "Quai"
  },
  {
    "id": "QUA",
    "label": "Quartier"
  },
  {
    "id": "RAC",
    "label": "Raccourci"
  },
  {
    "id": "RAID",
    "label": "Raïdillon"
  },
  {
    "id": "RD",
    "label": "Rte département"
  },
  {
    "id": "REM",
    "label": "Rempart"
  },
  {
    "id": "RES",
    "label": "Résidence"
  },
  {
    "id": "RIV",
    "label": "Rivière"
  },
  {
    "id": "RLE",
    "label": "Ruelle"
  },
  {
    "id": "RN",
    "label": "Route nationale"
  },
  {
    "id": "ROC",
    "label": "Rocade"
  },
  {
    "id": "ROQT",
    "label": "Roquet"
  },
  {
    "id": "RPE",
    "label": "Rampe"
  },
  {
    "id": "RPT",
    "label": "Rond-point"
  },
  {
    "id": "RTD",
    "label": "Rotonde"
  },
  {
    "id": "RTE",
    "label": "Route"
  },
  {
    "id": "RUE",
    "label": "Rue"
  },
  {
    "id": "SECT",
    "label": "Section"
  },
  {
    "id": "SEN",
    "label": "Sentier"
  },
  {
    "id": "SENT",
    "label": "Sente"
  },
  {
    "id": "SQ",
    "label": "Square"
  },
  {
    "id": "STDE",
    "label": "Stade"
  },
  {
    "id": "TOUR",
    "label": "Tour"
  },
  {
    "id": "TPL",
    "label": "Terre-plein"
  },
  {
    "id": "TRA",
    "label": "Traverse"
  },
  {
    "id": "TRN",
    "label": "Terrain"
  },
  {
    "id": "TRT",
    "label": "Tertre"
  },
  {
    "id": "TSSE",
    "label": "Terrasse"
  },
  {
    "id": "UNIV",
    "label": "Université"
  },
  {
    "id": "VAL",
    "label": "Vallon"
  },
  {
    "id": "VCHE",
    "label": "Vieux chemin"
  },
  {
    "id": "VEN",
    "label": "Venelle"
  },
  {
    "id": "VIA",
    "label": "Via"
  },
  {
    "id": "VIL",
    "label": "Ville"
  },
  {
    "id": "VLA",
    "label": "Villa"
  },
  {
    "id": "VLGE",
    "label": "Village"
  },
  {
    "id": "VOI",
    "label": "Voie"
  },
  {
    "id": "VTE",
    "label": "Vieille route"
  },
  {
    "id": "ZA",
    "label": "Za"
  },
  {
    "id": "ZAC",
    "label": "Zac"
  },
  {
    "id": "ZAD",
    "label": "Zad"
  },
  {
    "id": "ZAE",
    "label": "Zae"
  },
  {
    "id": "ZI",
    "label": "ZI"
  },
  {
    "id": "ZIL",
    "label": "Zil"
  },
  {
    "id": "ZONE",
    "label": "Zone"
  },
  {
    "id": "ZUP",
    "label": "Zup"
  }
];

export default streetTypes