import React from 'react';

import { DatePicker as MuiDatePicker} from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';

import { Field } from 'react-final-form';
import {showErrorOnChange} from "mui-rff";



export function DatePicker(props) {
  const { name, fieldProps, required, ...rest } = props;

  return (
    <Field
      name={name}
      render={(fieldRenderProps) => <DatePickerWrapper required={required} {...fieldRenderProps} {...rest} />}
      {...fieldProps}
    />
  );
}

function DatePickerWrapper(props) {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    showError = showErrorOnChange,
    required,
    placeholder,
    ...rest
  } = props;

  const { error, submitError } = meta;
  const isError = showError({ meta });

  const { helperText, textFieldProps, ...lessrest } = rest;

  return (
    <MuiDatePicker
      onChange={onChange}
      value={(value) === '' ? null : value}
      {...lessrest}
      renderInput={(inputProps) => (
        <TextField
          {...textFieldProps}
          {...inputProps}
          fullWidth={true}
          helperText={isError ? error || submitError : helperText}
          error={inputProps.error || isError}
          name={name}
          required={required}
          inputProps={{
            ...inputProps.inputProps,
            onBlur: (event) => {
              inputProps.inputProps?.onBlur?.(event);
              restInput.onBlur(event);
            },
            onFocus: (event) => {
              inputProps.inputProps?.onFocus?.(event);
              restInput.onFocus(event);
            },
            placeholder: placeholder || inputProps.inputProps?.placeholder,
          }}
        />
      )}
    />
  );
}