import { Grid, Typography, Stack, Button } from "@mui/material";
import React, { useState } from "react";
import SignPaDialog from "../SignPaDialog/SignPaDialog";
import { useQuery } from "@tanstack/react-query";
import { CheckDocapostCircuitFn } from "../../api";
import subscriptionStepStore from "../../../../../../core/store/subscription-step-store";

const SignDocumentStep = (props) => {
  const [quoteId, currentStepCode] = subscriptionStepStore((state) => [
    state.quoteId,
    state.currentStepCode,
  ]);

  const {
    title,
    subtitle,
    status,
    code,
    isSigningModeDefined,
    remoteSigningOnly,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const querySteps = useQuery(
    ["checkDocapost", { quoteId, code }],
    CheckDocapostCircuitFn,
    {
      enabled: currentStepCode === code,
      refetchInterval: 5000,
    }
  );

  if (querySteps.isFetched) {
    if (
      querySteps.data &&
      ["FINISHED", "REJECTED"].includes(querySteps.data.status) &&
      status !== "done"
    ) {
      window.location.reload();
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography color={"#024E7D"} variant="h6">
            {title || "Signature éléctronique"}
          </Typography>
          <Typography color={"#024E7D"} variant="subtitle2">
            {subtitle || ""}
          </Typography>
          {querySteps.data && (
            <Typography
              variant="body2"
              sx={{ whiteSpace: "pre", marginTop: 2, color: "#999" }}
            >{`
Numéro de dossier : ${querySteps.data.fileRef}
Etat du canal de signature électronique : ${querySteps.data.status}
${
  querySteps.data.deliveryEmailSended
    ? "Les documents contractuels sont disponibles par email"
    : ""
}
${
  querySteps.data.confirmEmailSended
    ? "Les documents signés sont disponibles par email"
    : ""
}
${
  querySteps.data.signatureEmailSended
    ? "La signature électronique est disponible par email"
    : ""
}`}</Typography>
          )}
          <Typography></Typography>
        </Grid>
        <Grid item xs={4} display={"flex"}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            width={"100%"}
          >
            {status === "todo" && (
              <>
                <Button
                  disabled={
                    isSigningModeDefined !== null &&
                    isSigningModeDefined &&
                    remoteSigningOnly
                  }
                  sx={{
                    borderRadius: "35px",
                    boxSizing: "border-box",
                    padding: "4px 20px",
                    gap: "10px",
                    background: "#024E7D",
                    border: "1px solid #024E7D",
                  }}
                  onClick={handleClickOpen}
                  variant="contained"
                  size="small"
                >
                  Fournir
                </Button>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
      <SignPaDialog open={open} onClose={handleClose} {...props} />
    </>
  );
};
export default SignDocumentStep;
