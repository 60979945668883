import React, { useContext, useEffect, useState } from "react";
import {
  useLocation,
  Route,
  Routes,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import { Grid, Stack, Typography } from "@mui/material";
import { SubscriptionContext } from "../Subscription/SubscriptionIndex";
import CaptureDocument from "./CaptureDocument";
import CardBlockTextTag from "../../../../core/blocks/CardBlockTextTag/CardBlockTextTag";
import {
  getDocumentSpecification,
  getSubscriptionDocument,
} from "../../../../core/services/BatchSubscriptionService";
import SummaryBlock from "./SummaryBlock";
import { statuses } from "../../../../core/utils/statuses";
import { OpenCvProvider } from "../../../ImageCapture/components/opencv/OpenCvProvider";

export const CaptureContext = React.createContext({
  refreshDocumentState: () => { },
});

const SubscriptionCapture = () => {
  let { batchId, subscriptionId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [documentTypes, setDocumentTypes] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(0);
  const [nextDoc, setNextDoc] = useState(null);

  const { subscription } = useContext(SubscriptionContext);

  const refreshDocumentState = () => {
    setForceRefresh(forceRefresh + 1);
  };

  const refresh = () => {
    const specs = getDocumentSpecification();
    setDocumentTypes(
      specs.map((spec) => {
        const doc = getSubscriptionDocument(subscription, spec.type);
        return {
          status: doc?.status || "NEW",
          ...spec,
          _id: doc?._id || null,
        };
      })
    );
  }

  useEffect(() => {
    refresh();
    let id = setInterval(() => {
      refresh();
    }, 500
    );
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription, forceRefresh]);

  useEffect(() => {
    if (!subscription._id) return;
    const firstNewDoc = subscription?.supportingDocuments
        ?.sort((a, b) => a.documentType > b.documentType ? 1 : -1)
        ?.find((doc) => doc.status === "NEW");
    setNextDoc(firstNewDoc ? firstNewDoc.documentType : "summary");
  }, [subscription]);

  return (
    <OpenCvProvider openCvPath={'/opencv/opencv.js'}>
      <CaptureContext.Provider value={{ refreshDocumentState, forceRefresh }}>
        <Stack spacing={1} className="Capture Page">
          <Typography variant="h2">Capture documents</Typography>
          <Typography variant="p">Prenez une photo des documents</Typography>
          <Grid container direction="row">
            {documentTypes.map((item) => (
              <CardBlockTextTag
                key={item.type}
                item={{
                  label: item.label,
                  value: item.status,
                  tagVariant: statuses[item.status]?.tag || "error",
                  tagLabel: statuses[item.status]?.label || item.status,
                }}
                onActiveCard={() =>
                  navigate(
                    `/batch-subscriptions/${batchId}/subscriptions/${subscriptionId}/capture/${item.type}`
                  )
                }
                className={
                  (location.pathname.endsWith("/" + item.type) &&
                    "CardBlock__active") ||
                  ""
                }
              />
            ))}
          </Grid>

          <Routes>
            <Route path="/" element={nextDoc && <Navigate to={nextDoc} />} />
            <Route path=":documentType" element={<CaptureDocument />} />
            <Route path="summary" element={<SummaryBlock documentTypes={documentTypes} />} />
          </Routes>
        </Stack>
      </CaptureContext.Provider>
    </OpenCvProvider>
  );
};

export default SubscriptionCapture;
