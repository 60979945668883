import React from "react"
import "./Advisor.scss"

const Advisor = ({adviserImage, adviser, adviserDescription}) => {
  return (
      <div className="Advisor__container">
        <img
          className="Advisor__img"
          src={adviserImage.url}
          alt={adviserImage.alt}
        />
        <div className="Advisor__span">
          <p className="Advisor__name">{adviser} </p>
          <p className="Advisor__description">{adviserDescription}</p>
        </div>
      </div>
  )
}

export default Advisor
