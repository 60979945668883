import React, {useContext, useEffect, useState} from 'react'
import CardField from "../components/CardField/CardField";
import {Box, Button, Modal, Stack} from "@mui/material";
import {BasketContext} from "../context";

import "./ProductChoiceListField.scss";
import ReactMarkdown from "react-markdown";
import LongLabel from "../components/LongLabel/LongLabel";
import ProductChoiceField from "./ProductChoiceField";

const Component = ({registry, events,...args}) => {
    const [visible, setVisible] = useState(true);
    const [label, setLabel] = useState(args.label);

    const {navigationState, basket, product} = useContext(BasketContext);
    const [showModal, setShowModal] = useState(null);
    const [showModalOptions, setShowModalOptions] = useState(null);
    const [readonly, setReadonly] = useState(!!args.readonly);

    const [scope, setScope] = useState(null);
    const choices = args.choices.split(/[\s,]+/);

    useEffect(() => {
        if(navigationState.asset) {
            setScope(basket.getItem(navigationState.asset, navigationState.itemId));
        } else {
            setScope(basket);
        }
    }, [navigationState])

    if(args.code) {
        const api = {};
        Object.defineProperties(api, {
            'visible': {
                get: () => visible,
                set: (v) => setVisible(v)
            },
            'readonly': {
                get: () => readonly,
                set: (v) => setReadonly(v)
            },
            'label': {
                get: () => label,
                set: (v) => setLabel(v)
            },
        });
        registry.set(args.code, api);
    }

    const periodicity = {
        'M': 'mois',
        'A': 'an',
        'S': 'semestre',
        'T': 'trimestre'
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 830,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    if(!scope) return <></>


    return <>
        {label && <LongLabel label={label}/>}
        <Stack direction={"row"} gap={2} justifyContent={"center"} sx={{margin: '20px'}}>
            {choices.map( (choiceName,i)=> {
                const choice = product.choices.find(c => c.code === choiceName);
                const basketChoice = scope.getChoice(choiceName);
                if(!choice || !basketChoice) return <></>
                const option = choice.options.find( opt => opt.code === basketChoice.selected);

                return <CardField disabled={false}
                                  selected={!!basketChoice.selected}
                                  key={i}
                >
                    <div className={"ProductChoiceList__Card"}>
                        <div className={"ProductChoiceList__Title"}>{choice.title}</div>
                        <div className={"ProductChoiceList__Price"}>
                            {!basketChoice.selected && !basketChoice.isBoolean &&
                                <div className={"ProductChoiceList__StartingPrice"}>à partir de</div>}
                            {basketChoice.selected ? basketChoice.periodicPremium.toFixed(2) : basketChoice.starting('periodicPremium').toFixed(2)} €
                            <span>/{periodicity[basketChoice.periodicity]}</span>
                        </div>
                        {
                            basketChoice.selected &&
                            <a className={"ProductChoiceList__Link"} onClick={() => setShowModal(option)}>
                                Détail des garanties
                            </a>
                        }
                        {!basketChoice.selected &&
                            <Button variant={"outlined"} color={"primary"} children={"Ajouter"} onClick={() => {
                                if(!readonly) {
                                    if(basketChoice.isBoolean) {
                                        basketChoice.selected = basketChoice.options[0].code;
                                    } else {
                                        setShowModalOptions(basketChoice);
                                    }
                                }
                            }
                            }/>
                        }
                        {basketChoice.selected &&  basketChoice.isBoolean && !readonly &&
                            <Button variant={"contained"} color={"info"} children={"Retirer"} onClick={() => {
                                basketChoice.selected = false;
                            }
                            }/>
                        }
                        {basketChoice.selected && !basketChoice.isBoolean && !readonly &&
                            <Button variant={"contained"} color={"info"} children={"Modifier"} onClick={() => {
                                setShowModalOptions(basketChoice);
                            }
                            }/>
                        }
                    </div>
                </CardField>})}
        </Stack>
        <Modal open={!!showModal} onClose={() => setShowModal(null)}>
            <Box sx={style} className={"ProductChoiceList__Modal"}>
                <Stack direction="column" spacing={2}>
                    {showModal &&
                        <Stack direction="column" alignItems="stretch" spacing={3} className={"ProductChoiceList__ModalBody"}>
                            <h2>Détail des garanties &#8220;{showModal.title}&#8221;</h2>
                            <ReactMarkdown children={showModal.description}/>
                        </Stack>
                    }
                    <Stack direction="row" justifyContent={"center"} spacing={2}>
                        <Button variant="contained" onClick={() => setShowModal(false)}>
                            Fermer
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
        <Modal open={!!showModalOptions} onClose={() => setShowModalOptions(null)}>
            <Box sx={style} className={"ProductChoiceList__Modal"}>
                <Stack direction="column" spacing={2}>
                    {showModalOptions &&
                        <>
                            <Stack direction="column" alignItems="stretch" spacing={3}>
                                <h2 key={showModalOptions.title}>{showModalOptions.title}</h2>
                                <ProductChoiceField registry={registry}
                                                    events={null}
                                                    addOnClick={true}
                                                    choice={showModalOptions.code}
                                />
                            </Stack>
                            <Stack direction="row" justifyContent={"center"} spacing={2}>
                                {showModalOptions.selected && <Button variant="outlined" color="secondary" onClick={() => {
                                    showModalOptions.selected = false;
                                    setShowModalOptions(false);
                                }}>
                                    Retirer
                                </Button>}
                                <Button variant="outlined" onClick={() => {
                                    setShowModalOptions(false);
                                }}>
                                    Fermer
                                </Button>
                            </Stack>
                        </>
                    }
                </Stack>
            </Box>
        </Modal>
    </>
}

export default Component;
